import React, { useEffect, useState, useRef, useCallback } from 'react';
import store from '../../API/store';
import { useSelector, useDispatch } from 'react-redux';
import DataDecode from '../../utils/DataDecode';
import { useHistory, useLocation } from 'react-router-dom';
import { IoFemale } from "react-icons/io5";
import { HiMiniPlusCircle } from "react-icons/hi2";
import { IoMdMale, IoMdSave } from "react-icons/io";
import Prescription from './Profile/Mobile/Prescription';
import PastVisitHistory from './Profile/PastVisitHistory';
import DateFunction from '../../utils/DateFunctions';
import TimeInOutToggle from '../Appointments/TimeInOutToggle';
import TimeInTimer from './Profile/TimeInTimer';
import Vitals from '../Appointments/Vitals';
import { Checkbox, Label } from "flowbite-react";
import { setNotification } from '../../Redux/features/toast/toastSlice';
import UploadPatientFiles from './Profile/UploadPatientFiles';
import { setAppointmentInvoicePrint, setAddAppointmentPopup, setPatientAppointment } from '../../Redux/features/popup/popupSlice';
import { setAppointmentInvoice, setIsInvoiceExist } from '../../Redux/features/invoice/invoiceSlice';
import GenerateInvoice from '../Invoice/GenerateInvoice';
import { theme } from '../../EmraxisTheme/Theme';
import ChooseDocument from './Profile/DocumentUploads/ChooseDocument';
import { setDocumentType, setIsCameraUpload, setIsWithoutAppointment, setShowDocumentUploadPopup, setShowUploadPopup } from '../../Redux/features/uploadDoc/uploadDocSlice';
import UploadDocument from './Profile/DocumentUploads/UploadDocument';
import AddAppointment from '../Appointments/AddAppointment';
import AddPatient from './AddPatient';
import FileIcon from './Profile/FileIcon';
import LabNotesMobile from './Profile/Mobile/LabNotesMobile';
import Radiology from './Profile/Mobile/Radiology';
import DoctorNotesMobile from './Profile/Mobile/DoctorNotesMobile';
import UploadedDataMobile from './Profile/Mobile/UploadedDataMobile';
import { setActiveNavbar, setAutoSaveAll, setDoctorNotesDataAvailable, setIsGlobalSaveRequired, setLabNotesDataAvailable, setPrescriptionDataAvailabale, setRadiologyDataAvailable, setUploadedDataAvailable, setVisitHistoryDataAvailable } from '../../Redux/features/profileDrawer/profileSideNavbarSlice';
import PastHistoryDetails from './Profile/VisitHistory/PastHistoryDetails';
import { useNavigate } from 'react-router-dom';
import PatientCardDetails from './PatientCardDetails';


const MobilePatientProfile = ({setShowPatient}) => {

    var loggeduser = useSelector(state => state.login.loginuserData);
    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    var showAppointmentInvoicePrint = useSelector(state => state.popup.showAppointmentInvoicePrint);
    const previewTheme = useSelector(state => state.theme.colors) || theme.defaultTheme;
    var isInvoiceEnabled = useSelector(state => state.invoice.isInvoiceEnabled);
    var showDocumentUploadPopup = useSelector(state => state.document_upload.showDocumentUploadPopup);
    var showUploadPopup = useSelector(state => state.document_upload.showUploadPopup);
    var refreshUploadedFiles = useSelector(state => state.document_upload.refreshUploadedFiles);
    var showAddAppointmentpopup = useSelector(state => state.popup.showAddAppointment);
    var patientAddAppointment = useSelector(state => state.popup.setPatientAddAppointment);
    var showAddPatientpopup = useSelector(state => state.popup.showAddPatient);
    var appointmentData = useSelector(state => state.popup.patientProfileAppointmentData);
    var isMobileSidebarOpen = useSelector(state => state.drawer.isMobileSidebarOpen);
    var isProfileDrawerOpen = useSelector(state => state.profileDrawer.isProfileDrawerOpen);
    var isGlobalSaveRequired = useSelector(state => state.profileSideNavbar.isGlobalSaveRequired);
    var isSideNavbarClicked = useSelector(state => state.profileSideNavbar.isSideNavbarClicked);
    var autoSaveAll = useSelector(state => state.profileSideNavbar.autoSaveAll);
    var showAppointmentInvoicePrint = useSelector(state => state.popup.showAppointmentInvoicePrint);
    var patientProfileMenuSelector = useSelector((state) => state.profileSideNavbar.patientProfileMenuSelector);

    const [patient, setPatient] = useState({});
    const [appointment, setAppointment] = useState({});
    const [activeSection, setActiveSection] = useState('patientdetails');
    const [client, setclient] = useState(false);
    const [refreshData, setRefreshData] = useState(false);
    const patientdetails = useRef();
    const currentmedication = useRef();
    const pastvisithistory = useRef();
    const sections = useRef([]);
    const [openVitals, setOpenVitals] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryParamValue = queryParams.get('profile');
    const { appointment_id, patient_id, doctor_id, clinic_guid, page_src } = JSON.parse(atob(queryParamValue));

    const [files, setFiles] = useState([]);
    const [originalFiles, setOriginalFiles] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [confirmBox, setConfirmBox] = useState(false);
    const [expandRow, setExpandRow] = useState(false);
    const [signOffDisableButton, setSignOffDisableButton] = useState(false);
    const [confirmBoxSignOff, setConfirmBoxSignOff] = useState(false);
    const [showFollowUpPopup, setShowFollowUpPopup] = useState(false);
    const [followUpData, setFollowUpData] = useState({});
    const [loadingDeleteImage, setLoadingDeleteImage] = useState(false);
    const [readmore, setReadmore] = useState(true);
    const [editVitalsFirstSec, setEditVitalsFirstSec] = useState(false);
    const [loadVitalsFirstSec, setLoadVitalsFirstSec] = useState(false);
    const [editVitalsSecondSec, setEditVitalsSecondSec] = useState(false);
    const [loadVitalsSecondSec, setLoadVitalsSecondSec] = useState(false);
    const [uploadPatientFile, setUploadPatientFile] = useState(false);
    const [refreshImage, setRefreshImage] = useState(false);
    var invoiceExist = useSelector(state => state.invoice.isInvoiceExist);
    const [uploadPopup, setUploadPopup] = useState(false);
    const [capturePopup, setCapturePopup] = useState(false);
    const [fileType, setFileType] = useState("All");
    const [pageSrc, setPageSrc] = useState(page_src);
    const [isAnyChanges, setIsAnyChanges] = useState(false);
    const [saveAllLoading, setSaveAllLoading] = useState(false);
    const [showUploadFiles, setShowUploadFiles] = useState(false);
    const [isMessagePopupOpen, setIsMessagePopupOpen] = useState(null);
    const [doctors, setDoctors] = useState([]);
    const [doctorsTimeslot, setDoctorsTimeSlot] = useState([]);
    const [viewType, setViewType] = useState('grid');
    const [appointmentId, setAppointmentId] = useState(appointment_id);
    const [doctorId, setDoctorId] = useState(doctor_id);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedVisit, setSelectedVisit] = useState('Current Visit');
    const [showSecondaryMenu, setShowSecondaryMenu] = useState(false);
    const [openMenuIndex, setOpenMenuIndex] = useState(null);
    const [openMenu, setOpenMenu] = useState(false);
    const [pastHistory, setPastHistory] = useState([]);
    const [viewHistory, setViewHistory] = useState(false)
    const [patientView, setPatientView] = useState(false)
    const [viewHistoryData, setViewHistoryData] = useState({})
    const [patientData, setPatientData] = useState({})
    const [profile, setProfile] = useState();
    const [showAll, setShowAll] = useState(false);
    const [activeScrollTab, setActiveScrollTab] = useState('Doctor Notes');
    const [visibleSection, setVisibleSection] = useState('Doctor Notes');
    const scrolltabs = ['Doctor Notes', 'Prescription', 'Uploaded Data', 'Lab Notes', 'Radiology'];
    const [visibleTabs, setVisibleTabs] = useState(scrolltabs.slice(0, 3));
    const [startIndex, setStartIndex] = useState(0);
    const sectionRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const tabsContainerRef = useRef(null);

    const menuRef = useRef();
    const navigate = useNavigate();
    const [vitalData, setVitalData] = useState({
        weight: '',
        height: '',
        body_temperature: '',
        blood_pressure: '',
        pulse_rate: '',
        respiration_rate: '',
        updateStatusRequired: true
    })

    useEffect(() => {
        if (isMessagePopupOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [isMessagePopupOpen]);

    const GetVitals = (guid) => {
        store.GetPatientVitals(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': guid, patient_id: patient_id, appointment_id: appointmentId }))
            .then((result) => {
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        let vitals = JSON.parse(response);
                        setVitalData({ ...vitals, updateStatusRequired: true })
                    })
                } else {
                    setVitalData({
                        weight: '',
                        height: '',
                        body_temperature: '',
                        blood_pressure: '',
                        pulse_rate: '',
                        respiration_rate: '',
                        clinic_guid: guid,
                        appointment_id: appointmentId,
                        patient_id: patient_id,
                        updateStatusRequired: true
                    })
                }
            })
            .catch(err => {
                dispatch(setNotification({ message: 'Error Occurred', status: 'error', action: true }));
            })
    }
    const GetDoctors = (guid) => {
        store.getDoctorForAppointment(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': guid }))
            .then((result) => {
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        let data = JSON.parse(response);
                        setDoctors(data.drDetails);
                        setDoctorsTimeSlot(data.drDetailstimeSlots);
                    })
                } else {
                    setDoctors([])
                    setDoctorsTimeSlot([])
                }
            })
            .catch(err => {
                dispatch(setNotification({ message: 'Error Occurred', status: 'error', action: true }));
            })
    }
    useEffect(() => {
        if (patientAddAppointment?.isOpenAppointment) {
            dispatch(setAddAppointmentPopup(true));
        }
    }, [patientAddAppointment])
    const GetPatientFiles = (guid) => {
        store.GetPatientFiles(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': guid, 'patient_id': patient_id, 'appointment_id': appointmentId }))
            .then((result) => {
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        let data = JSON.parse(response);
                        setFiles(data);
                        setOriginalFiles(data);
                        setExpandRow(data.length > 0 ? true : false)
                    })
                } else {
                    setFiles([])
                    setOriginalFiles([])
                }
            })
            .catch(err => {
                setFiles([])
                setOriginalFiles([])
                dispatch(setNotification({ message: 'Error Occurred', status: 'error', action: true }));
            })
    }
    const GetAllPatientDataAavailable = (guid) => {
        store.GetAllPatientDataAavailable(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': guid, 'appointment_id': appointmentId, 'patient_id': patient_id }))
            .then((result) => {
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        let data = JSON.parse(response);
                        dispatch(setDoctorNotesDataAvailable(data.isDoctorNotesDataAvailable))
                        dispatch(setVisitHistoryDataAvailable(data.isVisitHistoryDataAvailable))
                        dispatch(setPrescriptionDataAvailabale(data.isPrescriptionDataAvailabale))
                        dispatch(setUploadedDataAvailable(data.isUploadedDataAvailable))
                        dispatch(setLabNotesDataAvailable(data.isLabNotesDataAvailable))
                        dispatch(setRadiologyDataAvailable(data.isRadiologyDataAvailable))
                    })
                } else {
                    dispatch(setDoctorNotesDataAvailable(false))
                    dispatch(setVisitHistoryDataAvailable(false))
                    dispatch(setPrescriptionDataAvailabale(false))
                    dispatch(setUploadedDataAvailable(false))
                    dispatch(setLabNotesDataAvailable(false))
                    dispatch(setRadiologyDataAvailable(false))
                }
            })
            .catch(err => {
                dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
            })
    }

    useEffect(() => {
        if (loggeduser && patient_id > 0)
            GetAllPatientDataAavailable(loggeduser.facilityID);
    }, [loggeduser, refreshData, refreshUploadedFiles, appointmentId])

    const handleGloablSave = () => {
        if (isGlobalSaveRequired) {
            setSaveAllLoading(true)
            dispatch(setAutoSaveAll(!autoSaveAll))
        }
        if (isAnyChanges) {
            setSaveAllLoading(true)
            handleUpdateVitals();
        }
        setTimeout(() => {
            dispatch(setIsGlobalSaveRequired(false))
            setSaveAllLoading(false)
        }, 2000)
    };

    useEffect(() => {
        handleGloablSave();
    }, [])

    window.addEventListener("beforeunload", (event) => {
        handleGloablSave();
    });
    const CheckIsInvoiceExist = () => {
        store.CheckIsInvoiceExist(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, patient_id: patient_id, appointment_id: appointmentId, invoice_for: "Appointment" }))
            .then((result) => {
                if (result.status == 200) {
                    dispatch(setIsInvoiceExist(true));
                } else {
                    dispatch(setIsInvoiceExist(false));
                }
            })
            .catch(err => {
                dispatch(setNotification({ message: 'Error Occurred', status: 'error', action: true }));
            })
    }

    useEffect(() => {
        if (fileType === "All") {
            setFiles(originalFiles);
        } else {
            let file = originalFiles.filter(a => a.file_type === fileType);
            setFiles(file);
        }
    }, [fileType, originalFiles])

    useEffect(() => {
        if (loggeduser) {
            GetPatientFiles(loggeduser.facilityID)
        }
    }, [loggeduser, refreshImage, refreshUploadedFiles])

    useEffect(() => {
        if (loggeduser && appointmentId > 0) {
            GetVitals(loggeduser.facilityID)
            setVitalData({ ...vitalData, clinic_guid: loggeduser.facilityID, appointment_id: appointmentId, patient_id: patient_id, updateStatusRequired: true })
        }
    }, [loggeduser, openVitals, appointmentId])

    useEffect(() => {
        if (loggeduser) {
            CheckIsInvoiceExist();
            GetDoctors(loggeduser.facilityID);
        }
    }, [loggeduser])

    const setTab = (newTab) => {
        setActiveSection(newTab)
    };
    const dispatch = useDispatch();

    useEffect(() => {
        setclient(true);
        sections.current = document.querySelectorAll('[data-section]');
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const handleSvgClick = () => {
        setExpandRow(!expandRow)
    }

    const handleChange = (id) => {
        setFiles(files.map(item =>
            item.patient_files_id === id ? { ...item, is_active: !item.is_active } : item
        ));
    }

    useEffect(() => {
        // Update selectAll state based on individual item selection
        const allSelected = files.every(item => !item.is_active);
        setSelectAll(allSelected && files.length > 0);
    }, [files]);

    const handleDelete = () => {
        const atLeastOneSelected = files.some(item => !item.is_active);
        if (atLeastOneSelected) {
            setConfirmBox(true)
        } else {
            dispatch(setNotification({ message: 'Please select at least one Image to delete!', status: 'error', action: true }));
        }
    }

    const handleDeleteSubmit = () => {
        setLoadingDeleteImage(true);
        store.DeletePatientFile(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, 'patient_id': patient_id, files: files }))
            .then((result) => {
                setLoadingDeleteImage(false)
                setConfirmBox(false)
                if (result.status == 204) {
                    setTimeout(() => {
                        setRefreshImage(o => !o);
                    }, 100)
                    dispatch(setNotification({ message: 'Deleted Successfully!', status: 'success', action: true }));
                }
                else {
                    dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                }

            })
            .catch((error) => {
                console.error("Error occurred while saving patient details:", error);
            });
    }

    const handleSelectAll = (e) => {
        setFiles(files.map(item => ({ ...item, is_active: !e.target.checked })));
    }

    const handleScroll = () => {
        const pageYOffset = window.pageYOffset;
        let newActiveSection = null;
        sections.current.forEach((section) => {
            const sectionOffsetTop = section.offsetTop;
            const sectionHeight = section.offsetHeight;
            if (typeof window !== 'undefined' && window.screen.width > 990) {
                if (pageYOffset + 140 >= sectionOffsetTop && pageYOffset + 140 < sectionOffsetTop + sectionHeight) {
                    newActiveSection = section.id;
                }
            } else {
                if (pageYOffset + 185 >= sectionOffsetTop && pageYOffset + 185 < sectionOffsetTop + sectionHeight) {
                    newActiveSection = section.id;
                }
            }

        });

        if (newActiveSection !== null) {
            setActiveSection(newActiveSection);
        }
    };


    let tabs = [];
    tabs = [
        {
            key: 'patientdetails', data: patientdetails, title: 'Patient Details'
        },
        // {
        //     key: 'currentmedication', data: currentmedication, title: 'Current Medication'
        // },
        {
            key: 'pastvisithistory', data: pastvisithistory, title: 'Visit History'
        },
    ];

    const GetPatient = (guid) => {
        store.GetPatient(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': guid, 'patient_id': patient_id }))
            .then((result) => {
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        setPatient(JSON.parse(response));
                    })
                } else {
                    setPatient({})
                }
            })
            .catch(err => {
                dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
            })
    }
    useEffect(() => {
        if (appointment_id == 0 && appointmentData?.appointment_id && appointmentData.appointment_id > 0) {
            setAppointmentId(appointmentData.appointment_id);
            setAppointment(appointmentData)
            let data = btoa(JSON.stringify({ appointment_id: appointmentData.appointment_id, patient_id: patient_id, doctor_id: appointmentData.doctor_id || 0, clinic_guid: loggeduser.facilityID, page_src: "appointment" }))
            window.history.pushState(null, null, `/patient-profile?profile=${data}`);
            const urlChangeEvent = new Event('urlChange');
            window.dispatchEvent(urlChangeEvent);
        } else {
            setAppointmentId(appointment_id)
        }
    }, [appointment_id, appointmentData]);

    useEffect(() => {
        if (doctor_id == 0 && appointmentData?.doctor_id && appointmentData.doctor_id > 0) {
            setDoctorId(appointmentData.doctor_id);
        } else {
            setDoctorId(doctor_id)
        }
    }, [doctor_id, appointmentData]);

    const GetAppointmnet = (guid) => {
        store.GetAppointmnet(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': guid, 'appointment_id': appointmentId }))
            .then((result) => {
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        setAppointment(JSON.parse(response));
                    })
                } else {
                    // setAppointment({})
                }
            })
            .catch(err => {
                dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
            })
    }

    const handleFollowUp= () =>{
        openAppointmentPopup();
        setShowFollowUpPopup(false);
        let appointmentDetails = {
            "appointment_id": 0,
            "patient_id": appointment.patient_id,
            "patient_name": patient.patient_name,
            "doctor_id": doctorId,
            "mobile_number": appointment.mobile_number,
            "appointment_time": null,
            "appointment_type": "Follow Up",
            "doctor_name": appointment.doctor_name,
            "clinic_guid": appointment.clinic_guid
        }
        setFollowUpData(appointmentDetails);
        setTimeout(()=>{
            dispatch(setAddAppointmentPopup(true));
            dispatch(setPatientAppointment({ isOpenAppointment: true, patient: patient, path: 'appointment' }));
        },100)
  
    }

    useEffect(() => {
        GetPatient(loggeduser.facilityID)
    }, [loggeduser]);

    useEffect(() => {
        GetAppointmnet(loggeduser.facilityID)
    }, [loggeduser, refreshData]);


    function handleClick(eleRef) {
        window.scrollTo({ top: typeof window !== 'undefined' && window.screen.width > 990 ? eleRef.current.offsetTop - 140 : eleRef.current.offsetTop - 85, behavior: "smooth" })
    }
    const tabsButtons = tabs.map((tab) => {

        return <button key={tab.key} id={tab.key} aria-label={tab.title} style={{ padding: tab.key ? "8px 15px" : "", fontWeight: "bold", "cursor": activeSection == tab.key ? "default" : "", "color": activeSection == tab.key ? "#a31b1b" : "black" }} type="button" onClick={() => { setTab(tab.key); handleClick(tab.data) }} >{tab.title}</button>
    })

    const completeSignOff = () => {
        setSignOffDisableButton(true);
        dispatch(setAutoSaveAll(!autoSaveAll))
        store.CompleteSignOff(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, 'appointment_id': appointmentId, '_time': DateFunction.SetDbCurrentTimeStamp(), 'patient_id': patient_id }))
            .then((result) => {
                setSignOffDisableButton(false);
                setConfirmBoxSignOff(false);
                setIsAnyChanges(false)
                if (result.status == 204) {
                    store.CreateActivityLogs(loggeduser, "Appointment to Patient Profile", `Appointment Successfully Signed Off - ${(appointment?.doctor_name ? "for " + appointment?.doctor_name?.trim():"")} with patient ${patient.FirstName} ${patient.MiddleName?.charAt(0)} ${patient.LastName}(${patient?.patient_op_number}) on ${DateFunction.GetDate(appointment?.appointment_time)} ${(appointment?.app_time ? " at " + DateFunction.Convert12Hour(appointment?.app_time) : "")}`, "Completed");
                    setRefreshData(o => !o);
                    dispatch(setNotification({ message: 'Successfully Signed Off!', status: 'success', action: true }));
                    setShowFollowUpPopup(true);
                } else {
                    dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                }
            })
            .catch(err => {
                dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
            })
    }
    const handleMessagePopupOpen = () => {
        setIsMessagePopupOpen(!isMessagePopupOpen);
    }
    const handleUpdateVitals = () => {

        if (editVitalsFirstSec) {
            setLoadVitalsFirstSec(true)
        }
        if (editVitalsSecondSec) {
            setLoadVitalsSecondSec(true)
        }
        store.UpdateVitals(loggedusertoken, DataDecode.encryptPayload(vitalData))
            .then((result) => {
                setLoadVitalsFirstSec(false);
                setLoadVitalsSecondSec(false);
                if (result.status == 204) {
                    dispatch(setNotification({ message: 'Vitals Updated Successfully!', status: 'success', action: true }));
                    setEditVitalsFirstSec(false);
                    setEditVitalsSecondSec(false);
                }
                else {
                    dispatch(setNotification({ message: 'Vitals Not Updated!', status: 'error', action: true }));
                }
            })
            .catch(err => {
                setLoadVitalsFirstSec(false);
                setLoadVitalsSecondSec(false);
                dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
            })

    }
    const generateInvoice = () => {
        dispatch(setAppointmentInvoicePrint(true))
        dispatch(setAppointmentInvoice({ patient: patient, doctor: appointment.doctor_id, appointment: appointment, targetFrom: 'Appointment' }))
    }
    const handleUpload = () => {
        setUploadPopup(false);
        setShowUploadFiles(true);
    }
    const uploadClose = () => {
        setUploadPopup(false);
    }

    const handleCapture = () => {
        setCapturePopup(true);
    }
    const captureClose = () => {
        setCapturePopup(false);
    }


    const handleOkay = () => {
        setCapturePopup(false);
        setShowUploadFiles(true);
    };

    const handleUploadButton = (type) => {
        if (appointmentId == 0 || (appointment && (appointment.status == 'Completed'))) {
            handleMessagePopupOpen(true);
            dispatch(setIsCameraUpload(type === "capture" ? true : false));
        } else {
            dispatch(setShowDocumentUploadPopup(true))
            dispatch(setIsCameraUpload(type === "capture" ? true : false));
        }
    }

    const openAppointmentPopup = () => {
        dispatch(setAddAppointmentPopup(true));
        dispatch(setPatientAppointment({ isOpenAppointment: true, patient: patient, path: 'appointment' }));
    };

    useEffect(() => {
        setVisibleTabs(prevVisibleTabs => {
            const newVisibleTabs = scrolltabs.slice(startIndex, startIndex + 3);
            return JSON.stringify(prevVisibleTabs) === JSON.stringify(newVisibleTabs)
                ? prevVisibleTabs
                : newVisibleTabs;
        });
    }, [startIndex, scrolltabs]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY + 77 + tabsContainerRef.current.offsetHeight;

            for (const tab of scrolltabs) {
                const element = sectionRefs.current[tab].current;
                if (element && element.offsetTop <= scrollPosition && element.offsetTop + element.offsetHeight > scrollPosition) {
                    setVisibleSection(tab);
                    updateTabsForSection(tab);
                    break;
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [scrolltabs]);

    const sectionRefs = useRef(scrolltabs.reduce((acc, tab) => {
        acc[tab] = React.createRef();
        return acc;
    }, {}));


    const scrollOneTab = (direction) => {
        if (direction === 'right' && startIndex < scrolltabs.length - 3) {
            setStartIndex(prevIndex => {
                const newIndex = prevIndex + 1;
                setVisibleSection(scrolltabs[newIndex]);
                return newIndex;
            });
        } else if (direction === 'left' && startIndex > 0) {
            setStartIndex(prevIndex => {
                const newIndex = prevIndex - 1;
                setVisibleSection(scrolltabs[newIndex]);
                return newIndex;
            });
        }
    };
    const updateTabsForSection = (section) => {
        const sectionIndex = scrolltabs.indexOf(section);
        if (sectionIndex >= 0) {
            if (sectionIndex < startIndex) {
                setStartIndex(Math.max(0, sectionIndex));
            } else if (sectionIndex >= startIndex + 3) {
                setStartIndex(Math.min(scrolltabs.length - 3, sectionIndex - 2));
            }
            setActiveScrollTab(section);
        }
    };

    const toggleVisibility = () => {
        if (window.pageYOffset > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        };
    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);
    const scrollToSection = (tab) => {
        const sectionRef = sectionRefs.current[tab];
        const tabsContainer = tabsContainerRef.current;

        if (sectionRef && sectionRef.current && tabsContainer) {
            const element = sectionRef.current;
            const tabsContainerRect = tabsContainer.getBoundingClientRect();

            const scrollToPosition = element.offsetTop - tabsContainerRect.height - 77;
            window.scrollTo({
                top: scrollToPosition,
                behavior: 'smooth'
            });

            updateTabsForSection(tab);
        }
    };

    const handleUploadWithoutAppointment = () => {
        handleMessagePopupOpen(false);
        dispatch(setShowUploadPopup(true));
        dispatch(setIsWithoutAppointment(true));
    }
    const GetPastHistory = () => {
        store.GetPastHistory(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': clinic_guid, 'patient_id': patient_id }))
            .then((result) => {
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        let data = JSON.parse(response);
                        setPastHistory(data);
                        if (data.length > 0) {
                            setExpandRow(true);
                        }
                    })
                } else {
                    setPastHistory([])
                }
            })
            .catch(err => {
                setPastHistory([])
                dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
            })
    }

    useEffect(() => {
        GetPastHistory();
    }, [patient_id, clinic_guid, refreshData])

    const viewDetails = (history) => {
        setIsOpen(false);
        setShowSecondaryMenu(history === 'Current Visit');

        if (history !== 'Current Visit') {
            const data = btoa(JSON.stringify({
                appointment_id: history.appointment_id,
                patient_id: history.patient_id,
                doctor_id: history.doctor_id,
                clinic_guid: loggeduser.facilityID,
                viewHistoryData: history
            }));

            window.location.href = `/past-history-details?history=${data}`;
        }
    };

   

    const toggleMenu = (e) => {
        e.stopPropagation(); 
        setOpenMenu(prevState => !prevState);
    };

   
    
    const handleProfileClick = () => {
 
        setShowPatient(true);
        setOpenMenu(false);
    };
    const handleVitalsClick = () => {
       setOpenVitals(true);
        setOpenMenu(false); 
    };

    
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setOpenMenuIndex(null);
        }
      };
    
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);
    return (
        <>
            {loggeduser && clinic_guid === loggeduser.facilityID ?
                <div className="mx-auto px-4 lg:py-8 my-20 ">
                    <div className='lg:hidden xs:block'>
                        <div className='flex flex-col'>
                            <div className="mt-2 origin-top-left flex font-bold mb-2 lg:text-black text-red-700 lg:text-xl sm:text-xl justify-between items-center">
                                <svg id="MobPatientProfileSvgmobReloadIcon" onClick={() => { window.history.back() }} xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 8 12" fill="none">
                                    <path d="M7.70492 1.41L6.29492 0L0.294922 6L6.29492 12L7.70492 10.59L3.12492 6L7.70492 1.41Z" className="fill-red-800" />
                                </svg>

                                <div className='flex justify-between w-full'>
                                    <div>
                                        <span id="MobPatientProfileSpanpatientDetails" className={`ml-3 ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewTheme.fontFamily }}>
                                            {patient?.FirstName && (patient?.FirstName + " " + patient?.MiddleName?.charAt(0) + " " + patient?.LastName)}
                                        </span>
                                    </div>
                                    <div className='flex lg:mr-10 gap-2'>
                                    </div>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <div className="relative inline-block text-left">
                                        <div
                                            className="flex items-center justify-between w-40 px-1 py-1 bg-gray-300 border border-gray-300 rounded-md shadow-sm cursor-pointer"
                                            onClick={() => setIsOpen(!isOpen)}
                                        >
                                            <span id="MobPatientProfileSvglabel"className="text-sm font-medium text-gray-700">{pageSrc==='patient'?"Select Visit":'Current Visit'}</span>
                                            <svg id="MobPatientProfileSvgDropdownIcon"  xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                                <path d="M14.25 6.5H3.75L9 14.75L14.25 6.5Z" fill="#93939A" />
                                            </svg>
                                        </div>

                                        {isOpen && (
                                            <div ref={menuRef} className="absolute z-30 w-40  bg-white border border-gray-300 rounded-md shadow-lg">
                                                 <ul className="px-1 py-1  items-center ">
                                                    {pastHistory?.map((history, index) => (
                                                        <li key={'current-medicine-' + index} className='cursor-pointer text-gray-800 flex items-center' onClick={() => viewDetails(history)}>
                                                            <svg  id="MobPatientProfileSvgmobDatesIcon"  xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 15 15" fill="none">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M2.5 7.5C2.5 4.39375 5.01875 1.875 8.125 1.875C11.2312 1.875 13.75 4.39375 13.75 7.5C13.75 10.6062 11.2312 13.125 8.125 13.125C6.56875 13.125 5.16875 12.4937 4.15 11.475L5.0375 10.5875C5.825 11.3812 6.91875 11.875 8.125 11.875C10.5437 11.875 12.5 9.91875 12.5 7.5C12.5 5.08125 10.5437 3.125 8.125 3.125C5.70625 3.125 3.75 5.08125 3.75 7.5H5.625L3.125 9.99375L0.625 7.5H2.5ZM7.5 8.125V5H8.4375V7.59375L10.6375 8.9L10.1562 9.7L7.5 8.125Z" fill="#252539" />
                                                            </svg>
                                                            <span className="px-4 py-1 text-left">{DateFunction.GetDate(history.appointment_time)}</span>
                                                        </li>


                                                    ))}
                                                </ul>
                                            </div>

                                        )}
                                    </div>


                                    <td className="px-1 py-1 text-center cursor-pointer mt-2">
                                        <button id="PatientsBtnmenu" onClick={toggleMenu}>
                                            <svg id="PatientsBtnmenuIcon" xmlns="http://www.w3.org/2000/svg" width="6" height="19" viewBox="0 0 6 19" fill="none">
                                                <g opacity="0.5">
                                                    <path d="M3 3.86C3.55228 3.86 4 3.44362 4 2.93C4 2.41638 3.55228 2 3 2C2.44772 2 2 2.41638 2 2.93C2 3.44362 2.44772 3.86 3 3.86Z" stroke="#A31B1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M3 10.68C3.55228 10.68 4 10.2636 4 9.75001C4 9.23638 3.55228 8.82001 3 8.82001C2.44772 8.82001 2 9.23638 2 9.75001C2 10.2636 2.44772 10.68 3 10.68Z" stroke="#A31B1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M3 17.5C3.55228 17.5 4 17.0836 4 16.57C4 16.0564 3.55228 15.64 3 15.64C2.44772 15.64 2 16.0564 2 16.57C2 17.0836 2.44772 17.5 3 17.5Z" stroke="#A31B1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </g>
                                            </svg>
                                        </button>
                                        {openMenu && (
                                            <div id="PatientsDivmobileActionMenu" ref={menuRef} className="absolute mt-[-40px] right-6 w-30 bg-white border border-gray-300 rounded-md z-20">
                                                <ul>
                                                    <li
                                                        id="PatientsLImobileEditAction"
                                                        className="px-3 py-1 hover:bg-gray-100 cursor-pointer flex items-center gap-2 text-gray-800"
                                                        onClick={() => handleProfileClick(patient)}
                                                    >
                                                        <svg
                                                        id="MobPatientProfileSvgmobProfileIcon" 
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="14"
                                                            height="14"
                                                            viewBox="0 0 14 14"
                                                            fill="none"
                                                            className='w-4 h-4'
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M7.00003 2.33341C6.1838 2.33327 5.38181 2.5472 4.67411 2.95386C3.96641 3.36053 3.37774 3.9457 2.96687 4.65097C2.556 5.35624 2.33729 6.15694 2.33258 6.97315C2.32787 7.78936 2.53732 8.59253 2.94003 9.3025C3.21222 8.94875 3.56211 8.66234 3.96266 8.4654C4.36321 8.26847 4.80368 8.16628 5.25003 8.16675H8.75003C9.19637 8.16628 9.63684 8.26847 10.0374 8.4654C10.4379 8.66234 10.7878 8.94875 11.06 9.3025C11.4627 8.59253 11.6722 7.78936 11.6675 6.97315C11.6628 6.15694 11.4441 5.35624 11.0332 4.65097C10.6223 3.9457 10.0336 3.36053 9.32594 2.95386C8.61824 2.5472 7.81625 2.33327 7.00003 2.33341ZM11.6334 10.5444C11.7066 10.4491 11.7766 10.3515 11.8434 10.2516C12.4901 9.29065 12.8349 8.15836 12.8334 7.00008C12.8334 3.77833 10.2218 1.16675 7.00003 1.16675C3.77828 1.16675 1.16669 3.77833 1.16669 7.00008C1.16485 8.28152 1.58672 9.52762 2.36661 10.5444L2.36369 10.5549L2.57078 10.7958C3.11788 11.4355 3.79717 11.9489 4.56183 12.3006C5.32649 12.6524 6.15833 12.8342 7.00003 12.8334C7.12603 12.8334 7.25125 12.8295 7.37569 12.8217C8.42826 12.7554 9.44284 12.4035 10.3104 11.8038C10.7254 11.5176 11.1015 11.1788 11.4293 10.7958L11.6364 10.5549L11.6334 10.5444ZM7.00003 3.50008C6.5359 3.50008 6.09078 3.68446 5.76259 4.01264C5.4344 4.34083 5.25003 4.78595 5.25003 5.25008C5.25003 5.71421 5.4344 6.15933 5.76259 6.48752C6.09078 6.81571 6.5359 7.00008 7.00003 7.00008C7.46416 7.00008 7.90927 6.81571 8.23746 6.48752C8.56565 6.15933 8.75003 5.71421 8.75003 5.25008C8.75003 4.78595 8.56565 4.34083 8.23746 4.01264C7.90927 3.68446 7.46416 3.50008 7.00003 3.50008Z"
                                                                fill="#2E3A59"
                                                            />
                                                        </svg>
                                                        Profile
                                                    </li>
                                                    {doctorId > 0 && (
                                                        <li
                                                            id="PatientsLImobileDeleteAction"
                                                            className="px-3 py-1 hover:bg-gray-100 cursor-pointer flex items-center gap-2 text-gray-800"
                                                            onClick={handleVitalsClick}
                                                        >
                                                            <svg  id="MobPatientProfileSvgmobVitalsIcon" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" className='w-4 h-4'>
                                                                <path d="M1.75 7.58336H3.3775L5.89167 2.79419L6.58 8.02086L8.45833 5.63502L10.4008 7.58336H12.25V8.75002H9.91667L8.5575 7.39086L5.78667 10.9259L5.215 6.59752L4.08333 8.75002H1.75V7.58336Z" fill="#2E3A59" />
                                                            </svg>
                                                            Vitals
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        )}
                                    </td>
                               </div>
                            </div>                            
                            <div ref={tabsContainerRef} className={`sticky top-[77px] bg-white ${(!isMobileSidebarOpen && !isProfileDrawerOpen) ? 'z-10' : ''}`}>
                                <div className="relative mt-2 flex items-center bg-white shadow" id='Tabs'>
                                    {startIndex > 0 && (
                                        <button id='BtntableftButtonProfile'
                                            onClick={() => scrollOneTab('left')}
                                            className="focus:outline-none"
                                        >
                                            <svg id='SvgtableftIcon' xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                                            </svg>
                                        </button>
                                    )}
                                    <div id="MobPatientProfileDivtabsContainer" className="flex-grow overflow-hidden">
                                        <nav id="MobPatientProfileNavtabs" className="flex">
                                            {visibleTabs.map((tab, index) => (
                                                <button id={'BtntablistButton' + index}
                                                    key={tab}
                                                    className={`py-2 px-4 text-sm font-bold h-14 whitespace-nowrap flex-grow ${activeScrollTab === tab
                                                        ? 'text-blue-500 border-b-2 border-red-800'
                                                        : 'text-gray-400'
                                                        }`}
                                                    onClick={() => scrollToSection(tab)}
                                                >
                                                    {tab}
                                                </button>
                                            ))}
                                        </nav>
                                    </div>
                                    {startIndex < scrolltabs.length - 3 && (
                                        <button
                                            onClick={() => scrollOneTab('right')}
                                            className="focus:outline-none" id='BtnTableft2Button'
                                        >
                                            <svg id='Svgtab2leftIcon' xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                            </svg>
                                        </button>
                                    )}
                                </div>

                            </div>


                            <div ref={sectionRef} className='flex-grow overflow-y-auto px-auto' style={{ scrollbarWidth: 'none', 'msOverflowStyle': 'none' }}>
                                <div className="grid gap-2 xs:grid-cols-1 lg:grid-cols-2">
                                    <div ref={sectionRefs.current['Doctor Notes']} id='DoctorNotes'>
                                        <DoctorNotesMobile appointment={appointment} clinicGuid={clinic_guid} appointment_id={appointmentId} patient_id={patient_id} page_src={pageSrc} setIsMessagePopupOpen={setIsMessagePopupOpen} patient={patient}/>
                                    </div>                                    
                                    <div ref={sectionRefs.current['Prescription']} id='Prescription'>
                                        <Prescription appointment={appointment} clinicGuid={clinic_guid} patient={patient} patient_id={patient_id} doctor_id={doctorId} appointment_id={appointmentId} page_src={pageSrc} setIsMessagePopupOpen={setIsMessagePopupOpen}/>
                                    </div>
                                    <div ref={sectionRefs.current['Uploaded Data']}>
                                        <UploadedDataMobile appointment={appointment} clinicGuid={clinic_guid} appointment_id={appointmentId} patient_id={patient_id} page_src={pageSrc} setIsMessagePopupOpen={setIsMessagePopupOpen} patient={patient}/>
                                    </div>
                                    <div ref={sectionRefs.current['Lab Notes']}>
                                        <LabNotesMobile appointment={appointment} clinicGuid={clinic_guid} appointment_id={appointmentId} patient_id={patient_id} page_src={pageSrc} setIsMessagePopupOpen={setIsMessagePopupOpen} patient={patient}/>
                                    </div>
                                    <div ref={sectionRefs.current['Radiology']} className='pb-[390px]'>
                                        <Radiology appointment={appointment} clinicGuid={clinic_guid} appointment_id={appointmentId} patient_id={patient_id} page_src={pageSrc} setIsMessagePopupOpen={setIsMessagePopupOpen}  patient={patient}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {appointment.status !== 'Completed' && appointmentId > 0 && (isGlobalSaveRequired || isAnyChanges) &&
                        <div className={`fixed bottom-40 right-4 ${(!isMobileSidebarOpen && !isProfileDrawerOpen) ? 'z-20' : ''}`}>
                            <button id="MobPatientProfilebtnSaveAllAppointment" onClick={handleGloablSave}>
                                <IoMdSave className='text-green-600 size-8 cursor-pointer ml-2 bg-white rounded-lg sm:mt-0' />
                                {saveAllLoading &&
                                    <svg aria-hidden="true" id="MobPatientProfileimgSaveAllAppointment" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                    </svg>}
                            </button>
                        </div>
                    }
                    {appointment.status !== 'Completed' && appointmentId > 0 &&
                        <div className={`fixed bottom-24 right-4 ${(!isMobileSidebarOpen && !isProfileDrawerOpen) ? 'z-20' : ''}`}>
                            {isInvoiceEnabled &&
                                <button className=" bg-blue-600 hover:bg-blue-700 text-white p-2 pl-4 pr-4 rounded-full shadow-lg transition-colors mr-3 mb-2" onClick={generateInvoice} id='BtninvoiceButton'>
                                    {invoiceExist ? "Update Invoice" : "Generate Invoice"}
                                </button>}
                            <button className=" bg-green-600 hover:bg-green-700 text-white p-2 pl-4 pr-4 rounded-full shadow-lg transition-colors" onClick={() => setConfirmBoxSignOff(true)} id='BtnFinishAppButton'>
                                Finish Appointment
                            </button>
                        </div>
                    }

                    <div className='mb-10'></div>
                    {showAppointmentInvoicePrint && <GenerateInvoice page="Appointment to Patient Profile"/>}
                    {uploadPatientFile && <UploadPatientFiles setUploadPatientFile={setUploadPatientFile} patient_id={patient_id} appointment_id={appointmentId} setExpandRow={setExpandRow} setRefreshImage={setRefreshImage} />}
                    {openVitals && <Vitals openVitals={openVitals} setOpenVitals={setOpenVitals} patient={{...patient, appointment:appointment}} appointment_id={appointmentId} setRefreshData={setRefreshData} page={`${patientProfileMenuSelector === "appointment" ? "Appointment to " : "Patient to " }Patient Profile`}/>}
                    {showDocumentUploadPopup && <ChooseDocument appointmentStatus={appointment.status} />}
                    {showUploadPopup && <UploadDocument patient_id={patient_id} appointment_id={appointmentId} patient={patient} appointment={appointment} page={`${patientProfileMenuSelector === "appointment" ? "Appointment to " : "Patient to " }Patient Profile`}/>}
                    {showAddAppointmentpopup && <AddAppointment setRefreshData={setRefreshData} doctors={doctors} appointment={followUpData} doctorsTimeslot={doctorsTimeslot} page={`${patientProfileMenuSelector === "appointment" ? "Appointment to " : "Patient to " }Patient Profile`}/>}
                    {showAddPatientpopup && <AddPatient doctors={doctors} setRefreshData={setRefreshData} patient={{}} page="Patient Profile Book Appointment Popup"/>}
                    {viewHistory && <PastHistoryDetails setViewHistory={setViewHistory} viewHistoryData={viewHistoryData} patient={patient} doctors={doctors} appointment_id={appointmentId} patient_id={patient_id} appointment={appointment} page_src={pageSrc}/>}
                   
                    {confirmBox &&
                        <div id="MobPatientProfiledelete" tabIndex="-1" aria-hidden="true" className="fixed z-[9999] lg:left-[40%] lg:w-80 top-[10rem] lg:top-[3rem] lg:translate-x-[50%] translate-y-[50%]">

                            <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                                <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                                    <svg id="MobPatientProfileSvgconfirmIcon" className="text-red-600 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
                                    <p id="MobPatientProfilePconfirmDelete" className="mb-4 text-gray-500 dark:text-gray-300">Are you sure you want to delete this Images?</p>
                                    <div className="flex justify-center items-center space-x-4">
                                        <button id='BtnCancelButton' onClick={() => setConfirmBox(false)} disabled={loadingDeleteImage} type="button" className={`py-2 px-3 text-sm font-medium rounded-lg border border-gray-200 focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10 ${previewTheme.cancelBtnColor} ${previewTheme.cancelBtnBgColor} hover:${previewTheme.cancelBtnHoverColor}`}>
                                            No, cancel
                                        </button>
                                        <button id='BtnSubmitButton' type="button" onClick={handleDeleteSubmit} disabled={loadingDeleteImage} className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900 disabled:opacity-50 disabled:cursor-not-allowed">
                                            Yes, I'm sure {loadingDeleteImage &&
                                                <svg id="MobPatientProfileSvgDeleteConfirmIcon" aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                </svg>
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {confirmBoxSignOff &&
                        <div id="MobPatientProfileconfirm-signOff" tabIndex="-1" aria-hidden="true" className="fixed lg:left-[40%] lg:w-80 top-[10rem] lg:top-[3rem] lg:translate-x-[50%] translate-y-[50%] z-[999999]">
                            <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                                <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                                    <svg id="MobPatientProfileSvgconfirmBoxIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-blue-600 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto">
                                        <path fillRule="evenodd" d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
                                    </svg>
                                    <p id="MobPatientProfilePsignOff" className="mb-4 text-gray-500 dark:text-gray-300">Are you sure to sign off the appointment?</p>
                                    <div className="flex justify-center items-center space-x-4">
                                        <button id='BtnCancelSignOff' onClick={() => setConfirmBoxSignOff(false)} disabled={signOffDisableButton} type="button" className={`py-2 px-3 text-sm font-medium  rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10 ${previewTheme.cancelBtnColor} ${previewTheme.cancelBtnBgColor} hover:${previewTheme.cancelBtnHoverColor}`}>
                                            No, cancel
                                        </button>
                                        <button id='BtnSubmitSignOff' type="button" onClick={completeSignOff} disabled={signOffDisableButton} className="py-2 px-3 text-sm font-medium text-center text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-900 disabled:opacity-50 disabled:cursor-not-allowed">
                                            Yes, I'm sure {signOffDisableButton &&
                                                <svg  id="MobPatientProfileSvgConfirmCompleteSihnOffIcon" aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                </svg>
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {showFollowUpPopup &&
                        <div id="confirm-followUp" tabIndex="-1" aria-hidden="true" className="fixed inset-0 flex items-center justify-center z-[999999]">
                            <div className="relative w-full max-w-xs p-4">
                                <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-blue-600 dark:text-gray-500 w-10 h-10 mb-3 mx-auto">
                                        <path fillRule="evenodd" d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
                                    </svg>
                                    <p className="mb-4 text-gray-500 dark:text-gray-300">Schedule a follow-up appointment?</p>
                                    <div className="flex justify-center items-center space-x-4">
                                        <button id='BtnNoFollowUp' onClick={() => setShowFollowUpPopup(false)} type="button" className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                                            No, thanks
                                        </button>
                                        <button id='BtnYesFollowUp' onClick={handleFollowUp} type="button" className="py-2 px-3 text-sm font-medium text-center text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-900">
                                            Yes, schedule
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                </div >
                :
                <p id="MobPatientProfilePnotAuthorized" className='text-red-600 font-semibold text-lg p-10 text-center my-20'>You are not authorized to perform this action!</p>
            }

            {
                isMessagePopupOpen &&
                <div className="fixed lg:left-[40%] w-80 left-[10%] top-[30%] z-[50]">
                    <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                        <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                            <div className="mb-4 text-gray-500 dark:text-gray-300">
                                <p id="MobPatientProfilePnoAppSchedule" >No Appointments scheduled.</p>
                                <p id="MobPatientProfilePconfirmSchedule">Do you want to schedule an appointment?</p>
                            </div>
                            <div className="flex justify-center items-center space-x-4">
                                <button id='BtnCancelButton' onClick={() => handleUploadWithoutAppointment()} className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                                    No, Proceed
                                </button>
                                <button id='BtnYesMessagePopUp' type="button" onClick={() => { handleMessagePopupOpen(false); openAppointmentPopup(); }} className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900 disabled:opacity-50 disabled:cursor-not-allowed">
                                    Yes, Proceed</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default MobilePatientProfile;