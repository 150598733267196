import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import DateFunction from "../../../../utils/DateFunctions";
import PastVisitHistory from "../PastVisitHistory";
import { theme } from "../../../../EmraxisTheme/Theme";
import store from "../../../../API/store";
import DataDecode from "../../../../utils/DataDecode";
import ReactToPrint from "react-to-print";
import { IoMdMale, IoMdSave } from "react-icons/io";
import { setNotification } from "../../../../Redux/features/toast/toastSlice";
import debounce from "lodash.debounce";
import { useLocation } from "react-router-dom";
import UploadDocument from "../DocumentUploads/UploadDocument";
import {
  setDocumentType,
  setIsCameraUpload,
  setRefreshNotes,
  setRefreshPrescription,
  setRefreshUploadedFiles,
  setShowDocumentUploadPopup,
  setShowUploadPopup,
} from "../../../../Redux/features/uploadDoc/uploadDocSlice";
import CreatableSelect from "react-select/creatable";
import ChooseDocument from "../DocumentUploads/ChooseDocument";
import FileIcon from "../FileIcon";
import { HiMiniPlusCircle } from "react-icons/hi2";
import DatePicker from "react-datepicker";
import PrescriptionPrintPreview from "../PrescriptionPrintPreview";
import VisitHistorySideNavbar from "./VisitHistorySideNavbar";
import ProfileSideNavbar from "../../../Patients/Profile/desktop/ProfileSideNavbar";

import {
  setActiveNavbar,
  setActiveHistoryNavbar,
  setActiveSubHistoryNavbar,
  setDoctorNotesDataAvailable,
  setIsGlobalSaveRequired,
  setLabNotesDataAvailable,
  setPrescriptionDataAvailabale,
  setRadiologyDataAvailable,
  setUploadedDataAvailable,
  setVisitHistoryDataAvailable,
  setNewAppointmentId,
  setHistoryVisitData,
} from "../../../../Redux/features/profileDrawer/profileSideNavbarSlice";
import {
  setActiveNavvisitbar,
  setAutoSaveAll,
  setSelectedAppointmentId,
  setpastSelected,
  setDoctorNotes,
  setPrescriptions,
  setUploadedData,
  setLabNotes,
  setRadiologyNotes,
  setRefreshNavBarScroll,
  setRefreshHistoryNavBarScroll,
} from "../../../../Redux/features/visitDrawer/visitSideNavbarSlice";
import DoctorContent from "./DoctorContent";
import LabContent from "./LabContent";
import PrescriptionContent from "./PrescriptionContent";
import UploadContent from "./UploadContent";
import RadiologyContent from "./RadiologyContent";
import DesktopPatientProfile from "../../../Patients/DesktopPatientProfile";
import { identity } from "lodash";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { setShowRecoringPopup, setStartRecording, setRecConfirmBox, setOpenRecordingPopUp } from '../../../../Redux/features/recording/recordingSlice';
import CustomAudioPlayer from '../../../../components/CustomAudioPlayer';
import { addLoginDetails } from "../../../../Redux/features/login/loginSlice";
const PastHistoryDetails = ({
  appointment_id,
  doctor_id,
  viewHistoryData,
  loggeduser,
  patient_id,
  page_src
}) => {
  var loggeduser = useSelector((state) => state.login.loginuserData);
  var loggedusertoken = useSelector((state) => state.login.userBarerToken);
  const menuRef = useRef();
  const divRef = useRef(null);
  var isDoctorNotesDataAvailable = useSelector(
    (state) => state.profileSideNavbar.isDoctorNotesDataAvailable
  );
  const previewTheme =
    useSelector((state) => state.theme.colors) ||
    theme.filter((a) => a.name === "defaultTheme")[0];
  var showDocumentUploadPopup = useSelector(
    (state) => state.document_upload.showDocumentUploadPopup
  );
  var showUploadPopup = useSelector(
    (state) => state.document_upload.showUploadPopup
  );
  var newAppointmentId = useSelector(
    (state) => state.profileSideNavbar.newAppointmentId
  );
  var refreshUploadedFiles = useSelector(
    (state) => state.document_upload.refreshUploadedFiles
  );
  var appointmentData = useSelector(
    (state) => state.popup.patientProfileAppointmentData
  );
  var visitHistory = useSelector(
    (state) => state.profileSideNavbar.visitHistory
  );
  var visitPastHistory = useSelector(
    (state) => state.profileSideNavbar.visitPastHistory
  );
  var autoSaveAll = useSelector((state) => state.visitSideNavbar.autoSaveAll);
  var refreshNotes = useSelector((state) => state.document_upload.refreshNotes);
  var selectedAppointmentId = useSelector(
    (state) => state.visitSideNavbar.selectedAppointmentId
  );
  var visitSidebar = useSelector((state) => state.visitSideNavbar.sidebar);

  const selectedAppointment = useSelector(
    (state) => state.profileSideNavbar.selectedAppointment
  );
  const refreshNavBarScroll = useSelector(
    (state) => state.visitSideNavbar.refreshNavBarScroll
  );
  const refreshHistoryNavBarScroll = useSelector(
    (state) => state.visitSideNavbar.refreshHistoryNavBarScroll
  );

  const pastSelected = useSelector(
    (state) => state.visitSideNavbar.pastSelected
  );
  var refreshPrescription = useSelector(
    (state) => state.document_upload.refreshPrescription
  );
  const isdoctorNotes = useSelector(
    (state) => state.visitSideNavbar.isdoctorNotes
  );
  const isprescriptions = useSelector(
    (state) => state.visitSideNavbar.isprescriptions
  );
  const isuploadedData = useSelector(
    (state) => state.visitSideNavbar.isuploadedData
  );
  const islabNotes = useSelector((state) => state.visitSideNavbar.islabNotes);
  const isradiologyNotes = useSelector(
    (state) => state.visitSideNavbar.isradiologyNotes
  );
  var patientProfileMenuSelector = useSelector(
    (state) => state.profileSideNavbar.patientProfileMenuSelector
  );
  var autoSaveAll = useSelector((state) => state.profileSideNavbar.autoSaveAll);
  const [loadingDeleteImage, setLoadingDeleteImage] = useState(false);
  const [filePrescription, setFilePrescription] = useState(null);
  const textareaRef = useRef(null);
  const [scrollTrigger, setScrollTrigger] = useState(0);

  const [patient, setPatient] = useState({});
  const [doctors, setDoctors] = useState({});
  const [notesFileDeleteCnfBox, setNotesFileDeleteCnfBox] = useState(false);
  const [appointment, setAppointment] = useState({});
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [editNotes, setEditNotes] = useState(false);
  const [editNotesLab, setEditNotesLab] = useState(false);
  const [editNotesRad, setEditNotesRad] = useState(false);
  const [appointmentId, setAppointmentId] = useState(appointment_id);
  const [editPrescription, setEditPrescription] = useState(false);
  const [rows, setRows] = useState([]);
  const [drNotes, setDrNotes] = useState("");
  const [drLabNotes, setDrLabNotes] = useState("");
  const [drRadNotes, setDrRadNotes] = useState("");
  const [typeScript, setTypeScript] = useState(false);
  const [checkNewPrescription, setCheckNewPrescription] = useState({});
  const [loadingSave, setLoadingSave] = useState(false);
  const [originalFiles, setOriginalFiles] = useState([]);
  const [refreshImage, setRefreshImage] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(false);
  const [medicineOptions, setMedicineOptions] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [selectedInput, setSelectedInput] = useState(null);
  const [doctorId, setDoctorId] = useState(doctor_id);
  const [notes, setNotes] = useState("");
  const [notesFileDeleteID, setNotesFileDeleteID] = useState(0);
  const [isPrescriptionOpen, setIsPrescriptionOpen] = useState(false);
  const [isNewPrescriptionOpen, setIsNewPrescriptionOpen] = useState(false);
  const [nextReview, setNextReview] = useState(null);
  const [loadingSavePrint, setLoadingSavePrint] = useState(false);
  const [medicineDetails, setMedicineDetails] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [printPreview, setPrintPreview] = useState(false);
  const [isAnyChanges, setIsAnyChanges] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [deleteFile, setDeleteFile] = useState([]);
  const [enterPrescriptionExist, setEnterPrescriptionExist] = useState(false);
  const [confirmBoxDeleteAll, setConfirmBoxDeleteAll] = useState(false);
  const sectionRefs = useRef([]);
  const [medicineSelectedValues, setMedicineSelectedValues] = useState({});
  const [loadInitialMedidcineStatus, setLoadInitialMedidcineStatus] = useState({
    medicine: false,
    existingRows: false,
  });
  const [highlightVitals, setHighlightVitals] = useState(false);
  const [pastHistory, setPastHistory] = useState([]);
  const [allPastData, setAllPastData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const pastSelectedRef = useRef(null);
  const historyRefs = useRef([]);
  const pastHistoryRefs = useRef([]);
  const historySectionRefs = useRef({});
  const subSectionRefs = useRef([]);
  const subPastHistorySectionRefs = useRef([]);
  const [history, setHistory] = useState({ prescriptions: [] });
  const [isDoctorContextLoaded, setIsDoctorContextLoaded] = useState(false);
  const [refsReady, setRefsReady] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [newMedicine, setNewMedicine] = useState({
    medicine: "",
    medicine_type: "Tablet",
    when_medidcine: false,
    morning: false,
    noon: false,
    night: false,
    duration: "",
  });
  const [vitalData, setVitalData] = useState({
    weight: "",
    height: "",
    body_temperature: "",
    blood_pressure: "",
    pulse_rate: "",
    respiration_rate: "",
    updateStatusRequired: true,
  });
  const [audioFiles, setAudioFiles] = useState([]);
  const [clinicalDocumentation, setClinicalDocumentation] = useState(true);
  const [audioConfirmBox, setAudioConfirmBox] = useState(false);
  const [sortOrder, setSortOrder] = useState('desc');
  const [clinicalnotesSection, setClinicalnotesSection] = useState(false)
  const [clinicalNotes, setClinicalNotes] = useState({})
  const [clinicalNotesData, setClinicalNotesData] = useState([])
  const [openMenu, setOpenMenu] = useState(false);
  const [audioFilesView, setAudioFilesView] = useState(false);
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const [allClinicalData, setAllClinicalData] = useState([]);
  const [currentPlaying, setCurrentPlaying] = useState(null);
  const dispatch = useDispatch();
  const scrollTopositionHistorySection = (
    tab,
    appointment_id,
    yOffset = -100
  ) => {
    const section = historySectionRefs.current[tab];

    // Ensure the appointment ID matches before scrolling
    if (
      section &&
      section.getAttribute("data-appointment-id") === appointment_id.toString()
    ) {
      const y = section?.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (clinicalnotesSection) {
      let data = JSON.parse(
        clinicalNotes.clinicalNotesRes[0].listOfClinicalNote
      ).reduce(
        (acc, item) => {
          acc[item?.Version] = {
            Version:
              item?.Version,
            EditedBy:
              item?.EditedBy,
            Content:
              JSON.parse(item?.Content)
            ,
          };

          return acc;
        },
        {}
      )
      setClinicalNotesData(data.Original.Content.ClinicalDocumentation.Sections)
    }

  }, [clinicalnotesSection])
  const copyToClipboard = () => {
    if (divRef.current) {
      navigator.clipboard.writeText(divRef.current.innerText)
        .then(() => {
          alert('Content copied to clipboard!');
        })
        .catch((err) => {
          dispatch(setNotification({ message: 'Failed to copy', status: 'error', action: true }));
        });
    }
  };

  const handleUpdateAudioRecordOption = async () => {
    let enableAudioRecording = {
      userId: loggeduser?.userId,
      clinicalNoteModelType: "AiScript",
    };
    await store
      .EnableAudioRecording(DataDecode.encryptPayload(enableAudioRecording))
      .then((result) => {
        if (result.status === 200) {
          setAudioConfirmBox(false);
          dispatch(
            addLoginDetails({
              ...loggeduser,
              clinicalNoteModelType: enableAudioRecording.clinicalNoteModelType,
            })
          );
          dispatch(
            setNotification({
              message: "Enabled Clinical DOcuments",
              status: "success",
              action: true,
            })
          );
          dispatch(setShowRecoringPopup(true));
        }
        if (result.status === 400) {
          dispatch(
            setNotification({
              message: "Error while enabling the  Standard_Ai feature",
              status: "error",
              action: true,
            })
          );
        } else if (result.status !== 200) {
          dispatch(
            setNotification({
              message: "Error while enabling the ClinicalDocumentation",
              status: "error",
              action: true,
            })
          );
        }
      });
  };
  const handleDownloadPDF = async () => {
    const content = document.getElementById("clinical-notes-contentMobile");

    html2canvas(content, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = pdfWidth;
      const imgHeight = (canvas.height * pdfWidth) / canvas.width;

      let heightLeft = imgHeight;
      let position = 0;

      // Add the first page
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      // Add additional pages if content extends beyond one page
      while (heightLeft > 0) {
        position -= pageHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("clinical_notes.pdf");
    });
  };

  const handleFetchTranscriptionFiles = async () => {
    let data = { PatientId: patient_id, Id: appointment_id };
    await store
      .GetTranscriptionFiles(DataDecode.encryptPayload(data))
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            let audioData = res;
            setAudioFiles(audioData);
          }, {});
        } else {
          dispatch(
            setNotification({
              message: "Error while fetching Audio Files",
              status: "error",
              action: true,
            })
          );
          setAudioFiles([]);
        }
      })
      .catch((err) => {
        setDataLoading(false);
        setAllClinicalData([]);
        dispatch(
          setNotification({
            message: "Error occurred",
            status: "error",
            action: true,
          })
        );
      });
  };
  useEffect(() => {
    
    if(appointment_id !== null){
      handleFetchTranscriptionFiles()
  
    }

  }, [appointment_id,patient_id]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (page_src === "appointment" || isDoctorNotesDataAvailable) {
  //       const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  //       await delay(1000);
  //       await handleFetchTranscriptionFiles().then((result) => { });
  //       await delay(1000);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const hanldeEnableRecordIcon = () => {
    if (loggeduser?.clinicalNoteModelType === "AiScript") {
      dispatch(setShowRecoringPopup(true));
      setRecConfirmBox(true);
      dispatch(setOpenRecordingPopUp(false))
    } else {
      setAudioConfirmBox(true);
    }
  };

  const handleCloseRecording = () => {
    dispatch(setStartRecording(false))
    dispatch(setRecConfirmBox(true))
  }

  const handleRowClick = (note) => {
    // setSelectedNote(note);
    setClinicalnotesSection(!clinicalnotesSection);
    setClinicalNotes(note);
  };
  const handleSortClick = () => {
    const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newOrder);

  };
  useEffect(() => {
    const sortedData = [...audioFiles].sort((a, b) => {
      const dateA = new Date(a.creationTime);
      const dateB = new Date(b.creationTime);

      if (sortOrder === 'asc') {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });
    setAudioFiles(sortedData);
  }, [sortOrder]);
  const toggleMenu = (e) => {
    e.stopPropagation();
    setOpenMenu(prevState => !prevState);
  };
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpenMenuIndex(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (subSectionRefs && subSectionRefs.current) {
        subSectionRefs.current.forEach((section) => {
          const rect = section?.getBoundingClientRect();

          if (rect && rect.top <= 200 && rect.bottom > 100) {

            const selectedMenu = section.getAttribute(
              "data-navbar-section-history"
            );
            const selectedSubMenu = section.getAttribute("data-navbar-subsection")
            dispatch(setActiveHistoryNavbar(selectedMenu));

          }
        });
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Initial check when the component mounts
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {

    const appointment_id = selectedAppointmentId;
    if (visitHistory.historyVitals) {
      scrollTopositionHistorySection(
        `${appointment_id}-history-vitals`,
        appointment_id,
        -90
      );
    } else if (visitHistory.historyDoctorNotes) {
      scrollTopositionHistorySection(
        `${appointment_id}-history-doctor-notes`,
        appointment_id,
        -90
      );
    } else if (visitHistory.historyPrescription) {
      scrollTopositionHistorySection(
        `${appointment_id}-history-prescription`,
        appointment_id,
        -90
      );
    } else if (visitHistory.historyUploadedData) {
      scrollTopositionHistorySection(
        `${appointment_id}-history-uploaded-data`,
        appointment_id,
        -90
      );
    } else if (visitHistory.historyLabNotes) {
      scrollTopositionHistorySection(
        `${appointment_id}-history-lab-notes`,
        appointment_id,
        -90
      );
    } else if (visitHistory.historyRadiology) {
      scrollTopositionHistorySection(
        `${appointment_id}-history-radiology`,
        appointment_id,
        -90
      );
    }

  }, [visitHistory]);
  useEffect(() => {
    const handleScroll = () => {
      sectionRefs.current.forEach((section) => {
        const rect = section?.getBoundingClientRect();
        if (rect && rect.top <= 200 && rect.bottom > 0) {
          const selectedMenu = section.getAttribute("data-navbar-section");
          dispatch(setActiveNavbar(selectedMenu));
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    // Initial check when the component mounts
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (historyRefs && historyRefs.current) {
        for (let section of Object.values(historyRefs.current)) {
          if (section) {
            const rect = section?.getBoundingClientRect();

            // Adjust the condition to trigger when the section's top is within a 100px threshold from the viewport's top
            if (rect && rect.top <= 200 && rect.bottom > 100) {
              const currentActiveAppointmentId = section.getAttribute(
                "data-appointment_id"
              );

              // Update the active section by dispatching the selected appointment
              dispatch(
                setSelectedAppointmentId(parseInt(currentActiveAppointmentId))
              );
            }
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Ensure the right section is active when the page loads
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [historyRefs, dispatch]);
  useEffect(() => {
    const handleScroll = () => {
      if (historyRefs && historyRefs.current) {
        for (let section of historyRefs.current) {
          if (section) {
            const rect = section?.getBoundingClientRect();

            if (rect && rect.top <= 200 && rect.bottom > 100) {
              const currentActiveAppointmentId = section.getAttribute(
                "data-appointment_id"
              );

              dispatch(
                setSelectedAppointmentId(parseInt(currentActiveAppointmentId))
              );
            }
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Call once to ensure the right section is active when the page loads
    //  handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [historyRefs]);
  const scrollTopositionHistory = (id, yOffset = -100) => {
    const y =
      historyRefs.current[id]?.getBoundingClientRect().top +
      window.scrollY +
      yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };
  const scrollToPosition = (index, yOffset = -100) => {
    if (historyRefs.current[index]) {
      const elementTop =
        historyRefs.current[index]?.getBoundingClientRect().top + window.scrollY;
      const scrollPosition = elementTop - yOffset;
      window.scrollTo({ top: scrollPosition, behavior: "smooth" });
    }
  };
  useEffect(() => {
    if (selectedAppointmentId > 0 && historyRefs) {
      scrollTopositionHistory(selectedAppointmentId, -90);
    }
  }, [refreshNavBarScroll]);

  useEffect(() => {
    let pf = viewHistoryData?.patientFiles?.filter(
      (a) => a.file_type === "Prescription"
    );
    setFilePrescription(pf?.length > 0 ? pf[0] : null);

    setFiles(
      viewHistoryData?.patientFiles?.filter(
        (a) => a.file_type !== "Prescription"
      )
    );
  }, [viewHistoryData]);

  const handlePrintMobile = () => {
    const mobileContent = document.getElementById("mobile-content-to-print");
    window.print(mobileContent);
  };

  const handlePrintLaptop = () => {
    const laptopContent = document.getElementById("laptop-content-to-print");
    window.print(laptopContent);
  };

  const handleEditClick = () => {
    setEditNotes(!editNotes);
    adjustTextareaHeight();
  };
  const handleEditClickLabNotes = () => {
    setEditNotesLab(!editNotesLab);
    adjustTextareaHeight();
  };
  const handleEditClickRadiology = () => {
    setEditNotesRad(!editNotesRad);
    adjustTextareaHeight();
  };

  const handleDoneEditing = () => {
    setEditNotes(false);
  };
  const handleLabDoneEditing = () => {
    setEditNotesLab(false);
  };
  const handleRadDoneEditing = () => {
    setEditNotesRad(false);
  };
  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };
  const openPopup = () => {
    addRow();
    setIsPrescriptionOpen(true);
    setTypeScript(true);
  };
  const closePopup = () => {
    addRow();
    setIsPrescriptionOpen(false);
  };
  useEffect(() => {
    adjustTextareaHeight();
  }, [drNotes, drLabNotes, drRadNotes]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = Math.min(textarea.scrollHeight, 500) + "px";
    }
  };

  useEffect(() => {
    if (
      appointment_id == 0 &&
      appointmentData?.appointment_id &&
      appointmentData.appointment_id > 0
    ) {
      setAppointmentId(appointmentData.appointment_id);
      setAppointment(appointmentData);
      let data = btoa(
        JSON.stringify({
          appointment_id: appointmentData.appointment_id,
          patient_id: patient_id,
          doctor_id: appointmentData.doctor_id || 0,
          clinic_guid: loggeduser.facilityID,
          page_src: "patient",
        })
      );
      window.history.pushState(null, null, `/patient-profile?profile=${data}`);
    } else {
      setAppointmentId(appointment_id);
    }
  }, [appointment_id, appointmentData]);

  useEffect(() => {
    if (
      doctor_id == 0 &&
      appointmentData?.doctor_id &&
      appointmentData.doctor_id > 0
    ) {
      setDoctorId(appointmentData.doctor_id);
    } else {
      setDoctorId(doctor_id);
    }
  }, [doctor_id, appointmentData]);

  const GetPastHistory = () => {
    if (pastHistory.length === 0) {
      setDataLoading(true);
    }
    store
      .GetPastHistory(
        loggedusertoken,
        DataDecode.encryptPayload({
          clinic_guid: loggeduser.facilityID,
          patient_id: patient_id,
        })
      )
      .then((result) => {
        setDataLoading(false);
        if (result.status === 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            const data = JSON.parse(response);
            setPastHistory(data);
            dispatch(setHistoryVisitData(data));
          });
        } else {
          setPastHistory([]);
        }
      })
      .catch((err) => {
        setDataLoading(false);

        setPastHistory([]);
        dispatch(
          setNotification({
            message: "Error occurred",
            status: "error",
            action: true,
          })
        );
      });
  };

  useEffect(() => {
    GetPastHistory();
  }, []);

  const deleteNotesFile = () => {
    setLoadingDeleteImage(true);
    store
      .DeletePatientFile(
        loggedusertoken,
        DataDecode.encryptPayload({
          clinic_guid: loggeduser.facilityID,
          patient_id: patient_id,
          files: notesFileDeleteID,
        })
      )
      .then((result) => {
        setLoadingDeleteImage(false);
        setNotesFileDeleteCnfBox(false);
        if (result.status == 204) {
          store.CreateActivityLogs(loggeduser, `${patientProfileMenuSelector === "appointment" ? "Appointment to " : "Patient to " }Patient Profile`, `Doctor Notes File Deleted - ${(appointment?.doctor_name ? "for " + appointment?.doctor_name?.trim():"")} with patient ${patient.FirstName} ${patient.MiddleName?.charAt(0)} ${patient.LastName}(${patient?.patient_op_number}) on ${DateFunction.GetDate(appointment?.appointment_time)} ${(appointment?.app_time ? " at " + DateFunction.Convert12Hour(appointment?.app_time) : "")}`,"Delete");
          dispatch(setRefreshNotes(!refreshNotes));
          dispatch(
            setNotification({
              message: "Deleted Successfully!",
              status: "success",
              action: true,
            })
          );
        } else {
          dispatch(
            setNotification({
              message: "Error Occurred!",
              status: "error",
              action: true,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotification({
            message: "Error occurred",
            status: "error",
            action: true,
          })
        );
      });
  };

  const GetAppointmnet = () => {
    store
      .GetAppointmnet(
        loggedusertoken,
        DataDecode.encryptPayload({
          clinic_guid: loggeduser.facilityID,
          appointment_id: appointment_id,
        })
      )
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            setAppointment(JSON.parse(response));
          });
        } else {
          // setAppointment({})
        }
      })
      .catch((err) => {
        dispatch(
          setNotification({
            message: "Error Occurred!",
            status: "error",
            action: true,
          })
        );
      });
  };
  const GetPatient = () => {
    store
      .GetPatient(
        loggedusertoken,
        DataDecode.encryptPayload({
          clinic_guid: loggeduser.facilityID,
          patient_id: patient_id,
        })
      )
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);

            setPatient(JSON.parse(response));
          });
        } else {
          setPatient({});
        }
      })
      .catch((err) => {
        dispatch(
          setNotification({
            message: "Error Occurred!",
            status: "error",
            action: true,
          })
        );
      });
  };
  const GetPatientNotesFile = () => {
    store
      .GetPatientFiles(
        loggedusertoken,
        DataDecode.encryptPayload({
          clinic_guid: loggeduser.facilityID,
          patient_id: patient_id,
          appointment_id: appointment_id,
        })
      )
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            setFiles(JSON.parse(response));
          });
        } else {
          setFiles([]);
        }
      })
      .catch((err) => {
        setFiles([]);
        dispatch(
          setNotification({
            message: "Error occurred",
            status: "error",
            action: true,
          })
        );
      });
  };
  useEffect(() => {
    GetPatientNotesFile();
  }, [refreshUploadedFiles, refreshNotes]);

  useEffect(() => {
    GetPatient(loggeduser.facilityID);
  }, [loggeduser]);

  useEffect(() => {
    if (loggeduser.facilityID && appointment_id) {
      GetAppointmnet();
    }
  }, [loggeduser, refreshData, appointment_id]);
  const saveNotes = () => {
    if (drNotes.trim() === "") {
      dispatch(
        setNotification({
          message: "Please enter Notes!",
          status: "error",
          action: true,
        })
      );
    } else {
      setLoading(true);
      store
        .updateDoctorNotes(
          loggedusertoken,
          DataDecode.encryptPayload({
            appointment_id: appointment_id,
            clinic_guid: loggeduser.facilityID,
            notes: drNotes,
          })
        )
        .then((result) => {
          setLoading(false);
          setIsAnyChanges(false);
          if (result.status == 204) {
            store.CreateActivityLogs(loggeduser, `${patientProfileMenuSelector === "appointment" ? "Appointment to " : "Patient to " }Patient Profile`, `Doctor Notes Updated - ${(appointment?.doctor_name ? "for " + appointment?.doctor_name?.trim():"")} with patient ${patient.FirstName} ${patient.MiddleName?.charAt(0)} ${patient.LastName}(${patient?.patient_op_number}) on ${DateFunction.GetDate(appointment?.appointment_time)} ${(appointment?.app_time ? " at " + DateFunction.Convert12Hour(appointment?.app_time) : "")}`,"Update");
            dispatch(
              setNotification({
                message: "Notes Updated!",
                status: "success",
                action: true,
              })
            );
            setDrNotes(drNotes);
            handleDoneEditing();
          } else {
            dispatch(
              setNotification({
                message: "Error Occured!",
                status: "error",
                action: true,
              })
            );
          }
        })
        .catch((err) => {
          dispatch(
            setNotification({
              message: "Error Occurred!",
              status: "error",
              action: true,
            })
          );
        });
    }
  };
  useEffect(() => {
    if (isAnyChanges && drNotes.trim() !== "") {
      saveNotes();
    }
  }, [autoSaveAll, drNotes]);
  const saveNotesLab = () => {
    if (drLabNotes.trim() === "") {
      dispatch(
        setNotification({
          message: "Please enter Notes!",
          status: "error",
          action: true,
        })
      );
    } else {
      setLoading(true);
      store
        .updateDoctorNotes(
          loggedusertoken,
          DataDecode.encryptPayload({
            appointment_id: appointment_id,
            clinic_guid: loggeduser.facilityID,
            notes: drLabNotes,
            type: "lab",
          })
        )
        .then((result) => {
          setLoading(false);
          setIsAnyChanges(false);
          if (result.status == 204) {
            store.CreateActivityLogs(loggeduser, `${patientProfileMenuSelector === "appointment" ? "Appointment to " : "Patient to " }Patient Profile`, `Lab Notes Updated - ${(appointment?.doctor_name ? "for " + appointment?.doctor_name?.trim():"")} with patient ${patient.FirstName} ${patient.MiddleName?.charAt(0)} ${patient.LastName}(${patient?.patient_op_number}) on ${DateFunction.GetDate(appointment?.appointment_time)} ${(appointment?.app_time ? " at " + DateFunction.Convert12Hour(appointment?.app_time) : "")}`,"Update");
            dispatch(
              setNotification({
                message: "Notes Updated!",
                status: "success",
                action: true,
              })
            );
            setDrLabNotes(drLabNotes);
            handleLabDoneEditing();
          } else {
            dispatch(
              setNotification({
                message: "Error Occured!",
                status: "error",
                action: true,
              })
            );
          }
        })
        .catch((err) => {
          dispatch(
            setNotification({
              message: "Error occurred",
              status: "error",
              action: true,
            })
          );
        });
    }
  };
  useEffect(() => {
    if (isAnyChanges && drLabNotes.trim() !== "") {
      saveNotesLab();
    }
  }, [autoSaveAll, drLabNotes]);
  const saveNotesRad = () => {
    if (drRadNotes.trim() === "") {
      dispatch(
        setNotification({
          message: "Please enter Notes!",
          status: "error",
          action: true,
        })
      );
    } else {
      setLoading(true);
      store
        .updateDoctorNotes(
          loggedusertoken,
          DataDecode.encryptPayload({
            appointment_id: appointment_id,
            clinic_guid: loggeduser.facilityID,
            notes: drRadNotes,
            type: "radiology",
          })
        )
        .then((result) => {
          setLoading(false);
          setIsAnyChanges(false);
          if (result.status == 204) {
            store.CreateActivityLogs(loggeduser, `${patientProfileMenuSelector === "appointment" ? "Appointment to " : "Patient to " }Patient Profile`, `Radiology Notes Updated - ${(appointment?.doctor_name ? "for " + appointment?.doctor_name?.trim():"")} with patient ${patient.FirstName} ${patient.MiddleName?.charAt(0)} ${patient.LastName}(${patient?.patient_op_number}) on ${DateFunction.GetDate(appointment?.appointment_time)} ${(appointment?.app_time ? " at " + DateFunction.Convert12Hour(appointment?.app_time) : "")}`,"Update");
            dispatch(
              setNotification({
                message: "Notes Updated!",
                status: "success",
                action: true,
              })
            );
            setDrRadNotes(drRadNotes);
            handleRadDoneEditing();
          } else {
            dispatch(
              setNotification({
                message: "Error Occured!",
                status: "error",
                action: true,
              })
            );
          }
        })
        .catch((err) => {
          dispatch(
            setNotification({
              message: "Error occurred",
              status: "error",
              action: true,
            })
          );
        });
    }
  };
  useEffect(() => {
    if (isAnyChanges && drRadNotes.trim() !== "") {
      saveNotesRad();
    }
  }, [autoSaveAll, drRadNotes]);
  useEffect(() => {
    setDrNotes(appointment && appointment?.notes ? appointment?.notes : "");
  }, [appointment]);

  const GetPatientFiles = () => {
    store
      .GetPatientFiles(
        loggedusertoken,
        DataDecode.encryptPayload({
          clinic_guid: loggeduser.facilityID,
          patient_id: patient_id,
          appointment_id: appointment_id,
        })
      )
      .then((result) => {
        if (result.status === 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            let data = JSON.parse(response);
            setFiles(data);
            setOriginalFiles(data);
          });
        } else {
          setFiles([]);
          setOriginalFiles([]);
        }
      })
      .catch((err) => {
        setFiles([]);
        setOriginalFiles([]);
        dispatch(
          setNotification({
            message: "Error Occurred",
            status: "error",
            action: true,
          })
        );
      });
  };
  useEffect(() => {
    if (loggeduser.facilityID && appointment_id) {
      GetPatientFiles(loggeduser.facilityID);
    }
  }, [
    loggeduser,
    appointment_id,
    refreshImage,
    refreshUploadedFiles,
    viewHistoryData,
    refreshPrescription,
  ]);

  const GetVitals = () => {
    store
      .GetPatientVitals(
        loggedusertoken,
        DataDecode.encryptPayload({
          clinic_guid: loggeduser.facilityID,
          patient_id: patient_id,
          appointment_id: appointment_id,
        })
      )
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            let vitals = JSON.parse(response);
            setVitalData({ ...vitals, updateStatusRequired: true });
          });
        } else {
          setVitalData({
            weight: "",
            height: "",
            body_temperature: "",
            blood_pressure: "",
            pulse_rate: "",
            respiration_rate: "",
            clinic_guid: loggeduser.facilityID,
            appointment_id: appointmentId,
            patient_id: patient_id,
            updateStatusRequired: true,
          });
        }
      })
      .catch((err) => {
        dispatch(
          setNotification({
            message: "Error Occurred",
            status: "error",
            action: true,
          })
        );
      });
  };
  useEffect(() => {
    if (loggeduser.facilityID && appointment_id) {
      GetVitals(loggeduser.facilityID);
      setVitalData({
        ...vitalData,
        clinic_guid: loggeduser.facilityID,
        appointment_id: appointment_id,
        patient_id: patient_id,
        updateStatusRequired: true,
      });
    }
  }, [loggeduser, appointment_id]);

  const handleUploadButton = (type, appointment_id) => {
    setAppointmentId(appointment_id);
    dispatch(setNewAppointmentId(appointment_id));
    dispatch(setShowDocumentUploadPopup(true));
    dispatch(setIsCameraUpload(type === "capture" ? true : false));
  };
  const handleUploadButtonMob = (type, appointment_id) => {
    setIsMobile(true);
    setAppointmentId(appointment_id);
    dispatch(setNewAppointmentId(appointment_id));
    dispatch(setShowDocumentUploadPopup(true))
    dispatch(setIsCameraUpload(type === "capture" ? true : false));
  }
  useEffect(() => {
    if (isPopupOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isPopupOpen]);
  useEffect(() => {
    if (showDocumentUploadPopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showDocumentUploadPopup]);

  useEffect(() => {
    setDrRadNotes(
      appointment && appointment?.radiology_notes
        ? appointment?.radiology_notes
        : ""
    );
  }, [appointment]);

  useEffect(() => {
    setDrLabNotes(
      appointment && appointment?.lab_notes ? appointment?.lab_notes : ""
    );
  }, [appointment]);

  const deletePrescriptionFile = () => {
    setLoadingDeleteImage(true);
    store
      .DeletePatientFile(
        loggedusertoken,
        DataDecode.encryptPayload({
          clinic_guid: loggeduser.facilityID,
          patient_id: patient_id,
          files: deleteFile,
        })
      )
      .then((result) => {
        setLoadingDeleteImage(false);
        setConfirmBox(false);
        if (result.status == 204) {
          setRefreshImage((o) => !o);
          dispatch(
            setNotification({
              message: "Deleted Successfully!",
              status: "success",
              action: true,
            })
          );
        } else {
          dispatch(
            setNotification({
              message: "Error Occurred!",
              status: "error",
              action: true,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotification({
            message: "Error Occurred!",
            status: "error",
            action: true,
          })
        );
      });
  };
  const addRowMedicine = () => {
    if (
      newMedicine.medicine.trim() === "" ||
      newMedicine.duration.trim() === ""
    ) {
      dispatch(
        setNotification({
          message: "* Fields are required!",
          status: "error",
          action: true,
        })
      );
      return;
    }
    if (editingIndex !== null) {
      const updatedRows = rows.map((row, index) =>
        index === editingIndex ? { ...newMedicine } : row
      );
      setRows(updatedRows);
      setEditingIndex(null);
    } else {
      const maxValue = Math.max(
        ...rows.filter((f) => f.medicine !== "").map((obj) => obj.order_id)
      );
      const newRow = {
        order_id: maxValue < rows.length + 1 ? rows.length + 1 : maxValue + 1,
        medicine: newMedicine.medicine,
        medicine_type: newMedicine.medicine_type,
        when_medidcine: newMedicine.when_medidcine,
        morning: newMedicine.morning,
        noon: newMedicine.noon,
        evening: newMedicine.evening,
        night: newMedicine.night,
        duration: newMedicine.duration,
        total: newMedicine.total,
        other: newMedicine.other,
      };
      if (rows.length == 1 && rows[0].medicine === "") {
        setRows([newRow]);
      } else {
        setRows([...rows, newRow]);
      }
    }
    setTimeout(() => {
      setNewMedicine({
        order_id: rows.length + 1,
        medicine: "",
        medicine_type: "Tablet",
        when_medidcine: false,
        morning: "",
        noon: "",
        evening: "",
        night: "",
        duration: "",
        total: "N/A",
        other: "",
      });
    }, 100);
  };

  // const addRow = () => {
  //     const newRow = { order_id: rows.length + 1, medicine: '', medicine_type: 'Tablet', when_medidcine: false, morning: '', noon: '', evening: '', night: '', duration: '', total: 'N/A', other: '' };
  //     setRows([...rows, newRow]);
  // };
  const GetPrescription = () => {
    store
      .GetPrescription(
        loggedusertoken,
        DataDecode.encryptPayload({
          appointment_id: appointment_id,
          clinic_guid: loggeduser.facilityID,
        })
      )
      .then((result) => {
        if (result.status === 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            let val = JSON.parse(response);
            setRows(val);
            if (val.length > 0) {
              setLoadInitialMedidcineStatus({
                ...loadInitialMedidcineStatus,
                existingRows: true,
              });
              setTimeout(() => {
                setCheckNewPrescription((prev) => ({
                  ...prev,
                  entry: "exist",
                }));
              }, 100);

              setTypeScript(true);
            } else {
              setTimeout(() => {
                setCheckNewPrescription((prev) => ({
                  ...prev,
                  entry: "na",
                }));
              }, 100);
            }
          });
        } else {
          setCheckNewPrescription((prev) => ({
            ...prev,
            entry: "na",
          }));
          setRows([
            {
              order_id: 1,
              medicine: "",
              medicine_type: "Tablet",
              when_medidcine: false,
              morning: "",
              noon: "",
              evening: "",
              night: "",
              duration: "",
              total: "N/A",
              other: "",
            },
          ]);
        }
      })
      .catch((err) => {
        dispatch(
          setNotification({
            message: "Error Occurred!",
            status: "error",
            action: true,
          })
        );
      });
  };

  const fetchMedicineData = () => {
    try {
      store
        .GetAllMedicine(
          loggedusertoken,
          DataDecode.encryptPayload({ clinic_guid: loggeduser.facilityID })
        )
        .then((response) => {
          if (response.status == 200) {
            response.json().then((res) => {
              const decryptedResponse = DataDecode.decryptResponse(res);
              const data = JSON.parse(decryptedResponse);
              if (data.length > 0) {
                const options = data.map((val) => ({
                  value: val.medicine_id,
                  label: val.medicine_name,
                }));
                setMedicineOptions(options);
                setLoadInitialMedidcineStatus({
                  ...loadInitialMedidcineStatus,
                  medicine: true,
                });
              }
            });
          } else {
            setMedicineOptions([]);
          }
        });
    } catch (err) {
      dispatch(
        setNotification({
          message: "Error Occurred!",
          status: "error",
          action: true,
        })
      );
      setMedicineOptions([]);
    }
  };
  async function validateMedicine(inputValue) {
    const apiUrl =
      process.env.REACT_APP_EMRAXIS_MEDICINEVALIDATION +
      encodeURIComponent(inputValue);
    const response = await fetch(apiUrl);
    if (response.status == 200) {
      return true;
    } else {
      return false;
    }
  }
  useEffect(() => {
    if (loggeduser.facilityID && appointment_id) {
      fetchMedicineData();
    }
  }, [loggeduser.facilityID, appointment_id]);

  useEffect(() => {
    setTimeout(() => {
      if (
        loadInitialMedidcineStatus.existingRows &&
        loadInitialMedidcineStatus.medicine
      ) {
        let updatedMedicine = {};
        rows.map((r) => {
          let seletedMedicine = medicineOptions.filter(
            (a) =>
              a.label?.trim()?.toLowerCase() ===
              r.medicine?.trim()?.toLowerCase()
          );
          if (seletedMedicine.length > 0) {
            updatedMedicine[r.order_id] = seletedMedicine[0];
          }
        });
        setTimeout(() => {
          setMedicineSelectedValues({
            ...medicineSelectedValues,
            ...updatedMedicine,
          });
        }, 100);
      }
    }, 100);
  }, [loadInitialMedidcineStatus]);

  const savePrescription = (save_type) => {
    let exist = rows.filter(
      (a) => a.medicine.trim() === "" || a.duration == 0 || a.duration == ""
    );
    if (exist.length > 0 && typeScript) {
      dispatch(
        setNotification({
          message: "* Fields are required!",
          status: "error",
          action: true,
        })
      );
    } else if (!file && !enterPrescriptionExist && exist > 0) {
      dispatch(
        setNotification({
          message: "Please add prescription!",
          status: "error",
          action: true,
        })
      );
    } else {
      setSubmitDisable(true);
      if (save_type === "save") {
        setLoadingSave(true);
      } else {
        setLoadingSavePrint(true);
      }
      let payload = {
        appointment_id: appointment.appointment_id,
        clinic_guid: loggeduser.facilityID,
        prescriptions: rows,
        patient_id: patient.patient_id,
        doctor_id: doctor_id,
        type_script: typeScript,
        medicineDetails: medicineDetails,
        inputValue: inputValue,
      };
      store
        .AddPrescription(loggedusertoken, DataDecode.encryptPayload(payload))
        .then((result) => {
          setSubmitDisable(false);
          setIsAnyChanges(false);
          if (result.status == 201) {
            if (save_type === "save") {
              setLoadingSave(false);
            } else {
              setPrintPreview(true);
              setLoadingSavePrint(false);
            }
            store.CreateActivityLogs(loggeduser,`${patientProfileMenuSelector === "appointment" ? "Appointment to " : "Patient to " }Patient Profile`, `Prescription Updated - ${(appointment?.doctor_name ? "for " + appointment?.doctor_name?.trim():"")} with patient ${patient.FirstName} ${patient.MiddleName?.charAt(0)} ${patient.LastName}(${patient?.patient_op_number}) on ${DateFunction.GetDate(appointment?.appointment_time)} ${(appointment?.app_time ? " at " + DateFunction.Convert12Hour(appointment?.app_time) : "")}`,"Update");
            dispatch(
              setNotification({
                message: "Prescription Added",
                status: "success",
                action: true,
              })
            );
          } else {
            setLoadingSave(false);
            setLoadingSavePrint(false);
            dispatch(
              setNotification({
                message: "Error Occured!",
                status: "error",
                action: true,
              })
            );
          }
        })
        .catch((err) => {
          dispatch(
            setNotification({
              message: "Error Occurred!",
              status: "error",
              action: true,
            })
          );
        });
    }
  };
  const GetPastPrescription = () => {
    store
      .GetPastPrescription(
        loggedusertoken,
        DataDecode.encryptPayload({
          clinic_guid: loggeduser.facilityID,
          patient_id: patient_id,
          appointment_id: appointment.appointment_id,
        })
      )
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            if (response === "file") {
              setTimeout(() => {
                GetPatientFiles();
              }, 500);
            } else if (response === "entry") {
              setTimeout(() => {
                GetPrescription();
              }, 500);
            }
          });
        }
      })
      .catch((err) => {
        dispatch(
          setNotification({
            message: "Error Occurred!",
            status: "error",
            action: true,
          })
        );
      });
  };
  const GetCurrentMedicines = () => {
    store
      .GetCurrentMedicines(
        loggedusertoken,
        DataDecode.encryptPayload({
          clinic_guid: loggeduser.facilityID,
          patient_id: patient_id,
        })
      )
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            setRows(JSON.parse(response));
          });
        } else {
          setRows([]);
        }
      })
      .catch((err) => {
        dispatch(
          setNotification({
            message: "Error Occurred!",
            status: "error",
            action: true,
          })
        );
      });
  };
  useEffect(() => {
    GetCurrentMedicines();
  }, [patient_id, loggeduser.facilityID]);

  useEffect(() => {
    if (
      isuploadedData &&
      isdoctorNotes &&
      isprescriptions &&
      islabNotes &&
      isradiologyNotes &&
      appointmentId &&
      pastHistory.length > 0
    ) {
      const index = pastHistory.findIndex(
        (history) => history.appointment_id === appointmentId
      );
      if (index !== -1) {
        scrollToPosition(index, 80);
        dispatch(setSelectedAppointmentId(appointmentId));
        dispatch(setpastSelected(appointmentId));
      }
    }
  }, [
    isuploadedData,
    isdoctorNotes,
    isprescriptions,
    islabNotes,
    isradiologyNotes,
    appointmentId,
    pastHistory,
  ]);

  const addRow = () => {
    setNewMedicine({
      order_id: 1,
      medicine: "",
      medicine_type: "Tablet",
      when_medidcine: false,
      morning: "",
      noon: "",
      evening: "",
      night: "",
      duration: "",
      total: "N/A",
      other: "",
    });
  };

  const handleChangeMedicine = (id, event, name = "medicine") => {
    if (event && event.target) {
      const { value, checked, type } = event.target;
      if (type === "checkbox") {
        setNewMedicine((prev) => ({
          ...prev,
          [name]: name === "when_medidcine" ? checked : checked ? "1" : "0",
        }));
      } else if (name === "duration") {
        const numericValue = value.replace(/[^0-9]/g, "");
        setNewMedicine((prev) => ({
          ...prev,
          [name]: numericValue,
        }));
      } else {
        setNewMedicine((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else if (event.label || event) {
      const value = event.label || event;
      setNewMedicine((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    if (name === "medicine") {
      setMedicineSelectedValues({
        ...medicineSelectedValues,
        [id]: event,
      });
    }
  };

  const getAvailableOptions = () => {
    const selectedOptions = Object.values(medicineSelectedValues).map(
      (option) => option?.value
    );
    return medicineOptions.filter(
      (option) => !selectedOptions.includes(option.value)
    );
  };
  useEffect(() => {
    if (loggeduser.facilityID && appointment_id) {
      GetPastPrescription();
    }
  }, [loggeduser.facilityID, appointment_id]);

  const handleCreateChangeMedicine = async (id, inputValue) => {
    try {
      const isValidMedicine = await validateMedicine(inputValue);
      if (isValidMedicine) {
        const updatedRows = rows.map((row) =>
          row.order_id === id ? { ...row, medicine: inputValue } : row
        );
        setRows(updatedRows);
      } else {
        dispatch(
          setNotification({
            message: "Please enter valid medicine name!",
            status: "error",
            action: true,
          })
        );
      }
    } catch (error) {
      dispatch(
        setNotification({
          message: "Error occurred",
          status: "error",
          action: true,
        })
      );
    }
  };
  const handleEditPreClick = (rows, index) => {
    setNewMedicine(rows);
    setEditingIndex(index);
    setIsPrescriptionOpen(true);
  };

  useEffect(() => {
    if (loggeduser.facilityID && appointment_id) {
      GetPrescription();
    }
  }, [viewHistoryData, appointment_id, loggeduser.facilityID]);

  const deleteRow = (id) => {
    const updatedRows = rows.filter((row) => row.order_id !== id);
    if (updatedRows.length > 0) {
      setRows(updatedRows);
      setIsAnyChanges(true);

      setMedicineSelectedValues((prevValues) => {
        const newValues = { ...prevValues };
        delete newValues[id];
        return newValues;
      });
    } else {
      dispatch(
        setNotification({
          message: "Minimum one row required!",
          status: "error",
          action: true,
        })
      );
    }
  };
  const deleteEnteredPrescriptions = () => {
    setLoadingDeleteImage(true);
    store
      .DeleteEnteredPrescriptions(
        loggedusertoken,
        DataDecode.encryptPayload({
          clinic_guid: loggeduser.facilityID,
          patient_id: patient.patient_id,
          appointment_id: appointment.appointment_id,
        })
      )
      .then((result) => {
        setLoadingDeleteImage(false);
        setConfirmBoxDeleteAll(false);
        if (result.status == 204) {
          GetPrescription();
          setEnterPrescriptionExist(false);
          dispatch(
            setNotification({
              message: "Deleted Successfully!",
              status: "success",
              action: true,
            })
          );
        } else {
          dispatch(
            setNotification({
              message: "Error Occurred!",
              status: "error",
              action: true,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(setNotification({ message: 'Error occurred while saving patient details', status: 'error', action: true }));
      });
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 767px)');
    setIsMobile(mediaQuery.matches);
    const handleMediaChange = (e) => {
      setIsMobile(e.matches);
      if (e.matches) {
        window.location.href = `/history?${queryParams.toString()}`;
      } else {
        window.location.href = `/patient-profile?profile=${queryParams.get('history')}`;
      }
    };
    mediaQuery.addListener(handleMediaChange);
    return () => {
      mediaQuery.removeListener(handleMediaChange);
    };
  }, []);
  return (
    <>
      {printPreview && (
        <PrescriptionPrintPreview
          setPrintPreview={setPrintPreview}
          patient={patient}
          medicines={rows}
          nextReview={nextReview}
          appointment={appointment}
          file={file}
        />
      )}
      {notesFileDeleteCnfBox && (
        <div
          id="delete"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed left-1/2 top-1/2 w-full max-w-sm transform  -translate-x-1/2 -translate-y-1/2 sm:w-80 sm:left-[40%] z-[999999] sm:top-[3rem] sm:translate-x-[50%] sm:translate-y-[50%] "
        >
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
              <svg
                id="btnDeletePastFile"
                className="text-red-600 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <p
                id="PastDeleteFile"
                className="mb-4 text-gray-500 dark:text-gray-300"
              >
                Are you sure you want to delete this file?
              </p>
              <div className=" flex justify-center items-center space-x-4">
                <button
                  id="btnCancelCfmdDeleteFile"
                  onClick={() => setNotesFileDeleteCnfBox(false)}
                  disabled={loadingDeleteImage}
                  type="button"
                  className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                >
                  No, cancel
                </button>
                <button
                  id="btnYesCfmdDeleteFile"
                  type="button"
                  onClick={deleteNotesFile}
                  disabled={loadingDeleteImage}
                  className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Yes, I'm sure{" "}
                  {loadingDeleteImage && (
                    <svg
                      id="SvgYesDeleteNotesFile"
                      aria-hidden="true"
                      role="status"
                      className="inline w-4 h-4 ml-2 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {confirmBox && (
        <div
          id="delete"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed z-10 lg:left-[40%] lg:w-80 top-[10rem] lg:top-[3rem] lg:translate-x-[50%] translate-y-[50%]"
        >
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
              <svg
                id="SvgDeletePrescriptionFile"
                className="text-red-600 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <p
                id="DeletePrescriptionFile"
                className="mb-4 text-gray-500 dark:text-gray-300"
              >
                Are you sure you want to delete this file?
              </p>
              <div className="flex justify-center items-center space-x-4">
                <button
                  id="btnCancelCfmdDeleteFile"
                  onClick={() => setConfirmBox(false)}
                  disabled={loadingDeleteImage}
                  type="button"
                  className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                >
                  No, cancel
                </button>
                <button
                  id="btnYesCfmdDeleteFile"
                  type="button"
                  onClick={deletePrescriptionFile}
                  disabled={loadingDeleteImage}
                  className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Yes, I'm sure{" "}
                  {loadingDeleteImage && (
                    <svg
                      id="YesDeletePrescriptionFile"
                      aria-hidden="true"
                      role="status"
                      className="inline w-4 h-4 ml-2 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {confirmBoxDeleteAll && (
        <div
          id="delete"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed z-10 lg:left-[40%] lg:w-80 top-[10rem] lg:top-[3rem] lg:translate-x-[50%] translate-y-[50%]"
        >
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
              <svg
                id="SvgDeleteEnteredPrescriptions"
                className="text-red-600 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <p
                id="DeleteAllPrescription"
                className="mb-4 text-gray-500 dark:text-gray-300"
              >
                Are you sure you want to delete all prescriptions?
              </p>
              <div className="flex justify-center items-center space-x-4">
                <button
                  id="BtnDelPrescCancelButton"
                  onClick={() => setConfirmBox(false)}
                  disabled={loadingDeleteImage}
                  type="button"
                  className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                >
                  No, cancel
                </button>
                <button
                  id="BtnDelPrescYesButton"
                  type="button"
                  onClick={deleteEnteredPrescriptions}
                  disabled={loadingDeleteImage}
                  className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Yes, I'm sure{" "}
                  {loadingDeleteImage && (
                    <svg
                      id="SvgYesDeleteEnteredPrescriptions"
                      aria-hidden="true"
                      role="status"
                      className="inline w-4 h-4 ml-2 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* MobileView */}
      <div className="lg:hidden mx-auto px-4  my-20">
        <div className="">
          <div className="origin-top-left flex items-center font-bold text-xl justify-between">
            <div className="flex items-center">
              <svg
                id="MobBack"
                onClick={() => {
                  window.history.back();
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="20"
                viewBox="0 0 8 12"
                fill="none"
              >
                <path
                  d="M7.70492 1.41L6.29492 0L0.294922 6L6.29492 12L7.70492 10.59L3.12492 6L7.70492 1.41Z"
                  className="fill-red-800"
                />
              </svg>

              <header
                className={`text-xl lg:text-2xl sm:text-lg pl-1 ${previewTheme.headingTitleColor}`}
                style={{ fontFamily: previewTheme.fontFamily }}
              >
                Visit History({DateFunction.GetDate(appointment?.appointment_time)})
              </header>
            </div>

            <div className="flex items-center gap-2">
              <svg
                id="SvgUploadPast"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => handleUploadButtonMob("upload", appointment_id)}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="h-5 w-5 text-red-800 cursor-pointer"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                />
              </svg>
              <svg
                id="SvgCapturePast"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => handleUploadButtonMob("capture", appointment_id)}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="h-5 w-5 text-red-700 cursor-pointer"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="" id="mobile-content-to-print">
          <div className="bg-gray-100 font-semibold justify-center  flex items-center ">
            <div
              className={`font-bold text-lg sm:mb-6 text-center py-2 ${previewTheme.headingTitleColor}`}
              style={{ fontFamily: previewTheme.fontFamily }}
            >
              Patient Details
            </div>
          </div>
          <div className="w-full mt-2 p-4  shadow-lg bg-white mb-2">
            <div className="flex justify-between items-center">
              <div className="mt-2 ml-2 flex items-center">
                <span className="text-sm w-[129px]">Name:</span>
                <span className="ml-2">
                  {`${patient.FirstName && (patient.FirstName +
                    " " +
                    patient.MiddleName?.charAt(0) +
                    " " +
                    patient.LastName)
                    }`}{" "}
                </span>
              </div>
            </div>
            <hr className="my-2 border-gray-300" />
            <div className="flex justify-between items-center">
              <div className="mt-2 ml-2 flex items-center">
                <span className="text-sm w-[129px]">Consulted Doctor:</span>
                <span className="ml-2">{appointment?.doctor_name}</span>
              </div>
            </div>
            <hr className="my-2 border-gray-300" />
            <div className="flex justify-between items-center">
              <div className="mt-2 ml-2 flex items-center">
                <span className="text-sm w-[129px]">Referred By:</span>
                <span className="ml-2">{appointment?.reffered_by}</span>
              </div>
            </div>
            <hr className="my-2 border-gray-300" />
            {!showMore && (
              <button
                id="btnDocNotExp"
                onClick={() => setShowMore(!showMore)}
                className="text-blue-500 text-xs float-right"
              >
                View More
              </button>
            )}
            {showMore && (
              <>
                <div className="mt-2 ml-2 flex items-center">
                  <span className="text-sm w-[129px]">Body Temperature:</span>
                  <span className="ml-2">
                    {vitalData?.body_temperature && (
                      <span>{vitalData.body_temperature}° F</span>
                    )}
                  </span>
                </div>
                <hr className="my-2 border-gray-300" />

                <div className="mt-2 ml-2 flex items-center">
                  <span className="text-sm w-[129px]">Height:</span>
                  <span className="ml-2">
                    {vitalData?.height && <span>{vitalData.height} cm</span>}
                  </span>
                </div>
                <hr className="my-2 border-gray-300" />

                <div className="mt-2 ml-2 flex items-center">
                  <span className="text-sm w-[129px]">Weight:</span>
                  <span className="ml-2">
                    {vitalData?.weight && <span>{vitalData.weight} kg</span>}
                  </span>
                </div>
                <hr className="my-2 border-gray-300" />

                <div className="mt-2 ml-2 flex items-center">
                  <span className="text-sm w-[129px]">Blood Pressure:</span>
                  <span className="ml-2">
                    {vitalData?.blood_pressure && (
                      <span>{vitalData.blood_pressure} mmHg</span>
                    )}
                  </span>
                </div>
                <hr className="my-2 border-gray-300" />

                <div className="mt-2 ml-2 flex items-center">
                  <span className="text-sm w-[129px]">Pulse:</span>
                  <span className="ml-2">
                    {vitalData?.pulse_rate && (
                      <span>{vitalData.pulse_rate} bpm</span>
                    )}
                  </span>
                </div>
                <hr className="my-2 border-gray-300" />

                <div className="mt-2 ml-2 flex items-center">
                  <span className="text-sm w-[129px]">Oxygen:</span>
                  <span className="ml-2">
                    {vitalData?.respiration_rate && (
                      <span>{vitalData.respiration_rate} %</span>
                    )}
                  </span>
                </div>
                <hr className="my-2 border-gray-300" />

                <div className="flex justify-end mt-2">
                  <button
                    id="btnShowLess"
                    onClick={() => setShowMore(!showMore)}
                    className="text-blue-500 text-xs"
                  >
                    View Less
                  </button>
                </div>
              </>
            )}
          </div>
          <div className="bg-gray-100 font-semibold justify-center  flex items-center mt-4">
            <div
              className={`font-bold text-lg text-center py-2 ${previewTheme.headingTitleColor}`}
              style={{ fontFamily: previewTheme.fontFamily }}
            >
              Doctor Notes
            </div>
          </div>
          {
            loggeduser?.clinicalNoteModelType === "AiScript" ?
              audioFiles && audioFiles.length > 0 ?
                !clinicalnotesSection ? (<><table className="min-w-full bg-white border rounded-lg shadow-sm">
                  <thead>
                    <tr className="bg-[#f1e8e9] text-white h-[4.65rem] w-[100%]">
                      <th className="py-2 px-4 text-left font-semibold w-[40%] flex text-[#262834]">
                        <span> Visit/Upload Details</span>
                        <button onClick={handleSortClick} className="ml-2">
                          {sortOrder === 'asc' ? (
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-4 text-[#262834]">
                              <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                            </svg>

                          ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-4 text-[#262834]">
                              <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                            </svg>


                          )}
                        </button>
                      </th>
                      <th className="py-2 px-4 text-left font-semibold w-[20%] text-[#262834]">Type</th>
                      <th className="py-2 px-4 text-left font-semibold w-[40%] text-[#262834]">Last Modified by</th>
                    </tr>
                  </thead>

                  <tbody>
                    <>
                      {audioFiles.map((note, index) => (
                        <tr
                          key={index}
                          id="history-clinical-notes"
                          onClick={() => handleRowClick(note)}
                          className="hover:bg-gray-100 border-b last:border-b-0 cursor-pointer w-[100%]"
                        >
                          <td className="py-2 px-4 w-[40%]">
                            <span className="text-black font-normal">
                              {`${new Date(note.creationTime).toLocaleDateString("en-US", {
                                month: "short",
                                day: "2-digit",
                                year: "numeric",
                              })} `}
                            </span>
                            <br />
                            <span className="text-[#8C8C8C] font-normal">
                              {`${new Date(new Date(note.lastModifiedDate).getTime() + (5 * 60 + 30) * 60 * 1000).toLocaleTimeString("en-US", {
                                hour: "2-digit",
                                minute: "2-digit",
                              })} `}
                            </span>
                          </td>
                          <td className="py-2 px-4 w-[20%]">{"AI Scribe"}</td>
                          <td className="py-2 px-4 w-[40%]">
                            {note.lastModifiedBy} <br />
                            <span className="text-black font-normal">
                              {`${new Date(note.lastModifiedDate).toLocaleDateString("en-US", {
                                month: "short",
                                day: "2-digit",
                                year: "numeric",
                              })} `}
                            </span>
                            <span className="text-[#8C8C8C] font-normal">
                              {`${new Date(new Date(note.lastModifiedDate).getTime() + (5 * 60 + 30) * 60 * 1000).toLocaleTimeString("en-US", {
                                hour: "2-digit",
                                minute: "2-digit",
                              })} `}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </></tbody></table>
                  <div className="text-left ml-2">

                  <textarea
                    id="doctorNotes"
                    style={{ resize: "none" }}
                    onChange={(evt) => {
                      setDrNotes(evt.target.value);
                      setIsAnyChanges(true);
                    }}
                    className="w-full mt-2 px-2 rounded-md text-sm border border-gray-300 focus:border-gray-500 text-gray-900 bg-white dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                    value={drNotes}
                    rows={5}
                    placeholder="Doctor Notes"
                  ></textarea>
                </div>
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 ">
              {files
                ?.filter((a) => a.file_type === "Notes")
                ?.map((file, index) => {
                  const fileExtension = file.file_path
                    .split(".")
                    .pop()
                    .toLowerCase();
                  const isImage = [
                    "jpg",
                    "jpeg",
                    "png",
                    "gif",
                    "bmp",
                    "webp",
                  ].includes(fileExtension);
                  const isAudio = ["mp3", "wav", "ogg", "m4a"].includes(
                    fileExtension
                  );
                  const isVideo = ["mp4", "webm", "ogg", "mov"].includes(
                    fileExtension
                  );

                  return (
                    <div
                      className="relative border rounded-sm"
                      key={"image-notes-" + index}
                    >
                      <a
                        rel="noopener"
                        target="_blank"
                        href={
                          process.env.REACT_APP_EMRAXIS_S3_URL +
                          "/" +
                          file.file_path
                        }
                        className=""
                      >
                        {isImage ? (
                          <img
                            id="imgMobDoctor"
                            className="max-h-[180px] w-auto h-auto object-contain p-5"
                            src={
                              process.env.REACT_APP_EMRAXIS_S3_URL +
                              "/" +
                              file.file_path
                            }
                            alt="Prescription"
                          />
                        ) : isAudio ? (
                          <div className="flex justify-center items-center h-full">
                            {" "}
                            <audio className="w-full max-w-[400px]" controls>
                              <source
                                src={
                                  process.env.REACT_APP_EMRAXIS_S3_URL +
                                  "/" +
                                  file.file_path
                                }
                                type={`audio/${fileExtension}`}
                              />
                              Your browser does not support the audio element.
                            </audio>
                          </div>
                        ) : isVideo ? (
                          <video
                            className="max-h-[180px] w-auto h-auto object-contain p-5"
                            controls
                          >
                            <source
                              src={
                                process.env.REACT_APP_EMRAXIS_S3_URL +
                                "/" +
                                file.file_path
                              }
                              type={`video/${fileExtension}`}
                            />
                            Your browser does not support the video element.
                          </video>
                        ) : (
                          <div className="flex justify-center items-center h-full">
                            <FileIcon
                              ext={"." + fileExtension}
                              className="max-h-[180px] w-auto h-auto object-contain p-5  "
                            />
                          </div>
                        )}
                      </a>
                      <button
                        id="btnNotesFileDeleteCnfBox"
                        className="absolute top-2 left-2 bg-gray-100 rounded-full p-1 shadow"
                        onClick={() => {
                          setNotesFileDeleteCnfBox(true);
                          setNotesFileDeleteID([{ ...file, is_active: false }]);
                        }}
                      >
                        <svg
                          id="SvgMobNotesFileDelete"
                          xmlns="http://www.w3.org/2000/svg"
                          className="size-6 text-red-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                          />
                        </svg>
                      </button>
                    </div>
                  );
                })}
            </div>
            </>
                  ) :
                  (clinicalnotesSection && (<>
                    <div className='flex justify-between items-center bg-slate-200 text-[#a31b1b] rounded-t-lg text-lg font-semibold py-2 px-2'>

                      <div className='flex justify-end items-center '><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5  mr-2 cursor-pointer text-[#a31b1b]" onClick={() => { setClinicalnotesSection(false); setClinicalNotesData([]); setClinicalNotes({}) }}>
                        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                      </svg>

                        <div className='flex cursor-pointer' id='RadExpandDrNotes'>Clinical Notes
                        </div></div>
                      <div className='flex justify-end items-center '>
                        {/* <button title="Record" onClick={""}
id="btnStartRecording"
type="button"
className={`${previewTheme.borderTheame} flex border-2 py-1 px-2 mr-2 text-sm font-medium text-gray-500 bg-white rounded-lg  border-gray-200 hover:bg-gray-100   hover:text-gray-900  dark:bg-gray-700 dark:text-gray-300 `}> <div className='font-semibold text-[14px]'>AI Scribe</div>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5  mr-0 cursor-pointer text-[#a31b1b]">
<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
</svg>

</button> */}
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5  mr-2 cursor-pointer text-[#a31b1b]" onClick={() => { copyToClipboard() }} id='copy-history-clinical'>
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
                        </svg>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className={`size-5 mt-0 cursor-pointer text-red-700`}>
<path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
</svg> */}

                        <div className="px-1 py-1 text-center cursor-pointer mt-2">
                          <button id="PatientsBtnmenuHistory" onClick={toggleMenu} >
                            <svg id="PatientsBtnmenuIcon" xmlns="http://www.w3.org/2000/svg" width="6" height="19" viewBox="0 0 6 19" fill="none">
                              <g opacity="0.5">
                                <path d="M3 3.86C3.55228 3.86 4 3.44362 4 2.93C4 2.41638 3.55228 2 3 2C2.44772 2 2 2.41638 2 2.93C2 3.44362 2.44772 3.86 3 3.86Z" stroke="#A31B1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M3 10.68C3.55228 10.68 4 10.2636 4 9.75001C4 9.23638 3.55228 8.82001 3 8.82001C2.44772 8.82001 2 9.23638 2 9.75001C2 10.2636 2.44772 10.68 3 10.68Z" stroke="#A31B1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M3 17.5C3.55228 17.5 4 17.0836 4 16.57C4 16.0564 3.55228 15.64 3 15.64C2.44772 15.64 2 16.0564 2 16.57C2 17.0836 2.44772 17.5 3 17.5Z" stroke="#A31B1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              </g>
                            </svg>
                          </button>
                          {openMenu && (
                            <div id="PatientsDivmobileActionMenu" ref={menuRef} className="absolute mt-[-40px] right-[5.5rem] w-30 bg-white border border-gray-300 rounded-md z-20">
                              <ul>
                                <li
                                  id="PatientsLImobileEditActionHistory"
                                  className="px-3 py-1 hover:bg-gray-100 cursor-pointer flex items-center gap-2 text-gray-800"
                                  onClick={() => { setAudioFilesView(true); setOpenMenu(!openMenu) }}
                                >
                                  Audio Files
                                </li>

                                <li
                                  id="PatientsLImobileDeleteActionHistory"
                                  className="px-3 py-1 hover:bg-gray-100 cursor-pointer flex items-center gap-2 text-gray-800"
                                  onClick={() => { handleDownloadPDF(); setOpenMenu(!openMenu) }}
                                >

                                  Download
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>

                        {/* <svg xmlns="http://www.w3.org/2000/svg" id='MobBtnUploadDrNotesData' onClick={() => handleUploadButton('upload')} fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className={`size-5 mt-0 mr-2 cursor-pointer text-red-700`}>
<path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
</svg> */}
                        {/* <svg xmlns="http://www.w3.org/2000/svg" id='MobBtnCaptureDrNotesData' onClick={() => handleUploadButton('capture')} fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className={`size-5 mt-0 cursor-pointer text-red-700`}>
<path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
<path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
</svg> */}
                      </div>
                    </div>
                    {!audioFilesView ?
                      <div >
                        <div ref={divRef} >
                          {clinicalNotesData.map((clinicalNote, index) => (
                            <div className='pt-[6px]' key={index}>
                              <div className='text-left text-[14px] text-[#151924] font-semibold'>{clinicalNote.SectionName}</div>
                              {clinicalNote.Summary.map((note, index) => (
                                <div className='w-full pr-[6px]' >
                                  <div key={index} className='w-full pr-[6px]'>{index + 1 + '. '}{note.SummarizedSegment}</div>
                                </div>
                              ))}
                            </div>
                          ))}</div>

                        <div className='absolute top-[-10000px] p-4' id="clinical-notes-contentMobile">
                          <div className='text-center text-[20px] text-[#A31B1B] font-semibold mb-5'>Clinical Notes</div>
                          {clinicalNotesData.map((clinicalNote, index) => (
                            <div className='pt-[6px]' key={index}>
                              <div className='text-left text-[14px] text-[#A31B1B] font-semibold mb-[10px]'>{clinicalNote.SectionName}</div>
                              {clinicalNote.Summary.map((note, index) => (
                                <div className='w-full pr-[6px]' >
                                  <div key={index} className='w-full pr-[6px] text-[12px]'>{index + 1 + '. '}{note.SummarizedSegment}</div>
                                </div>
                              ))}
                            </div>
                          ))}</div>
                      </div> : <div className=" grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1  ">
                        <div className=" ">
                          {
                            clinicalNotes ? (
                              <div className="content-container pt-2 pb-2 ">
                                <ul>
                                  <li >
                                    <div className="">
                                      <div className="p-3 block pb-[0px] ">
                                        <h3 className="m-0 p-0 mb-4 flex justify-between items-center">
                                          <div>
                                            <span className="text-[#A31B1B] font-semibold ">{`Notes :`}</span>
                                            <span className="text-black font-normal ml-2">{` (${new Date(
                                              clinicalNotes.creationTime
                                            ).toLocaleDateString(
                                              "en-US",
                                              {
                                                month:
                                                  "short",
                                                day: "2-digit",
                                                year: "numeric",
                                              }
                                            )})`}</span></div>
                                          <div onClick={() => { setAudioFilesView(false) }} id='close-history-audio-files'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5 text-[#A31B1B]">
                                              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                                            </svg>

                                          </div>
                                        </h3>
                                        {clinicalNotes?.transcript !=
                                          "Empty" ? (
                                          <CustomAudioPlayer
                                            key={
                                              clinicalNotes.id
                                            }
                                            id="AudioTagIdHistory"
                                            fileUrl={`${process.env.REACT_APP_EMRAXIS_S3_URL}/${clinicalNotes?.documentUrl}`}
                                            isPlaying={
                                              currentPlaying ===
                                              clinicalNotes
                                            }
                                            onPlay={() =>
                                              setCurrentPlaying(
                                                clinicalNotes
                                              )
                                            }
                                            onPause={() =>
                                              setCurrentPlaying(
                                                null
                                              )
                                            }
                                          />
                                        ) : (
                                          <p></p>
                                        )}
                                      </div>
                                    </div>
                                  </li>
                                  <li

                                    className=" pl-[0.65rem] "
                                  >
                                    {clinicalNotes?.transcript !=
                                      "Empty" ? (
                                      <p className="text-base">
                                        {
                                          clinicalNotes?.transcript
                                        }
                                      </p>
                                    ) : (
                                      <p className="text-center  px-4 py-2">
                                        Voice Notes are
                                        not present.
                                      </p>
                                    )}
                                  </li>
                                </ul>
                              </div>
                            ) : (
                              <p className='mb-4 className="text-center border px-4 py-2"'>
                                No audio files available.
                              </p>
                            )}
                        </div>
                        <div className="col-span-9 justify-self-start"></div>
                      </div>}

                  </>))
                : <>
                  <div className='flex justify-center items-center h-[150px]'>
                    <div className='text-center'>No clinical Notes are available.</div>
                  </div>
                  </> :
              <div className="text-left ml-2">

                <textarea
                  id="doctorNotes"
                  style={{ resize: "none" }}
                  onChange={(evt) => {
                    setDrNotes(evt.target.value);
                    setIsAnyChanges(true);
                  }}
                  className="w-full mt-2 px-2 rounded-md text-sm border border-gray-300 focus:border-gray-500 text-gray-900 bg-white dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                  value={drNotes}
                  rows={5}
                  placeholder="Doctor Notes"
                ></textarea>
              </div>}
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 ">
            {files
              ?.filter((a) => a.file_type === "Notes")
              ?.map((file, index) => {
                const fileExtension = file.file_path
                  .split(".")
                  .pop()
                  .toLowerCase();
                const isImage = [
                  "jpg",
                  "jpeg",
                  "png",
                  "gif",
                  "bmp",
                  "webp",
                ].includes(fileExtension);
                const isAudio = ["mp3", "wav", "ogg", "m4a"].includes(
                  fileExtension
                );
                const isVideo = ["mp4", "webm", "ogg", "mov"].includes(
                  fileExtension
                );

                return (
                  <div
                    className="relative border rounded-sm"
                    key={"image-notes-" + index}
                  >
                    <a
                      rel="noopener"
                      target="_blank"
                      href={
                        process.env.REACT_APP_EMRAXIS_S3_URL +
                        "/" +
                        file.file_path
                      }
                      className=""
                    >
                      {isImage ? (
                        <img
                          id="imgMobDoctor"
                          className="max-h-[180px] w-auto h-auto object-contain p-5"
                          src={
                            process.env.REACT_APP_EMRAXIS_S3_URL +
                            "/" +
                            file.file_path
                          }
                          alt="Prescription"
                        />
                      ) : isAudio ? (
                        <div className="flex justify-center items-center h-full">
                          {" "}
                          <audio className="w-full max-w-[400px]" controls>
                            <source
                              src={
                                process.env.REACT_APP_EMRAXIS_S3_URL +
                                "/" +
                                file.file_path
                              }
                              type={`audio/${fileExtension}`}
                            />
                            Your browser does not support the audio element.
                          </audio>
                        </div>
                      ) : isVideo ? (
                        <video
                          className="max-h-[180px] w-auto h-auto object-contain p-5"
                          controls
                        >
                          <source
                            src={
                              process.env.REACT_APP_EMRAXIS_S3_URL +
                              "/" +
                              file.file_path
                            }
                            type={`video/${fileExtension}`}
                          />
                          Your browser does not support the video element.
                        </video>
                      ) : (
                        <div className="flex justify-center items-center h-full">
                          <FileIcon
                            ext={"." + fileExtension}
                            className="max-h-[180px] w-auto h-auto object-contain p-5  "
                          />
                        </div>
                      )}
                    </a>
                    <button
                      id="btnNotesFileDeleteCnfBox"
                      className="absolute top-2 left-2 bg-gray-100 rounded-full p-1 shadow"
                      onClick={() => {
                        setNotesFileDeleteCnfBox(true);
                        setNotesFileDeleteID([{ ...file, is_active: false }]);
                      }}
                    >
                      <svg
                        id="SvgMobNotesFileDelete"
                        xmlns="http://www.w3.org/2000/svg"
                        className="size-6 text-red-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                        />
                      </svg>
                    </button>
                  </div>
                );
              })}
          </div>
          <div className="bg-gray-100 font-semibold justify-center  flex items-center mt-4">
            <div
              className={`font-bold text-lg text-center py-2 ${previewTheme.headingTitleColor}`}
              style={{ fontFamily: previewTheme.fontFamily }}
            >
              Prescription
            </div>
            <div className="flex items-center absolute right-8 ">
              <button
                id="btnOpenPopupButton"
                className="sm:flex md:flex sm:top-section sm:border"
                onClick={openPopup}
              >
                <p
                  id="OpenPrescription"
                  className=" flex text-red-800 cursor-pointer"
                >
                  <HiMiniPlusCircle className="size-6 ml-2" />
                </p>
              </button>
              {isPrescriptionOpen && (
                <div>
                  <div className="fixed inset-0 overflow-hidden flex justify-center items-center z-50">
                    <div className="absolute inset-0 bg-black opacity-25"></div>
                    <div
                      className="relative bg-white shadow-xl p-8 max-h-[70vh] overflow-y-auto"
                      style={{ width: "350px" }}
                    >
                      <div className="flex items-center justify-between">
                        <header
                          className={`origin-top-left flex font-bold mb-2 text-2xl lg:text-xl sm:text-lg pl-2.5 pt-1 ${previewTheme.headingTitleColor}`}
                          style={{ fontFamily: previewTheme.fontFamily }}
                        >
                          Add Prescription
                        </header>
                        <button
                          id="btnClosePopupButton"
                          className={`text-red-700 sm:justify-left`}
                          onClick={closePopup}
                        >
                          <svg
                            id="SvgMobPrescriptionPopup"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className={`h-10 w-10 ${previewTheme.popupCloseBtnColor} transition-colors duration-300 ease-in-out hover:${previewTheme.popupCloseBtnHoverColor}`}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                          </svg>
                        </button>
                      </div>
                      {
                        <div>
                          {
                            <>
                              <div className="mb-4 mt-4">
                                <div className="mb-4">
                                  <div className="relative rounded-md border border-gray-600">
                                    <CreatableSelect
                                      id={"selectedMedicine"}
                                      name="medicine"
                                      styles={{
                                        input: (base) => ({
                                          ...base,
                                          "input:focus": {
                                            boxShadow: "none",
                                          },
                                        }),
                                        control: (provided, state) => ({
                                          ...provided,
                                          height: "45px",
                                          minHeight: "45px",
                                          border: "none",
                                          boxShadow: state.isFocused
                                            ? "0 0 0 1px blue"
                                            : "none",
                                          "&:hover": {
                                            boxShadow: state.isFocused
                                              ? "0 0 0 1px blue"
                                              : "0 0 0 1px gray",
                                          },
                                        }),
                                      }}
                                      value={{
                                        value: newMedicine.medicine,
                                        label: newMedicine.medicine,
                                      }}
                                      onChange={(selectedOption) =>
                                        handleChangeMedicine(
                                          newMedicine.order_id,
                                          selectedOption
                                        )
                                      }
                                      options={getAvailableOptions()}
                                      onCreateOption={(inputValue) =>
                                        handleCreateChangeMedicine(
                                          newMedicine.order_id,
                                          inputValue
                                        )
                                      }
                                      getOptionLabel={(option) => option.label}
                                      placeholder="Type"
                                    />
                                    <label
                                      htmlFor="medicine"
                                      className="absolute top-0 left-4 -mt-2 px-1 bg-white text-sm font-medium text-gray-700"
                                    >
                                      Medicine*
                                    </label>
                                  </div>
                                </div>
                                <div className="mb-4">
                                  <div className="relative rounded-md border border-gray-600 w-full">
                                    <select
                                      id={"selectedMedicineType"}
                                      name="medicine_type"
                                      value={newMedicine.medicine_type}
                                      onChange={(event) =>
                                        handleChangeMedicine(
                                          newMedicine.order_id,
                                          event,
                                          "medicine_type"
                                        )
                                      }
                                      className="block w-full border-0 focus:ring-0 p-3 sm:p-2 sm:text-base text-sm rounded-md"
                                    >
                                      <option value="Tablet">Tablet</option>
                                      <option value="Syrup">Syrup</option>
                                      <option value="Capsule">Capsule</option>
                                      <option value="Ointment">Ointment</option>
                                    </select>
                                    <label
                                      htmlFor="formType"
                                      className="absolute top-0 left-4 -mt-2 px-1 bg-white text-sm font-medium text-gray-700"
                                    >
                                      Tablet
                                    </label>
                                  </div>
                                </div>

                                <div className="mb-4">
                                  <div className="flex items-center mb-4">
                                    <span className="ml-2 mr-4 text-gray-700">
                                      Before Food:
                                    </span>
                                    <label
                                      htmlFor="beforeFood"
                                      className="inline-flex items-center"
                                    >
                                      <input
                                        id={"checkWhenMedicine"}
                                        type="checkbox"
                                        className="ml-2 form-checkbox h-5 w-5 text-blue-600"
                                        name="when_medidcine"
                                        checked={newMedicine.when_medidcine}
                                        onChange={(event) =>
                                          handleChangeMedicine(
                                            newMedicine.order_id,
                                            event,
                                            "when_medidcine"
                                          )
                                        }
                                      />
                                    </label>
                                  </div>

                                  <div className="mb-4">
                                    <div className="relative border border-gray-500 px-4 py-4 rounded">
                                      <label className="absolute top-0 left-4 -mt-2 px-1 bg-white text-sm font-medium text-gray-700">
                                        What time do you take this medicine?
                                      </label>
                                      <div className="mt-6">
                                        <div className="mb-4 flex justify-between items-center">
                                          <span className="ml-2 text-gray-700">
                                            Morning
                                          </span>
                                          <label
                                            htmlFor="morning"
                                            className="inline-flex items-center"
                                          >
                                            <input
                                              id={"chkBoxMrng"}
                                              type="checkbox"
                                              className="h-8 w-8 rounded-md"
                                              name="morning"
                                              checked={
                                                newMedicine.morning === "1"
                                              }
                                              onChange={(event) =>
                                                handleChangeMedicine(
                                                  newMedicine.order_id,
                                                  event,
                                                  "morning"
                                                )
                                              }
                                            />
                                          </label>
                                        </div>
                                        <div className="mb-4 flex justify-between items-center">
                                          <span className="ml-2 text-gray-700">
                                            Afternoon
                                          </span>
                                          <label
                                            htmlFor="afternoon"
                                            className="inline-flex items-center"
                                          >
                                            <input
                                              id={"chkBoxNoon"}
                                              type="checkbox"
                                              className="h-8 w-8 rounded-md"
                                              name="noon"
                                              checked={newMedicine.noon === "1"}
                                              onChange={(event) =>
                                                handleChangeMedicine(
                                                  newMedicine.order_id,
                                                  event,
                                                  "noon"
                                                )
                                              }
                                            />
                                          </label>
                                        </div>
                                        <div className="mb-4 flex justify-between items-center">
                                          <span className="ml-2 text-gray-700">
                                            Night
                                          </span>
                                          <label
                                            htmlFor="night"
                                            className="inline-flex items-center"
                                          >
                                            <input
                                              id={"chkBoxNight"}
                                              type="checkbox"
                                              className="h-8 w-8 rounded-md"
                                              name="night"
                                              checked={
                                                newMedicine.night === "1"
                                              }
                                              onChange={(event) =>
                                                handleChangeMedicine(
                                                  newMedicine.order_id,
                                                  event,
                                                  "night"
                                                )
                                              }
                                            />
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="mb-4">
                                    <div className="relative rounded-md border border-gray-600">
                                      <input
                                        id={"txtDuration"}
                                        type="text"
                                        name="duration"
                                        value={newMedicine.duration}
                                        onChange={(event) =>
                                          handleChangeMedicine(
                                            newMedicine.order_id,
                                            event,
                                            "duration"
                                          )
                                        }
                                        className="appearance-none border-none bg-transparent w-full p-3 leading-tight focus:outline-none"
                                        placeholder="Duration"
                                      />
                                      <label
                                        htmlFor="duration"
                                        className="absolute top-0 left-4 -mt-2 px-1 bg-white text-sm font-medium text-gray-700"
                                      >
                                        Duration (Days)*
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <div className="mt-8 flex justify-center">
                                  <button
                                    id="btnAddRow"
                                    className={`px-4 disabled:cursor-not-allowed disabled:opacity-50 py-2 border rounded-md text-white transition-colors duration-300 ease-in-out ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} hover:${previewTheme.saveBtnHoverColor}`}
                                    onClick={addRowMedicine}
                                  >
                                    Add
                                  </button>
                                </div>
                              </div>
                            </>
                          }
                        </div>
                      }
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>{" "}
          <div className="">
            <div className="flex flex-col gap-4">
              {rows
                .filter((a) => a.medicine !== "")
                .map((row, index) => (
                  <div
                    key={`current-medicine-${index}`}
                    className="p-4 rounded-md shadow-md border border-gray-200"
                  >
                    <div className="mb-2 flex items-center">
                      <span className="font-semibold"></span>
                      <span>{row.medicine}</span>
                      <button
                        id="btnEditPre"
                        className="ml-auto cursor-pointer"
                        onClick={() => handleEditPreClick(row, index)}
                      >
                        <svg
                          id="SvgMobEditPrescription"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          className="cursor-pointer"
                        >
                          <path
                            d="M7.243 17.997H3V13.754L14.435 2.31903C14.6225 2.13156 14.8768 2.02625 15.142 2.02625C15.4072 2.02625 15.6615 2.13156 15.849 2.31903L18.678 5.14703C18.771 5.2399 18.8447 5.35019 18.8951 5.47159C18.9454 5.59299 18.9713 5.72312 18.9713 5.85453C18.9713 5.98595 18.9454 6.11607 18.8951 6.23747C18.8447 6.35887 18.771 6.46916 18.678 6.56203L7.243 17.997ZM3 19.997H21V21.997H3V19.997Z"
                            fill="#A31B1B"
                          />
                        </svg>
                      </button>
                      <button
                        id="btnDeleteRow"
                        onClick={() => deleteRow(row.order_id)}
                      >
                        <svg
                          id="SvgMobDeleteRow"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M9.808 17H10.808V7.99998H9.808V17ZM13.192 17H14.192V7.99998H13.192V17ZM6 20V5.99998H5V4.99998H9V4.22998H15V4.99998H19V5.99998H18V20H6Z"
                            fill="#A31B1B"
                          />
                        </svg>
                      </button>
                    </div>

                    <div className="mb-2">
                      <span className="font-semibold"></span>
                      <span>{row.duration || "-"} (days)</span>
                    </div>
                    <div className="mb-2">
                      <span className="font-semibold"></span>
                      <span>
                        {row.morning === "1" ? "1" : "0"} -
                        {row.noon === "1" ? "1" : "0"} -
                        {row.night === "1" ? "1" : "0"}{" "}
                        {row.when_medidcine ? "(Before Food)" : ""}
                      </span>
                    </div>
                  </div>
                ))}

              {rows.filter((a) => a.medicine !== "").length > 0 && (
                <div className=" lg:hidden sm:block text-right gap-2 justify-end mt-4 inline-flex mr-2">
                  {rows.length > 0 && (
                    <button
                      id={"btnDeleteAllMedicineRowMobile"}
                      title="Delete All"
                      disabled={submitDisable}
                      onClick={() => setConfirmBoxDeleteAll(true)}
                    >
                      <span className="flex justify-end text-red-800 font-semibold hover:underline pr-1">
                        Delete All
                        <svg
                          id="SvgMobDeleteAllPre"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-6 h-6 text-red-800 hover:text-red-900"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                    </button>
                  )}
                  <button
                    id="btnSavePre"
                    disabled={submitDisable}
                    onClick={() => {
                      savePrescription("save");
                    }}
                  >

                    {loadingSave ? (
                      <svg
                        id="SvgMobSave"
                        aria-hidden="true"
                        role="status"
                        className="inline w-4 h-4 ml-1 text-red-500 animate-spin"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#E5E7EB"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                    ) : <IoMdSave
                      className="text-green-600 size-6 cursor-pointer ml-1"
                      id="SAVEPRESC"
                    />}
                  </button>
                  <button
                    id="btnSavePrint"
                    disabled={submitDisable}
                    onClick={() => {
                      savePrescription("save-print");
                    }}
                  >
                    <svg
                      id="SvgMobsavePrint"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      className=""
                    >
                      <path
                        d="M6 6.94777V2.97754H18V6.94777H6ZM18 12.4068C18.2833 12.4068 18.521 12.3116 18.713 12.121C18.905 11.9304 19.0007 11.6948 19 11.4143C18.9993 11.1337 18.9033 10.8981 18.712 10.7076C18.5207 10.517 18.2833 10.4217 18 10.4217C17.7167 10.4217 17.4793 10.517 17.288 10.7076C17.0967 10.8981 17.0007 11.1337 17 11.4143C16.9993 11.6948 17.0953 11.9307 17.288 12.122C17.4807 12.3132 17.718 12.4082 18 12.4068ZM8 18.8585H16V14.8882H8V18.8585ZM6 20.8436V16.8733H2V7.94033H22V16.8733H18V20.8436H6Z"
                        fill="#176CBB"
                      />
                    </svg>
                    {loadingSavePrint && (
                      <svg
                        id="SvgMobloadingSavePrint"
                        aria-hidden="true"
                        role="status"
                        className="inline w-4 h-4 ml-2 text-white animate-spin"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#E5E7EB"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                    )}
                  </button>
                </div>
              )}
            </div>

            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
              {files
                ?.filter((a) => a.file_type === "Prescription")
                ?.map((file, index) => {
                  const fileExtension = file.file_path
                    .split(".")
                    .pop()
                    .toLowerCase();
                  const isImage = [
                    "jpg",
                    "jpeg",
                    "png",
                    "gif",
                    "bmp",
                    "webp",
                  ].includes(fileExtension);
                  const isAudio = ["mp3", "wav", "ogg", "m4a"].includes(
                    fileExtension
                  );
                  const isVideo = ["mp4", "webm", "ogg", "mov"].includes(
                    fileExtension
                  );

                  return (
                    <div
                      key={index}
                      className="relative p-2 border rounded-sm h-[220px]"
                    >
                      <a
                        rel="noopener"
                        target="_blank"
                        href={
                          process.env.REACT_APP_EMRAXIS_S3_URL +
                          "/" +
                          file.file_path
                        }
                        className="block h-full"
                      >
                        <div className="flex justify-center items-center h-full">
                          {isImage ? (
                            <img
                              id="imgMobPre"
                              className="max-h-[180px] max-w-full w-auto h-auto object-contain"
                              src={
                                process.env.REACT_APP_EMRAXIS_S3_URL +
                                "/" +
                                file.file_path
                              }
                              alt={file.file_name || "File"}
                            />
                          ) : isAudio ? (
                            <audio className="w-full max-w-[400px]" controls>
                              <source
                                src={
                                  process.env.REACT_APP_EMRAXIS_S3_URL +
                                  "/" +
                                  file.file_path
                                }
                                type={`audio/${fileExtension}`}
                              />
                            </audio>
                          ) : isVideo ? (
                            <video
                              className="max-h-[180px] max-w-full w-auto h-auto object-contain"
                              controls
                            >
                              <source
                                src={
                                  process.env.REACT_APP_EMRAXIS_S3_URL +
                                  "/" +
                                  file.file_path
                                }
                                type={`video/${fileExtension}`}
                              />
                            </video>
                          ) : (
                            <FileIcon
                              ext={"." + fileExtension}
                              className="max-h-[180px] w-auto h-auto object-contain"
                            />
                          )}
                        </div>
                      </a>
                      <button
                        id={"btnDeletePrescriptionFile" + index}
                        className="absolute top-2 left-2 bg-gray-100 rounded-full p-1 shadow"
                        onClick={() => {
                          setConfirmBox(true);
                          setDeleteFile([file]);
                        }}
                      >
                        <svg
                          id="SvgDeletePrescriptionFile"
                          xmlns="http://www.w3.org/2000/svg"
                          className="size-6 text-red-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                          />
                        </svg>
                      </button>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="bg-gray-100 font-semibold mt-2 py-2">
            <div
              className={` font-bold text-lg sm:mb-6 text-center ${previewTheme.headingTitleColor}`}
              style={{ fontFamily: previewTheme.fontFamily }}
            >
              Patient Files
            </div>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 mb-4">
            {files
              ?.filter(
                (a) =>
                  a.file_type !== "Prescription" &&
                  a.file_type !== "Notes" &&
                  a.file_type !== "Lab Reports" &&
                  a.file_type !== "X Ray"
              )
              ?.map((file, index) => {
                const fileExtension = file.file_path
                  .split(".")
                  .pop()
                  .toLowerCase();
                const isImage = [
                  "jpg",
                  "jpeg",
                  "png",
                  "gif",
                  "bmp",
                  "webp",
                ].includes(fileExtension);
                const isAudio = ["mp3", "wav", "ogg", "m4a"].includes(
                  fileExtension
                );
                const isVideo = ["mp4", "webm", "ogg", "mov"].includes(
                  fileExtension
                );

                return (
                  <div
                    className="relative border rounded-sm"
                    key={"image-notes-" + index}
                  >
                    <a
                      rel="noopener"
                      target="_blank"
                      href={
                        process.env.REACT_APP_EMRAXIS_S3_URL +
                        "/" +
                        file.file_path
                      }
                      className=""
                    >
                      {isImage ? (
                        <img
                          id="imgMobFiles"
                          className="max-h-[180px] w-auto h-auto object-contain p-5"
                          src={
                            process.env.REACT_APP_EMRAXIS_S3_URL +
                            "/" +
                            file.file_path
                          }
                          alt="Prescription"
                        />
                      ) : isAudio ? (
                        <div className="flex justify-center items-center h-full">
                          {" "}
                          <audio className="w-full max-w-[400px]" controls>
                            <source
                              src={
                                process.env.REACT_APP_EMRAXIS_S3_URL +
                                "/" +
                                file.file_path
                              }
                              type={`audio/${fileExtension}`}
                            />
                            Your browser does not support the audio element.
                          </audio>
                        </div>
                      ) : isVideo ? (
                        <video
                          className="max-h-[180px] w-auto h-auto object-contain p-5"
                          controls
                        >
                          <source
                            src={
                              process.env.REACT_APP_EMRAXIS_S3_URL +
                              "/" +
                              file.file_path
                            }
                            type={`video/${fileExtension}`}
                          />
                          Your browser does not support the video element.
                        </video>
                      ) : (
                        <div className="flex justify-center items-center h-full">
                          <FileIcon
                            ext={"." + fileExtension}
                            className="max-h-[180px] w-auto h-auto object-contain p-5  "
                          />
                        </div>
                      )}
                    </a>
                    <button
                      id={"btnDeleteUploadedData" + index}
                      className="absolute top-2 left-2 bg-gray-100 rounded-full p-1 shadow"
                      onClick={() => {
                        setNotesFileDeleteCnfBox(true);
                        setNotesFileDeleteID([{ ...file, is_active: false }]);
                      }}
                    >
                      <svg
                        id="SvgMobPrescriptionFileDeleteBox"
                        xmlns="http://www.w3.org/2000/svg"
                        className="size-6 text-red-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                        />
                      </svg>
                    </button>
                  </div>
                );
              })}
          </div>
          <div className="bg-gray-100 font-semibold justify-center flex items-center py-2">
            <div
              className={`font-bold text-xl text-center ${previewTheme.headingTitleColor}`}
              style={{ fontFamily: previewTheme.fontFamily }}
            >
              Lab Notes
            </div>
          </div>
          <div className="text-left ml-2">
            <textarea
              id="labNotes"
              style={{ resize: "none" }}
              onChange={(evt) => {
                setDrLabNotes(evt.target.value);
                setIsAnyChanges(true);
              }}
              className="w-full mt-2 px-2 rounded-md text-sm border border-gray-300 focus:border-gray-500 text-gray-900 bg-white dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
              value={drLabNotes}
              rows={5}
              placeholder="Lab Notes"
            ></textarea>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 mb-4">
            {files
              ?.filter((a) => a.file_type === "Lab Reports")
              ?.map((file, index) => {
                const fileExtension = file.file_path
                  .split(".")
                  .pop()
                  .toLowerCase();
                const isImage = [
                  "jpg",
                  "jpeg",
                  "png",
                  "gif",
                  "bmp",
                  "webp",
                ].includes(fileExtension);
                const isAudio = ["mp3", "wav", "ogg", "m4a"].includes(
                  fileExtension
                );
                const isVideo = ["mp4", "webm", "ogg", "mov"].includes(
                  fileExtension
                );

                return (
                  <div
                    className="relative border rounded-sm"
                    key={"image-notes-" + index}
                  >
                    <a
                      rel="noopener"
                      target="_blank"
                      href={
                        process.env.REACT_APP_EMRAXIS_S3_URL +
                        "/" +
                        file.file_path
                      }
                      className=""
                    >
                      {isImage ? (
                        <img
                          id="imgMobLab"
                          className="max-h-[180px] w-auto h-auto object-contain p-5"
                          src={
                            process.env.REACT_APP_EMRAXIS_S3_URL +
                            "/" +
                            file.file_path
                          }
                          alt="Prescription"
                        />
                      ) : isAudio ? (
                        <div className="flex justify-center items-center h-full">
                          {" "}
                          <audio className="w-full max-w-[400px]" controls>
                            <source
                              src={
                                process.env.REACT_APP_EMRAXIS_S3_URL +
                                "/" +
                                file.file_path
                              }
                              type={`audio/${fileExtension}`}
                            />
                            Your browser does not support the audio element.
                          </audio>
                        </div>
                      ) : isVideo ? (
                        <video
                          className="max-h-[180px] w-auto h-auto object-contain p-5"
                          controls
                        >
                          <source
                            src={
                              process.env.REACT_APP_EMRAXIS_S3_URL +
                              "/" +
                              file.file_path
                            }
                            type={`video/${fileExtension}`}
                          />
                          Your browser does not support the video element.
                        </video>
                      ) : (
                        <div className="flex justify-center items-center h-full">
                          <FileIcon
                            ext={"." + fileExtension}
                            className="max-h-[180px] w-auto h-auto object-contain p-5  "
                          />
                        </div>
                      )}
                    </a>
                    <button
                      id="btnNotesFileDeleteCnf"
                      className="absolute top-2 left-2 bg-gray-100 rounded-full p-1 shadow"
                      onClick={() => {
                        setNotesFileDeleteCnfBox(true);
                        setNotesFileDeleteID([{ ...file, is_active: false }]);
                      }}
                    >
                      <svg
                        id="SvgMobLabNotesFileDeleteCnfBox"
                        xmlns="http://www.w3.org/2000/svg"
                        className="size-6 text-red-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                        />
                      </svg>
                    </button>
                  </div>
                );
              })}
          </div>
          <div className="bg-gray-100 font-semibold justify-center flex items-center py-2">
            <div
              className={`font-bold text-xl text-center ${previewTheme.headingTitleColor}`}
              style={{ fontFamily: previewTheme.fontFamily }}
            >
              Radiology
            </div>
          </div>
          <div className="text-left ml-2">
            <textarea
              id="RadNotes"
              style={{ resize: "none" }}
              onChange={(evt) => {
                setDrRadNotes(evt.target.value);
                setIsAnyChanges(true);
              }}
              className="w-full mt-2 px-2 rounded-md text-sm border border-gray-300 focus:border-gray-500 text-gray-900 bg-white dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
              value={drRadNotes}
              rows={5}
              placeholder="Radiology Notes"
            ></textarea>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 p-2">
            {files
              ?.filter((a) => a.file_type === "X Ray")
              ?.map((file, index) => {
                const fileExtension = file.file_path
                  .split(".")
                  .pop()
                  .toLowerCase();
                const isImage = [
                  "jpg",
                  "jpeg",
                  "png",
                  "gif",
                  "bmp",
                  "webp",
                ].includes(fileExtension);
                const isAudio = ["mp3", "wav", "ogg", "m4a"].includes(
                  fileExtension
                );
                const isVideo = ["mp4", "webm", "ogg", "mov"].includes(
                  fileExtension
                );

                return (
                  <div
                    className="relative border rounded-sm"
                    key={"image-notes-" + index}
                  >
                    <a
                      rel="noopener"
                      target="_blank"
                      href={
                        process.env.REACT_APP_EMRAXIS_S3_URL +
                        "/" +
                        file.file_path
                      }
                      className=""
                    >
                      {isImage ? (
                        <img
                          id="imgmobRadiology"
                          className="max-h-[180px] w-auto h-auto object-contain p-5"
                          src={
                            process.env.REACT_APP_EMRAXIS_S3_URL +
                            "/" +
                            file.file_path
                          }
                          alt="Prescription"
                        />
                      ) : isAudio ? (
                        <div className="flex justify-center items-center h-full">
                          {" "}
                          <audio className="w-full max-w-[400px]" controls>
                            <source
                              src={
                                process.env.REACT_APP_EMRAXIS_S3_URL +
                                "/" +
                                file.file_path
                              }
                              type={`audio/${fileExtension}`}
                            />
                            Your browser does not support the audio element.
                          </audio>
                        </div>
                      ) : isVideo ? (
                        <video
                          className="max-h-[180px] w-auto h-auto object-contain p-5"
                          controls
                        >
                          <source
                            src={
                              process.env.REACT_APP_EMRAXIS_S3_URL +
                              "/" +
                              file.file_path
                            }
                            type={`video/${fileExtension}`}
                          />
                          Your browser does not support the video element.
                        </video>
                      ) : (
                        <div className="flex justify-center items-center h-full">
                          <FileIcon
                            ext={"." + fileExtension}
                            className="max-h-[180px] w-auto h-auto object-contain p-5  "
                          />
                        </div>
                      )}
                    </a>
                    <button
                      id="btnNotesFile"
                      className="absolute top-2 left-2 bg-gray-100 rounded-full p-1 shadow"
                      onClick={() => {
                        setNotesFileDeleteCnfBox(true);
                        setNotesFileDeleteID([{ ...file, is_active: false }]);
                      }}
                    >
                      <svg
                        id="SvgMobRadiologyFileDeleteCnfBox"
                        xmlns="http://www.w3.org/2000/svg"
                        className="size-6 text-red-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                        />
                      </svg>
                    </button>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="flex justify-between items-center mb-4">
          <div></div>
          <div className="mt-2 flex justify-center">
            <ReactToPrint
              trigger={() => (
                <button
                  id="btnReactToPrint"
                  className="px-4 py-2 border bg-blue-600 hover:bg-blue-700 text-white rounded-md"
                  onClick={handlePrintMobile}
                >
                  Print
                  <svg
                    id="SvgMobReactToPrint"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="inline size-6"
                  >
                    <path d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z" />
                  </svg>
                </button>
              )}
              content={() => document.getElementById("mobile-content-to-print")}
            />
          </div>
        </div>
      </div>
      {/* Desktop View */}

      <div className="mx-auto pt-[0.5rem] pb-8 mb-10 hidden lg:block">
        <div className="flex">
          <div className="flex-none">
            <div className="fixed  pr-6"></div>
          </div>

          <div className="flex-auto w-auto">
            <div className="origin-top-left flex font-bold mt-2 lg:text-black text-red-700 lg:text-lg sm:text-xl justify-between items-center flex justify-between bg-[#e2e8f0] text-[#a31b1b] rounded-t-lg text-lg font-semibold py-1 px-2' ">
              <div className="flex justify-between w-full">
                <div>
                  <span
                    className={`ml-3 text-xl ${previewTheme.headingTitleColor}`}
                    style={{ fontFamily: previewTheme.fontFamily }}
                  >
                    Visit History
                  </span>
                </div>
              </div>
            </div>

            <div id="laptop-content-to-print">
              {pastHistory.map((history, index) => (
                <div
                  key={history.appointment_id}
                  ref={(el) =>
                    (historyRefs.current[history.appointment_id] = el)
                  }
                  data-date={DateFunction.GetDate(history.appointment_time)}
                  data-appointment_id={history.appointment_id}
                  visit-navbar-section="visit-details"
                >
                  <div className="relative bg-red-300 h-6 flex items-center justify-between p-4">
                    <p
                      id="DateFunctionTime"
                      className="text-center font-semibold flex-grow"
                    >
                      <span className="px-1">
                        {DateFunction.GetDate(history.appointment_time)}
                      </span>
                    </p>
                    <div className="absolute right-0 flex lg:mr-10 mr-4">
                      {history.appointment_id && (
                        <>
                          <svg
                            id="SvgUploadPast"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() =>
                              handleUploadButton(
                                "upload",
                                history.appointment_id,
                                selectedAppointmentId
                              )
                            }
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            className="lg:size-7 size-5 lg:mr-8 mr-0 inline cursor-pointer text-red-700"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                            />
                          </svg>
                          <svg
                            id="SvgCapturePast"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() =>
                              handleUploadButton(
                                "capture",
                                history.appointment_id,
                                selectedAppointmentId
                              )
                            }
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            className="hidden lg:inline size-7 cursor-pointer text-red-700"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z"
                            />
                          </svg>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="bg-gray-100 font-semibold flex items-center py-1 relative">
                    <div className="flex-grow text-center">
                      <div
                        className={`font-bold text-xl ${previewTheme.headingTitleColor}`}
                        style={{ fontFamily: previewTheme.fontFamily }}
                      >
                        Patient Details
                      </div>
                    </div>
                  </div>

                  <section
                    id={`${selectedAppointment.appointment_id}-secVitals`}
                    className="w-full mt-4"
                    ref={(el) => {
                      historySectionRefs.current[
                        `${history.appointment_id}-history-vitals`
                      ] = el;
                      subSectionRefs.current["1" + history.appointment_id] = el;
                    }}

                    selectedAppointmentId
                    data-navbar-section-history={`${history.appointment_id}-history-vitals`}

                    data-appointment-id={history.appointment_id}
                  >
                    <div className="w-full mt-2 grid grid-cols-2 gap-4">
                      <div className="p-2 bg-white shadow-lg border border-gray-300 rounded-md ">
                        <div className="mb-2 flex items-center">
                          <span className="text-sm w-[114px]">Name:</span>
                          <span className="ml-2">
                            {history.patient_name ||
                              patient?.FirstName +
                              " " +
                              patient?.MiddleName?.charAt(0) +
                              " " +
                              patient?.LastName}
                          </span>
                        </div>
                        <div className="mb-2 flex items-center">
                          <span className="text-sm w-[114px]">
                            Consulted Doctor:
                          </span>
                          <span className="ml-2">{history.doctor_name}</span>
                        </div>
                        <div className="mb-2 flex items-center">
                          <span className="text-sm w-[114px]">
                            Referred By:
                          </span>
                          <span className="ml-2">{history.reffered_by}</span>
                        </div>
                      </div>

                      <div className="p-2 bg-white shadow-lg border border-gray-300 rounded-md grid grid-cols-2">
                        <div className="grid grid-cols-1 gap-2">
                          <div className="flex">
                            <span className="text-sm w-[150px]">Weight:</span>
                            <span className="ml-2">
                              {history?.patientVitals?.weight && (
                                <span>{history?.patientVitals?.weight} kg</span>
                              )}
                            </span>
                          </div>
                          <div className="flex">
                            <span className="text-sm w-[150px]">Oxygen:</span>
                            <span className="ml-2">
                              {history?.patientVitals?.respiration_rate && (
                                <span>
                                  {history?.patientVitals?.respiration_rate} %
                                </span>
                              )}
                            </span>
                          </div>
                          <div className="flex">
                            <span className="text-sm w-[150px]">
                              Body Temperature:
                            </span>
                            <span className="ml-2">
                              {history?.patientVitals?.body_temperature && (
                                <span>
                                  {history?.patientVitals?.body_temperature}° F
                                </span>
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="grid grid-cols-1 gap-2">
                          <div className="flex">
                            <span className="text-sm w-[150px]">Pulse:</span>
                            <span className="ml-2">
                              {history?.patientVitals?.pulse_rate && (
                                <span>
                                  {history?.patientVitals?.pulse_rate} bpm
                                </span>
                              )}
                            </span>
                          </div>
                          <div className="flex">
                            <span className="text-sm w-[150px]">
                              Blood Pressure:
                            </span>
                            <span className="ml-2">
                              {history?.patientVitals?.blood_pressure && (
                                <span>
                                  {history?.patientVitals?.blood_pressure} mmHG{" "}
                                </span>
                              )}
                            </span>
                          </div>
                          <div className="flex">
                            <span className="text-sm w-[150px]">Height:</span>
                            <span className="ml-2">
                              {history?.patientVitals?.height && (
                                <span>{history?.patientVitals?.height} cm</span>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section
                    id={`${selectedAppointment.appointment_id}-secDoctorNotes`}
                    className="w-full mt-4"
                    ref={(el) => {
                      historySectionRefs.current[
                        `${history.appointment_id}-history-doctor-notes`
                      ] = el;
                      subSectionRefs.current["2" + history.appointment_id] = el;
                    }}
                    data-navbar-section-history={`${selectedAppointmentId}-history-doctor-notes`}

                    data-appointment-id={history.appointment_id}
                  >
                    <DoctorContent
                      history={history}
                      appointment_id={appointment_id}
                      patient={patient}
                    />
                  </section>
                  <section
                    id={`${selectedAppointment.appointment_id}-secPrescription`}
                    className="w-full mt-4"
                    ref={(el) => {
                      historySectionRefs.current[
                        `${history.appointment_id}-history-prescription`
                      ] = el;
                      subSectionRefs.current["3" + history.appointment_id] = el;
                    }}
                    data-navbar-section-history={`${selectedAppointmentId}-history-prescription`}
                    data-navbar-subsection={`${selectedAppointment.appointment_id}-history-prescription`}

                    data-appointment-id={history.appointment_id}
                  >
                    <PrescriptionContent
                      initialHistory={history}
                      appointment_id={appointment_id}
                      patient_id={patient_id}
                      doctor_id={doctor_id}
                      patient={patient}
                    />
                  </section>

                  <section
                    id={`${selectedAppointment.appointment_id}-secLabNotes`}
                    className="w-full mt-4 "
                    ref={(el) => {
                      historySectionRefs.current[
                        `${history.appointment_id}-history-lab-notes`
                      ] = el;
                      subSectionRefs.current["10" + history.appointment_id] =
                        el;
                    }}
                    data-navbar-section-history={`${selectedAppointmentId}-history-lab-notes`}

                    data-appointment-id={history.appointment_id}
                  >
                    <LabContent
                      history={history}
                      appointment_id={appointment_id}
                      patient={patient}
                    />
                  </section>

                  <section
                    id={`${selectedAppointment.appointment_id}-secRadiology`}
                    className="w-full mt-4 h-auto"
                    ref={(el) => {
                      historySectionRefs.current[
                        `${history.appointment_id}-history-radiology`
                      ] = el;
                      subSectionRefs.current["11" + history.appointment_id] =
                        el;
                    }}
                    data-navbar-section-history={`${selectedAppointmentId}-history-radiology`}

                    data-appointment-id={history.appointment_id}
                  >
                    <RadiologyContent
                      history={history}
                      appointment_id={appointment_id}
                      patient={patient}
                    />
                  </section>
                  <section
                    id={`${selectedAppointment.appointment_id}-secUploadedData`}
                    className="w-full mt-4"
                    ref={(el) => {
                      historySectionRefs.current[
                        `${history.appointment_id}-history-uploaded-data`
                      ] = el;
                      subSectionRefs.current["9" + history.appointment_id] = el;
                    }}
                    data-navbar-section-history={`${selectedAppointmentId}-history-uploaded-data`}

                    data-appointment-id={history.appointment_id}
                  >
                    <UploadContent
                      history={history}
                      appointment_id={appointment_id}
                      patient={patient}
                    />
                  </section>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center mb-6">
        <div></div>
        <div className="mt-4 flex justify-center">
          <ReactToPrint
            trigger={() => (
              <button
                id="btnDesktopReactToPrint"
                className="px-4 py-2 border bg-blue-600 hover:bg-blue-700 text-white rounded-md"
                onClick={handlePrintLaptop}
              >
                Print
                <svg
                  id="SvgDesktopReactToPrint"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="inline size-6"
                >
                  <path d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z" />
                </svg>
              </button>
            )}
            content={() => document.getElementById("laptop-content-to-print")}
          />
        </div>
      </div>

      {isMobile && (
        <>
          {showDocumentUploadPopup && <ChooseDocument />}
          {showUploadPopup && <UploadDocument patient_id={patient.patient_id} appointment_id={appointmentId} appointment={pastHistory.filter(a=>a.appointment_id == appointmentId)[0]} patient={patient} page={`${patientProfileMenuSelector === "appointment" ? "Appointment to " : "Patient to " }Patient Profile`}/>}
        </>
      )}

    </>
  );

};

export default PastHistoryDetails;
