import React, { useEffect, useState, useRef } from 'react';
import { IoMdSave } from 'react-icons/io';
import { useSelector, useDispatch } from 'react-redux';
import { theme } from '../../../../EmraxisTheme/Theme';
import DataDecode from '../../../../utils/DataDecode';
import store from '../../../../API/store';
import jsPDF from "jspdf";
import { setNotification } from '../../../../Redux/features/toast/toastSlice';
import html2canvas from "html2canvas";
import { addLoginDetails } from "../../../../Redux/features/login/loginSlice";
import addImage from "../../../../components/Assets/addImage.png";
import ReactDOMServer from "react-dom/server";
import { MenuItem, Select } from "@mui/material";
import downloadImage from "../../../../components/Assets/download.png";
import editImage from "../../../../components/Assets/edit.png";
import copyImage from "../../../../components/Assets/copyImage.png";
import cancelImage from "../../../../components/Assets/cancelImage.png";
import closeImage from "../../../../components/Assets/closedIcon.png";
import CustomAudioPlayer from "../../../../components/CustomAudioPlayer";
import AudioFiles from "../../../../components/Assets/file.png";
import EMRAudioPlayer from "../Audio/EMRAudioPlayer";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { setDocumentType, setIsCameraUpload, setRefreshPrescription, setRefreshUploadedFiles, setRefreshNotes, setShowDocumentUploadPopup, setShowUploadPopup } from '../../../../Redux/features/uploadDoc/uploadDocSlice';
import { setActiveNavvisitbar, setAutoSaveAll, setSelectedAppointmentId, setpastSelected, setDoctorNotes, setPrescriptions, setUploadedData, setLabNotes, setRadiologyNotes } from '../../../../Redux/features/visitDrawer/visitSideNavbarSlice';
import FileIcon from '../FileIcon';
import DateFunction from '../../../../utils/DateFunctions';
const DoctorContent = ({ appointment_id, history, patient,appointment }) => {
    var loggeduser = useSelector(state => state.login.loginuserData);
    var isAiscriptEnable=useSelector(state=>state.feature.isStandardAiActive);
    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    var isVoice2TextActive = useSelector(
      (state) => state.feature.isVoice2TextActive
    );
   
    var isRecLoading = useSelector((state) => state.recording.isLoading);
    var refreshNotes = useSelector(state => state.document_upload.refreshNotes);
    var refreshUploadedFiles = useSelector(state => state.document_upload.refreshUploadedFiles);
    var autoSaveAll = useSelector(state => state.profileSideNavbar.autoSaveAll);
    const previewTheme = useSelector(state => state.theme.colors) || theme.filter(a => a.name === "defaultTheme")[0];
    const textareaRef = useRef(null);
    const [isDoctorContextLoaded, setIsDoctorContextLoaded] = useState(false);
    const isdoctorNotes = useSelector(state => state.visitSideNavbar.isdoctorNotes);
    const [isAnyChanges, setIsAnyChanges] = useState(false);
    const [files, setFiles] = useState([]);
    const [originalFiles, setOriginalFiles] = useState([]);
    const recNotesEndRef = useRef(null);
    const [clinicalNoteData, setClinicalNoteData] = useState([]);
    const [refreshImage, setRefreshImage] = useState(false);
    const [refreshData, setRefreshData] = useState(false);
    const [drNotes, setDrNotes] = useState('');
    const [notesFileDeleteCnfBox, setNotesFileDeleteCnfBox] = useState(false);
    const [loadingDeleteImage, setLoadingDeleteImage] = useState(false);
    const [notesFileDeleteID, setNotesFileDeleteID] = useState(0);
    const [loading, setLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(true);
       const [showAudioFiles, setShowAudioFiles] = useState(false);
    const [transcriptJson, setTranscriptJson] = useState([]);
    const [audioFiles, setAudioFiles] = useState([]);
    const [currentPlaying, setCurrentPlaying] = useState(null); // Track currently playing audio
    const [audioConfirmBox, setAudioConfirmBox] = useState(false);
    const [showClinicalNotes, setShowClinicalNotes] = useState(false);
    const [selectedNote, setSelectedNote] = useState(null);
    const [showAudioFilesData, setShowAudioFilesData] = useState(false);
    const [modifiedJson, setModifiedJson] = useState();
    const [originalJsonBeforeEdit, setOriginalJsonBeforeEdit] = useState([{}]);
    const ContentEdit = useRef(false);
  const [modifyClinicalNoteLoading, setUpdateModifyClinicalNoteLoading] =
    useState(false);
  const [latestNote, setLatestNote] = useState(false);
  const [activeContent, setActiveContent] = useState("");
  const [clinicalNoteVersionList, updateClinicalNoteVersionList] = useState([
    "Original",
  ]);
  const latestVersion = [clinicalNoteVersionList].reverse()[0];
  const [isActiveSaveButton,setIsActiveSaveButton]=useState(false);
  var openRecorderPopUp = useSelector((state) => state.recording.openRecorderPopUp);
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(30);
  const [showOnlyClinicalNote,setShowOnlyClinicalNote] = useState(false)
  const [showOnlyAudioNote,setShowOnlyAudioNote] = useState(false)
  const [selectedFilTemplate, setSelectedFilTemplate] = useState(null);
  const [clinicalNoteVersion, UpdateClinicalNoteVersion] = useState(
    clinicalNoteVersionList
      ? clinicalNoteVersionList[clinicalNoteVersionList.length - 1]
      : ""
  );
    const dispatch = useDispatch();
    var showDocumentUploadPopup = useSelector(state => state.document_upload.showDocumentUploadPopup);
    var patientProfileMenuSelector = useSelector(
      (state) => state.profileSideNavbar.patientProfileMenuSelector
    );
    const [editNotes, setEditNotes] = useState(false);
    const handleEditClick = () => {
        setEditNotes(!editNotes);
        adjustTextareaHeight();
    };
    const focusElementById = (id) => {
      const input = document.getElementById(id); // Get the element by id
      if (input) {
        input.focus(); // Focus on the input
      }
    };
    const handleCloseClinicalNotes = () => {
      setSelectedNote(null);
      setShowAudioFilesData(false);
    };
    const HtmlSTringForPDF = ({ jsonString, patientName_ }) => {
      let patientNameArray = patientName_.split(" ");
      let patientLastName = patientNameArray[patientNameArray.length - 1];
  
      return (
        <div style={{ width: "750px", padding: "12px" }}>
          <div className="!font-['Open Sans'] text-center">
            <h3 className="text-[#005F74] !font-['Open Sans'] text-[20px]">
              Clinical Notes
            </h3>
          </div>
          {jsonString?.Sections?.map((section, sectionIndex) => {
            return (
              <div className="p-2 !leading-6" key={`section-${sectionIndex}`}>
                <span className="text-[#005F74] !font-['Open Sans'] !text-[14px] ">
                  {`${section?.SectionName}`?.replaceAll("_", " ")}
                </span>
  
                <div className="ml-8 !mt-[10px]">
                  {section?.Summary?.map((item, summaryIndex) => (
                    <p
                      className="text-[#000000] !font-['Open Sans'] !text-[12px] text-justify !leading-5"
                      key={`${section.SectionName}-${summaryIndex}`}
                    >
                      - {item?.SummarizedSegment}
                    </p>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      );
    };
    const handleSaveNoteButton = async () => {
      setIsActiveSaveButton(true);
      modifiedJson.ClinicalDocumentation.Sections.forEach((item, index) => {
        for (let i = item?.Summary?.length - 1; i >= 0; i--) {
          if (item?.Summary?.[i].SummarizedSegment.trim() === "") {
            item.Summary.splice(i, 1);
          }
        }
      });
      setUpdateModifyClinicalNoteLoading(true);
  
      let payload = {
        UserId: loggeduser?.userId,
        modifiedClinicalNote: `${JSON.stringify(modifiedJson)}`,
        editedBy: loggeduser?.userId,
        Id: selectedNote?.id,
        Version: `V${clinicalNoteVersionList?.length}`,
      };
  
      await store
        .UpdateClinicalNotes(DataDecode.encryptPayload(payload))
        .then((response) => {
          if (response?.status === 200) {
            ContentEdit.current = false;
            handleDefaultData();
            dispatch(
              setNotification({
                message: "Clinicalnotes updated successfully",
                status: "success",
                action: true,
              })
            );
            setIsActiveSaveButton(false);
           window.location.reload();
          } else {
            dispatch(
              setNotification({
                message: "Clinical note modification failed",
                status: "error",
                action: true,
              })
            );
            setUpdateModifyClinicalNoteLoading(false);
          }
        })
        .catch((e) => {
          setUpdateModifyClinicalNoteLoading(false);
        });
    };
    const updateJSON = (key, value) => {
      if (key?.startsWith("summary") && (value === " " || value === "")) {
        let [sectionText, sectionIdx, summaryIdx] = key?.split("-");
        let indexToInsert = Number(summaryIdx) - 1;
        let tempArr = JSON.parse(JSON.stringify(modifiedJson));
  
        tempArr?.ClinicalDocumentation.Sections.forEach((item, index) => {
          if (index == sectionIdx) {
            item.Summary.forEach((ele, innerIndex) => {
              if (innerIndex == summaryIdx && item.Summary.length > 1) {
                item.Summary.splice(innerIndex, 1);
                focusElementById(`${sectionText}-${sectionIdx}-${indexToInsert}`);
              } else if (item.Summary.length === 1) {
                item.Summary[0].SummarizedSegment = " ";
              }
            });
          }
        });
        setIsActiveSaveButton(true);
        setModifiedJson(tempArr);
        setOriginalJsonBeforeEdit(tempArr?.ClinicalDocumentation);
      } else if (key?.startsWith("section")) {
        let sectionIndex = key?.split("-")[1];
  
        setModifiedJson((prevData) => {
          const { ClinicalDocumentation } = prevData;
          const { Sections } = ClinicalDocumentation;
  
          const updatedSections = Sections.map((section, index) => {
            if (index == sectionIndex) {
              return {
                ...section,
                SectionName: value?.split(" ")?.join("_"),
              };
            }
            return section;
          });
  
          return {
            ...prevData,
            ClinicalDocumentation: {
              ...ClinicalDocumentation,
              Sections: updatedSections,
            },
          };
        });
      } else if (key?.startsWith("summary")) {
        let [sectionText, sectionIdx, summaryIdx] = key?.split("-");
  
        setModifiedJson((prevData) => {
          const { ClinicalDocumentation } = prevData;
          const { Sections } = ClinicalDocumentation;
  
          const updatedSections = Sections.map((section, index) => {
            if (index == sectionIdx) {
              return {
                ...section,
                Summary: section.Summary.map((summaryItem, summIndex) => {
                  if (summIndex == summaryIdx) {
                    return {
                      ...summaryItem,
                      SummarizedSegment: value,
                    };
                  }
                  return summaryItem;
                }),
              };
            }
            return section;
          });
  
          setIsActiveSaveButton(true);
  
          return {
            ...prevData,
            ClinicalDocumentation: {
              ...ClinicalDocumentation,
              Sections: updatedSections,
            },
          };
        });
      }
    };
    const handleDoneEditing = () => {
        setEditNotes(false);
    };
    const handleDefaultData = () => {
      let transJson = [];
      let content;
      if (!!selectedNote) {
        var data =
          selectedNote?.clinicalNotesRes.forEach((obj, index, array) => {
            if (obj.versionStatus !== null) {
              transJson.push(obj.versionStatus);
            }
            if (index === array.length - 1) {
              content = obj.listOfClinicalNote;
              UpdateClinicalNoteVersion(obj.versionStatus);
              setLatestNote(true);
            }
          }) || {};
        let latestVersionData = transJson;
        let latestVersion = clinicalNoteVersion;
        let versionsList = Object.values(transJson);
        const clinicalDocumentString = content;
        let clinicalDocumentParsed = [];
        let ClnicalDocuments;
        try {
          if (clinicalDocumentString != "Empty") {
            clinicalDocumentParsed = JSON.parse(clinicalDocumentString);
            let data = clinicalDocumentParsed.reduce((acc, item) => {
              acc[item?.Version] = {
                Version: item?.Version,
                EditedBy: item?.EditedBy,
                Content: item?.Content
                  ? typeof item.Content === "string"
                    ? JSON.parse(item.Content)
                    : item.Content
                  : {},
              };
              return acc;
            }, {});
            let dataObj = data?.Original;
            ClnicalDocuments = dataObj.Content.ClinicalDocumentation;
          } else {
            ClnicalDocuments = [];
          }
        } catch (error) {
          ClnicalDocuments = clinicalDocumentParsed?.ClinicalDocumentation;
        }
        setActiveContent(ClnicalDocuments);
        setTranscriptJson(ClnicalDocuments);
        updateClinicalNoteVersionList(versionsList);
      }
    };
    useEffect(() => {
      if (!!selectedNote) {
        handleDefaultData();
        var content =
          selectedNote?.clinicalNotesRes.forEach((obj, index, array) => {
            if (index === array.length - 1) {
              content = obj.listOfClinicalNote;
              UpdateClinicalNoteVersion(obj.versionStatus);
              setLatestNote(true);
            }
          }) || {};
      }
    }, [selectedNote]);
    const handleCloseEditFunctionality = () => {
      ContentEdit.current = false;
    };
    const handleCloseAudioFiles = () => {
  
      setShowAudioFilesData(false);
      setShowOnlyClinicalNote(true)
    setShowOnlyAudioNote(false)
    };
    const handleVersionDropdown = (selectedVersion) => {
      UpdateClinicalNoteVersion(selectedVersion);
      let content;
      if (!!selectedNote) {
        content =
          selectedNote?.clinicalNotesRes.find(
            (obj) => obj.versionStatus === selectedVersion
          )?.listOfClinicalNote || {};
        const clinicalDocumentString = content;
        let clinicalDocumentParsed = [];
        let ClnicalDocuments;
  
        try {
          if (clinicalDocumentString != "Empty") {
            clinicalDocumentParsed = JSON.parse(clinicalDocumentString);
            let data = clinicalDocumentParsed.reduce((acc, item) => {
              acc[item?.Version] = {
                Version: item?.Version,
                EditedBy: item?.EditedBy,
                Content: item?.Content
                  ? typeof item.Content === "string"
                    ? JSON.parse(item.Content)
                    : item.Content
                  : {},
              };
  
              return acc;
            }, {});
  
            let dataObj = data?.Original;
  
            ClnicalDocuments = dataObj.Content.ClinicalDocumentation;
          } else {
            ClnicalDocuments = [];
          }
        } catch (error) {
          try {
            ClnicalDocuments = clinicalDocumentParsed?.ClinicalDocumentation;
          } catch (error) {
            dispatch(
              setNotification({
                message: "Error While opening ClinicalNotes",
                status: "error",
                action: true,
              })
            );
          }
        }
        setActiveContent(ClnicalDocuments);
  
        setTranscriptJson(ClnicalDocuments);
      }
    };
    const addNewLineIndex = (currentKey) => {
      let [sectionText, sectionIdx, summaryIdx] = currentKey?.split("-");
      let indexToInsert = Number(summaryIdx) + 1;
  
      setModifiedJson((prevData) => {
        const { ClinicalDocumentation } = prevData;
        const { Sections } = ClinicalDocumentation;
        let modifiedSections = Sections;
  
        const updatedSections = modifiedSections.map((section, index) => {
          if (index == sectionIdx) {
            let tempSummary = section.Summary;
            let modifiedSummary = [
              ...tempSummary.slice(0, indexToInsert),
              {
                SummarizedSegment: " ",
              },
              ...tempSummary.slice(indexToInsert),
            ];
            return {
              ...section,
              Summary: modifiedSummary,
            };
          }
  
          return section;
        });
  
        setOriginalJsonBeforeEdit({
          ...ClinicalDocumentation,
          Sections: updatedSections,
        });
  
        return {
          ...prevData,
          ClinicalDocumentation: {
            ...ClinicalDocumentation,
            Sections: updatedSections,
          },
        };
      });
    };
    const handleEditContentButton = (e, data) => {
      e.preventDefault();
      e.stopPropagation();
      let content =
        selectedNote?.clinicalNotesRes.find(
          (obj) => obj.versionStatus === clinicalNoteVersion
        )?.listOfClinicalNote || {};
  
      let clinicalDocumentParsed = [];
      let ClnicalDocuments;
      const clinicalDocumentString = JSON.parse(content);
      if (clinicalDocumentString?.ClinicalDocumentation?.Sections.length >= 0) {
        ClnicalDocuments = clinicalDocumentString?.ClinicalDocumentation;
      } else {
        try {
          if (
            clinicalDocumentString != "Empty" ||
            clinicalDocumentString?.ClinicalDocumentation
          ) {
            clinicalDocumentParsed = clinicalDocumentString[0].Content;
            const parsedContent = JSON.parse(clinicalDocumentString[0].Content);
  
            // Step 2: Access specific sections
            const sections = parsedContent.ClinicalDocumentation;
  
            ClnicalDocuments = sections;
          } else {
            ClnicalDocuments = [];
          }
        } catch (error) {}
      }
  
      let arrString = ClnicalDocuments;
      let modifiedArr = arrString?.Sections?.map((section, sectionIndex) => {
        if (section.Summary.length === 0) {
          return { ...section, Summary: [{ SummarizedSegment: " " }] };
        }
        return section;
      });
     
      let modifiedObject = { Sections: modifiedArr };
      setOriginalJsonBeforeEdit(modifiedObject);
  
      setModifiedJson({ ClinicalDocumentation: modifiedObject });
      ContentEdit.current = true;
    };
    const handleCancelEditButton = () => {
      ContentEdit.current = false;
      setModifiedJson({});
    };
    const compareJson = () => {
      if (Object.keys(modifiedJson).length) {
        return (
          JSON.stringify(transcriptJson) ===
          JSON.stringify(modifiedJson?.ClinicalDocumentation)
        );
      }
  
      return false;
    };
    const handleRowClick = (note) => {
      setSelectedNote(note);
      setShowOnlyClinicalNote(true)
      setShowOnlyAudioNote(false)
      setSelectedFilTemplate(note.id
        );
    };
    let filteredSelFiles = null;

    if (Array.isArray(selectedNote)) {
      filteredSelFiles = selectedNote.find((file) => file.id
      === Number(selectedFilTemplate));
    } else if (selectedNote?.id
      === Number(selectedFilTemplate)) {
      filteredSelFiles = selectedNote;
    }
    const formatToIST = (dateString) => {
      const utcDate = new Date(dateString);
      const istDate = new Date(utcDate.getTime() + 330 * 60 * 1000);
      const timeString =  istDate.toLocaleTimeString('en-IN', {
        hour: '2-digit',
        minute: '2-digit',
       hour12: true
      });
      return timeString.replace('am', 'AM').replace('pm', 'PM');
    };
    const copyToClipBord = async (e) => {
      e.preventDefault();
      e.stopPropagation();
      const clinicalDocumentString = transcriptJson;
       let patientNameArray = "";
      if (!appointment?.patient_name) {
        const exportData = ReactDOMServer.renderToString(
          <HtmlSTringForPDF
            jsonString={clinicalDocumentString}
            patientName_={
              appointment?.patient_name != null
                ? appointment?.patient_name.join(" ")
                : ""
            }
          />
        );
        const tempElement = document.createElement("div");
        tempElement.innerHTML = exportData;
  
        const formattedText = traverseAndFormatContent(tempElement);
  
        try {
          await navigator.clipboard.writeText(formattedText);
          dispatch(
            setNotification({
              message: "Copied Clinical Notes",
              status: "success",
              action: true,
            })
          );
        } catch (err) {
          dispatch(
            setNotification({
              message: "Failed to copy content",
              status: "error",
              action: true,
            })
          );
        }
      }
    };
    const handleDownloadPDF = async () => {
      const content = document.getElementById("clinical-notes-content");
  
      html2canvas(content, { scale: 2 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = pdfWidth;
        const imgHeight = (canvas.height * pdfWidth) / canvas.width;
  
        let heightLeft = imgHeight;
        let position = 0;
  
        // Add the first page
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
  
        // Add additional pages if content extends beyond one page
        while (heightLeft > 0) {
          position -= pageHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
  
        pdf.save("clinical_notes.pdf");
      });
    };
    const adjustTextareaHeight = () => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = Math.min(textarea.scrollHeight, 500) + 'px';
        }
    };

  const GetAllPatientDrNotes = () => {
       
            setDataLoading(true);
        
        store.GetAllPatientDrNotes(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, 'patient_id': history.patient_id,
                 'appointment_id': history.appointment_id }))
            .then((result) => {
                setDataLoading(false);
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        dispatch(setDoctorNotes(JSON.parse(response)));
                    })
                } else {
                    dispatch(setDoctorNotes([]))
                }
            })
            .catch(err => {
                setDataLoading(false);
               
                dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
            })
    }
    useEffect(() => {
        GetAllPatientDrNotes();
    }, []);

    const saveNotes = () => {
        if (drNotes.trim() === "") {
            dispatch(setNotification({ message: "Please enter Notes!", status: 'error', action: true }));
        } else {
            setLoading(true);

            store.updateDoctorNotes(loggedusertoken, DataDecode.encryptPayload({
                appointment_id: history.appointment_id,
                clinic_guid: loggeduser.facilityID,
                notes: drNotes,
                type: "notes",
                history: history
            }))
                .then((result) => {
                    setLoading(false);
                    setIsAnyChanges(false);


                    if (result.status === 204) {
                        setDrNotes(drNotes);
                        handleDoneEditing();
                        store.CreateActivityLogs(loggeduser, `${patientProfileMenuSelector === "appointment" ? "Appointment to " : "Patient to " }Patient Profile`, `Doctor Notes Updated - ${(history?.doctor_name ? "for " + history?.doctor_name?.trim():"")} with patient ${patient.FirstName} ${patient.MiddleName?.charAt(0)} ${patient.LastName}(${patient?.patient_op_number}) on ${DateFunction.GetDate(history?.appointment_time)} ${(history?.app_time ? " at " + DateFunction.Convert12Hour(history?.app_time) : "")}`,"Update");
                        dispatch(setNotification({ message: "Notes Updated!", status: 'success', action: true }));
                    } else {

                        dispatch(setNotification({ message: `Unexpected error: ${result.status}`, status: 'error', action: true }));
                    }
                })
                .catch((err) => {
                    setLoading(false);

                    dispatch(setNotification({ message: 'Error occurred during notes update.', status: 'error', action: true }));
                });
        }
    };

    const handlePlay = (file) => {
      if (currentPlaying === file) {
         setCurrentPlaying(null);
      } else {
        setCurrentPlaying(file);
      }
    };
    const traverseAndFormatContent = (element) => {
      let result = "";
  
      for (let node of element.childNodes) {
        if (node.nodeType === Node.TEXT_NODE) {
          result += node.textContent.trim() + "\n";
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          if (node.tagName === "DIV" || node.tagName === "P") {
            result += "\n" + traverseAndFormatContent(node) + "\n";
          } else if (node.tagName === "SPAN" || node.tagName === "H3") {
            result += node.textContent.trim() + "\n";
          } else {
            result += traverseAndFormatContent(node);
          }
        }
      }
  
      return result.trim();
    };
  const handleClinicalNotes=(div)=>{
    setShowAudioFilesData(true);
    setShowClinicalNotes(true);
    setShowOnlyAudioNote(true)
    setShowOnlyClinicalNote(false)
    }
    const handleAudioFIles=(div)=>{
        scrollToDiv(div);
        setShowClinicalNotes(false)
    }
    const scrollToDiv = (divId) => {
      const targetDiv = document.getElementById(divId);
      if (targetDiv) {
        targetDiv.scrollIntoView({ behavior: "smooth" });
      }
    };
    const handleFetchTranscriptionFiles=async()=>{
      let data={PatientId: history.patient_id,
       Id :history.appointment_id
     }
     await store.GetTranscriptionFiles(DataDecode.encryptPayload(data))
     .then((result)=>{
        if(result.status==200){
         result.json().then((res) => {
          let audioData=res;
           setAudioFiles(audioData);
        }, {});
       
       }
       else{
         dispatch(setNotification({ message: 'Error while fetching Audio Files', status: 'error', action: true }));
       }
     }) .catch(err => {
               setDataLoading(false);
                dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
           });
   };
    useEffect(() => {
        if (isAnyChanges && drNotes.trim() !== "") {
            saveNotes();
        }
    }, [autoSaveAll, drNotes]);

    useEffect(() => {
      if(history.appointment_id!=null){
        handleFetchTranscriptionFiles();

    }
        setDrNotes(history?.notes || '');
    }, [history]);
    useEffect(() => {
     
        adjustTextareaHeight();
    }, [drNotes]);
    const deleteNotesFile = () => {
        setLoadingDeleteImage(true);
        store.DeletePatientFile(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, 'patient_id': history.patient_id, files: notesFileDeleteID }))
            .then((result) => {
                setLoadingDeleteImage(false)
                setNotesFileDeleteCnfBox(false)
                if (result.status == 204) {
                    dispatch(setRefreshNotes(!refreshNotes))
                    store.CreateActivityLogs(loggeduser, `${patientProfileMenuSelector === "appointment" ? "Appointment to " : "Patient to " }Patient Profile`, `Doctor Notes File Deleted - ${(history?.doctor_name ? "for " + history?.doctor_name?.trim():"")} with patient ${patient.FirstName} ${patient.MiddleName?.charAt(0)} ${patient.LastName}(${patient?.patient_op_number}) on ${DateFunction.GetDate(history?.appointment_time)} ${(history?.app_time ? " at " + DateFunction.Convert12Hour(history?.app_time) : "")}`,"Delete");
                    dispatch(setNotification({ message: 'Deleted Successfully!', status: 'success', action: true }));
                }
                else {
                    dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                }

            })
            .catch((error) => {
                dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
            });
    }
    const GetPatientFiles = () => {
        store.GetPatientFiles(loggedusertoken, DataDecode.encryptPayload({
            'clinic_guid': loggeduser.facilityID,
            'patient_id': history.patient_id,
            'appointment_id': history.appointment_id
        }))
            .then((result) => {
                if (result.status === 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        let data = JSON.parse(response);

                        setFiles([...data, ...(history.PatientFiles || [])]);
                        setOriginalFiles([...data, ...(history.PatientFiles || [])]);

                    });
                } else {
                    setFiles(history.PatientFiles || []);
                    setOriginalFiles(history.PatientFiles || []);

                }
            })
            .catch(err => {
                setFiles(history.PatientFiles || []);
                setOriginalFiles(history.PatientFiles || []);
                dispatch(setNotification({ message: 'Error Occurred', status: 'error', action: true }));
            });
    };
    const GetPatientNotesFile = () => {
        store.GetPatientFiles(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, 'patient_id': history.patient_id, 'appointment_id': history.appointment_id }))
            .then((result) => {
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        setFiles(JSON.parse(response));
                    })
                } else {
                    setFiles([])
                }
            })
            .catch(err => {
                setFiles([])
                dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
            })
    }
    useEffect(() => {
        GetPatientNotesFile();
    }, [refreshNotes])

    useEffect(() => {
        if (loggeduser.facilityID && history.appointment_id) {
            GetPatientFiles(loggeduser.facilityID);
        }
    }, [loggeduser.facilityID, history.appointment_id, refreshImage, refreshUploadedFiles]);
    const handleUploadButton = (type) => {
        dispatch(setShowDocumentUploadPopup(true))
        dispatch(setIsCameraUpload(type === "capture" ? true : false));
    }

    useEffect(() => {
        if (showDocumentUploadPopup) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [showDocumentUploadPopup]);
    useEffect(() => {
        const textarea = textareaRef.current;
        if(textarea){
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 'px';
        }
    }, [drNotes]);
    return (
      <>
      {notesFileDeleteCnfBox &&

          <div id="delete" tabIndex="-1" aria-hidden="true" className="fixed z-[9999] lg:left-[40%] lg:w-80 top-[10rem] lg:top-[3rem] lg:translate-x-[50%] translate-y-[50%]">

              <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                  <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                      <svg id="DoctorNotesSvgconfirmBoxIcon" className="text-red-600 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>

                      <p id="DoctorNotesconfirmBoxMessage" className={`mb-4 `} style={{ fontFamily: previewTheme.fontFamily }}>Are you sure you want to delete this file?</p>
                      <div className=" flex justify-center items-center space-x-4">

                          <button id="btnDoctorNotesCancelCfmdDeleteFile" onClick={() => setNotesFileDeleteCnfBox(false)} disabled={loadingDeleteImage} type="button" className={`py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10  ease-in-out
            ${previewTheme.cancelBtnColor} 
            ${previewTheme.cancelBtnBgColor} 
            ${loadingDeleteImage ? "opacity-50 cursor-not-allowed" : `hover:${previewTheme.cancelBtnHoverColor}`}
            `}>
                              No, cancel
                          </button>
                          <button id="btnDoctorNotesYesCfmdDeleteFile" type="button" onClick={deleteNotesFile} disabled={loadingDeleteImage} className={`py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10  ease-in-out
                ${previewTheme.saveBtnColor} 
                ${previewTheme.saveBtnBgColor} 
                ${loadingDeleteImage ? "opacity-50 cursor-not-allowed" : `hover:${previewTheme.saveBtnHoverColor}`}`}
                          >
                              Yes, I'm sure {loadingDeleteImage &&
                                  <svg aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                  </svg>
                              }
                          </button>
                      </div>
                  </div>
              </div>
          </div>
      }
     <div className=''>
          <div className="bg-gray-100 font-semibold justify-center  flex items-center py-1 mt-4">


              <div>
                  <div  className={`font-bold text-xl  text-center ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewTheme.fontFamily }}>
                      Doctor Notes

                  </div>

              </div>

              <div className='flex items-center absolute right-8 '>
                 </div>
          </div>

              <div>  
                 {isAiscriptEnable &&loggeduser?.clinicalNoteModelType === "AiScript" && (
                <div
                
                >
                  
                  {!showAudioFiles &&
                    loggeduser?.clinicalNoteModelType === "AiScript" && (
                      <>
                      {audioFiles.length > 0 && (
                      <div className="pt-[0.5rem]  bg-gray-100">
                      <div className="">
                            <div
                              className={` bg-[#FFFFFF] ${
                                selectedNote ? "w-1/2" : "w-full"
                              }`}
                            >
                      
                              {!selectedNote && (
                                <table className="min-w-full bg-white border rounded-lg shadow-sm">
                                  <thead>
                                    <tr className="bg-red-800 text-white h-[4.65rem]">
                                      <th className="py-3 px-4 text-left font-medium">
                                        Visit/Upload Details
                                      </th>
                                      {selectedNote && !showAudioFilesData && (
                                        <th className="py-3 px-4 text-left font-medium">
                                          Type
                                        </th>
                                      )}
                                      <th className="py-3 px-4 text-left font-medium">
                                        Last Modified by
                                      </th>
                                      {!selectedNote && !showAudioFilesData && (
                                        <th className="py-3 px-4 text-left font-medium">
                                          Doctor
                                        </th>
                                      )}
                                      {!selectedNote && !showAudioFilesData && (
                                        <th className="py-3 px-4 text-left font-medium">Type</th>
                                      )}
                                      {!selectedNote && !showAudioFilesData && (
                                        <th className="py-3 px-4 text-left font-medium">
                                      
                                        </th>
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {audioFiles.map((note, index) => {
                                      const isModifiedTime = formatToIST(note.lastModifiedDate);
                                      const istTimeString = formatToIST(note.creationTime);
                                     
                                      return(
                                      <tr
                                        key={index}
                                        onClick={() => handleRowClick(note)}
                                        className="hover:bg-gray-100 border-b last:border-b-0 cursor-pointer"
                                      >
                                        <td className="py-3 px-4">
                                          <span className="text-black font-normal ml-2">
                                        
                                            {`${new Date(
                                              note.creationTime
                                            ).toLocaleDateString("en-US", {
                                              month: "short",
                                              day: "2-digit",
                                              year: "numeric",
                                            })} `}
                                          </span>
                                          <br />
                                          <span className="text-[#8C8C8C] font-normal ml-2">
                                          {istTimeString}
                                           
                                          </span>
                                        </td>
                                        {selectedNote &&
                                          !showAudioFilesData && (
                                            <td className="py-3 px-4">
                                              {note.type}
                                            </td>
                                          )}
                                        <td className="py-3 px-4">
                                          {note.lastModifiedBy} <br />
                                          <span className="text-[#8C8C8C] font-normal">
                                          {`${new Date(
                                              note.lastModifiedDate
                                            ).toLocaleDateString("en-US", {
                                              month: "short",
                                              day: "2-digit",
                                              year: "numeric",
                                            })} `}
                                           
                                          {isModifiedTime}
                                            
                                          </span>
                                        </td>
                                        {!selectedNote &&
                                          !showAudioFilesData && (
                                            <td className="py-3 px-4">
                                            {history.doctor_name}
                                              {/* {appointment.doctor_name} */}
                                            </td>
                                          )}
                                        {!selectedNote &&
                                          !showAudioFilesData && (
                                            <td className="py-3 px-4">
                                              {"AI Scribe"}
                                            </td>
                                          )}

                                        {!selectedNote &&
                                          !showAudioFilesData && (
                                            <td className="py-3 px-4">
                                              <button className="text-gray-500 hover:text-gray-700">
                                               
                                              </button>
                                            </td>
                                          )}
                                      </tr>
                              )})}
                                  </tbody>
                                </table>
                              )}

                             
                             
                              </div>
                             
                              
                              <div className="grid grid-cols-2 !overflow-hidden">
  {!showAudioFilesData  ? (
    <>
   <div> {!showAudioFilesData && selectedNote && (
                                <table className=" min-w-full bg-white border rounded-lg shadow-sm">
                                  <thead>
                                    <tr className="bg-red-800 text-white h-[4.65rem] ">
                                      <th className="py-3 md:text-[14px] lg:text-[14px] xl:text-[16px] px-4 text-left font-medium">
                                        Visit/Upload Details
                                      </th>
                                      {selectedNote && !showAudioFilesData && (
                                        <th className="py-3 px-4 md:text-[14px] lg:text-[14px] xl:text-[16px] text-left font-medium">
                                          Type
                                        </th>
                                      )}
                                      <th className="py-3 md:text-[14px] lg:text-[14px] xl:text-[16px] px-4 text-left font-medium">
                                        Last Modified by
                                      </th>
                                      {!selectedNote && !showAudioFilesData && (
                                        <th className="py-3 md:text-[14px] lg:text-[14px] xl:text-[16px] px-4 text-left font-medium">
                                          Provider
                                        </th>
                                      )}
                                      {!selectedNote && !showAudioFilesData && (
                                        <th className="py-3 md:text-[14px] lg:text-[14px] xl:text-[16px] px-4 text-left font-medium"></th>
                                      )}
                                      {!selectedNote && !showAudioFilesData && (
                                        <th className="py-3 md:text-[14px] lg:text-[14px] xl:text-[16px] px-4 text-left font-medium">
                                        
                                        </th>
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {audioFiles.map((note, index) => {
                                     
                                      const istTimeString = formatToIST(note.creationTime);
                                     
                                      return(
                                      <tr
                                      id={index}
                                        key={index}
                                        onClick={() => handleRowClick(note)}
                                        className="hover:bg-gray-100 border-b last:border-b-0 cursor-pointer"
                                      >
                                        <td className="py-3 px-4">
                                          <span className="text-black font-normal md:text-[14px] lg:text-[14px] xl:text-[16px] md:ml-0 lg:ml-0 xl:ml-2">
                                            {`${new Date(
                                              note.creationTime
                                            ).toLocaleDateString("en-US", {
                                              month: "short",
                                              day: "2-digit",
                                              year: "numeric",
                                            })} `}
                                          </span>
                                          <br />
                                          <span className="text-[#8C8C8C] md:text-[14px] lg:text-[14px] xl:text-[16px] font-normal md:ml-0 lg:ml-0 xl:ml-2">
                                           {istTimeString}
                                          </span>
                                        </td>
                                        {selectedNote &&
                                          !showAudioFilesData && (
                                            <td className="py-3 md:text-[14px] lg:text-[14px] xl:text-[16px] px-4">
                                            {"AI Scribe"}
                                            </td>
                                          )}
                                        <td className="py-3 px-4 md:text-[14px] lg:text-[14px] xl:text-[16px]">
                                          {note.lastModifiedBy} <br />
                                          <span className="text-sm md:text-[14px] lg:text-[14px] xl:text-[16px] text-gray-500">
                                            {note.modifiedDate}{" "}
                                            {note.modifiedTime}
                                          </span>
                                        </td>
                                        {!selectedNote &&
                                          !showAudioFilesData && (
                                            <td className="py-3 md:text-[14px] lg:text-[14px] xl:text-[16px] px-4">
                                              {note.provider}
                                            </td>
                                          )}
                                        {!selectedNote &&
                                          !showAudioFilesData && (
                                            <td className="py-3 md:text-[14px] lg:text-[14px] xl:text-[16px] px-4">
                                              {note.template}
                                            </td>
                                          )}

                                        {!selectedNote &&
                                          !showAudioFilesData && (
                                            <td className="py-3 px-4">
                                              <button className="text-gray-500 hover:text-gray-700">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  className="h-6 w-6"
                                                  fill="none"
                                                  viewBox="0 0 24 24"
                                                  stroke="currentColor"
                                                >
                                                  <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                    d="M12 6v.01M12 12v.01M12 18v.01"
                                                  />
                                                </svg>
                                              </button>
                                            </td>
                                          )}
                                      </tr>
                              )})}
                                  </tbody>
                                </table>
                              )}</div>
      <div>  {selectedNote && (
                              <div className="w-auto border bg-[#FFFFFF] border-gray-300 rounded-lg shadow-md font-sans">
                                {/* Header Section */}
                                <div className="!bg-[#D9D9D9] flex justify-between items-center bg-gray-100 p-3 border-b border-gray-300 h-[4.65rem]">
                                  <div>
                                    <div className="flex items-center space-x-2">
                                      <h3 className=" text-xl font-bold text-[#A31B1B]">
                                        Clinical Notes
                                      </h3>
                                      <p className="text-sm text-gray-600 pt-[3px]">
                                      {history.doctor_name}
                                      </p>
                                    </div>
                                   
                                  </div>
                                  <div className="flex space-x-2">
                                  {!ContentEdit.current && (
                                    <div className="flex gap-[4px]">
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="AddImageIcon"
                                       
                                      />
                                    </button>
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="CopyImageIcon"
                                        onClick={(e) => {
                                        copyToClipBord(e);
                                       }}
                                        src={copyImage}
                                      />
                                    </button>
                                    {clinicalNoteVersionList.length > 1 && (
                            <Select
                              labelId="audio-transcipt-clinical-note-version-select"
                              id="audio-transcipt-clinical-note-version-select"
                              value={
                                latestNote ? clinicalNoteVersion : latestVersion
                              }
                              label=""
                              onChange={(e) => {
                                setLatestNote(true);
                                handleVersionDropdown(e?.target?.value);
                              }}
                              size="small"
                              style={{
                                height: 30,
                                backgroundColor: "#fff",
                                marginTop: "3px",
                              }}
                              inputProps={{
                                sx: {
                                  fontWeight: "500",
                                  maxHeight: "50px",
                                },
                                "data-testid":
                                  "audio-transcipt-clinical-note-version-types",
                              }}
                              disabled={!clinicalNoteVersionList}
                            >
                              {clinicalNoteVersionList?.map((version) => (
                                <MenuItem
                                  value={version}
                                  key={version}
                                  id={version}
                                >
                                  {version}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="EditImageIcon"
                                        onClick={(e) => {
                              handleEditContentButton(e, selectedNote);
                            }}
                                        src={editImage}
                                      />
                                    </button>
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="DownloadImageIcon"
                                        onClick={handleDownloadPDF}
                                      
                                        src={downloadImage}
                                      />
                                    </button>
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="CancelImageIcon"
                                        onClick={handleCloseClinicalNotes}
                                     
                                        src={cancelImage}
                                      />
                                    </button>
                                    </div>
                            )}
                            {ContentEdit.current && (
                        <div className="flex justify-between items-end space-x-2">
                          <div>
                            <button
                            id="BtnNoteSave"
                              onClick={handleSaveNoteButton}
                              disabled={
                                modifyClinicalNoteLoading ||
                                 compareJson()
                              }
                              className={
                                isActiveSaveButton
                                  ? "bg-[#b81f1f] text-[#f6f3f3] border-red-700 hover:bg-red-500 w-32 active:bg-white-700 font-medium py-2 px-4 h-100% w-100% rounded transition duration-300 ease-in-out rounded-xl"
                                  : "bg-[#cbc0c0] text-[#8e7979] border-red-700 w-32 active:bg-white-700 font-medium py-2 px-4 h-100% w-100% rounded transition duration-300 ease-in-out rounded-xl"
                              }
                            >
                              Save
                            </button>{" "}
                          </div>
                          <div>
                            <button
                            id="BtnCanEdit"
                              onClick={handleCancelEditButton}
                              className="bg-[#fffefe] text-[#e26666] border-red-700 hover:bg-[#e8d2d2] w-32 font-medium py-2 px-4 rounded transition duration-300 ease-in-out rounded-xl"
                            >
                              Cancel
                            </button>{" "}
                          </div>
                          <div>
                            <button className="text-gray-600 hover:text-gray-800 text-lg">
                              <img
                                id="AudiohandleCloseEditFunctionality"
                                onClick={handleCloseEditFunctionality}
                               
                                src={cancelImage}
                              />
                            </button>
                          </div>
                        </div>
                      )}
                                  </div>
                                </div>

                              

                                {/* Patient Info */}

                                <div
                                  style={{ display: "-webkit-inline-box" }}
                                  
                                >
                                  {!showAudioFilesData && (
                                    <div className="mt-[16rem]">
                                      <button
                                        id="AudioFilesBtn"
                                       onClick={() => {
                                          handleClinicalNotes(
                                            "showClinicalNotes_Id"
                                          );
                                        }}
                                      >
                                     
                                        <img
                                          id="AudioEditIcon"
                                          className={`size-auto mb-1 mr-2 inline cursor-pointer text-red-700`}
                                          src={AudioFiles}
                                        />
                                      </button>
                                    </div>
                                  )}

                                  <div id="clinical-notes-content">
                                    <div
                                      className={` ${
                                        !showAudioFilesData
                                          ? "ml-[1rem]"
                                          : "pl-[1rem]"
                                      } `}
                                    >
                                     
                                      <div>
                                        <ul className="list-disc list-inside   mt-2">
                                          <div className=" mb-4">
                                            {isAiscriptEnable && (
                                              <div
                                                id={"showClinicalNotes_Id"}
                                                className=" flex justify-between hidden sm:inline grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2"
                                              >
                                                <div className="col-span-9 justify-self-start ">
                                                  {!ContentEdit.current && audioFiles &&
                                                  audioFiles.length > 0 ? (
                                                    <>
                                                      {audioFiles?.slice(0,1)?.map(
                                                        (file, index) => {
                                                          if (
                                                            file?.listOfClinicalNote !=
                                                            "Empty"
                                                          ) {
                                                            const clinicalDocumentString =
                                                              file?.listOfClinicalNote;
                                                            let clinicalDocumentParsed =
                                                              [];
                                                            let ClnicalDocuments;

                                                            try {
                                                              if (
                                                                clinicalDocumentString !=
                                                                "Empty"
                                                              ) {
                                                                clinicalDocumentParsed =
                                                                  JSON.parse(
                                                                    clinicalDocumentString
                                                                  );
                                                                let data =
                                                                  clinicalDocumentParsed.reduce(
                                                                    (
                                                                      acc,
                                                                      item
                                                                    ) => {
                                                                      acc[
                                                                        item?.Version
                                                                      ] = {
                                                                        Version:
                                                                          item?.Version,
                                                                        EditedBy:
                                                                          item?.EditedBy,
                                                                        Content:
                                                                          item?.Content
                                                                            ? JSON?.parse(
                                                                                JSON.parse(
                                                                                  item?.Content
                                                                                )?.[0]
                                                                                  ?.Content
                                                                              )
                                                                            : {},
                                                                      };

                                                                      return acc;
                                                                    },
                                                                    {}
                                                                  );
                                                                let dataObj =
                                                                  data?.Original;

                                                                ClnicalDocuments =
                                                                  dataObj
                                                                    .Content
                                                                    .ClinicalDocumentation;
                                                              } else {
                                                                ClnicalDocuments =
                                                                  [];
                                                              }
                                                            } catch (error) {
                                                             
                                                            }

                                                            const date =
                                                              new Date(
                                                                file?.creationTime
                                                              );

                                                            const day = String(
                                                              date.getDate()
                                                            ).padStart(2, "0");
                                                            const month =
                                                              String(
                                                                date.getMonth() +
                                                                  1
                                                              ).padStart(
                                                                2,
                                                                "0"
                                                              );
                                                            const year = String(
                                                              date.getFullYear()
                                                            ).slice(-2);
                                                            return (
                                                              <div className="content-container pt-2 pb-2 pr-4">
                                                                <ul>
                                                                  <li className=" md:text-[14px] lg:text-[14px] xl:text-[16px]"
                                                                    key={index}
                                                                  >
                                                                    <div className="grid grid-row-10 gap-4 pr-[5rem]">
                                                                      
                                                                      {transcriptJson?.Sections?.map(
                                                                        (
                                                                          section,
                                                                          sectionIndex
                                                                        ) => (
                                                                          <div
                                                                            className="py-2 pr-2"
                                                                            key={`section-${sectionIndex}`}
                                                                          >
                                                                            <span
                                                                              className="!font-bold"
                                                                              onInput={(
                                                                                e
                                                                              ) => {
                                                                                e.stopPropagation();
                                                                                e.preventDefault();
                                                                              }}
                                                                              suppressContentEditableWarning={
                                                                                true
                                                                              }
                                                                            >
                                                                              {section?.SectionName.replaceAll(
                                                                                "_",
                                                                                " "
                                                                              )}
                                                                            </span>
                                                                            <ul
                                                                              className="!list-disc ml-8"
                                                                              id="clinical_note_unordered_list"
                                                                            >
                                                                              {section?.Summary?.filter(
                                                                                (
                                                                                  item
                                                                                ) =>
                                                                                  item?.SummarizedSegment
                                                                              )?.map(
                                                                                (
                                                                                  item,
                                                                                  summaryIndex
                                                                                ) => (
                                                                                  <p
                                                                                    key={`${section.SectionName}-${summaryIndex}`}
                                                                                    onInput={(
                                                                                      e
                                                                                    ) => {
                                                                                      const liElement =
                                                                                        e.currentTarget;
                                                                                      if (
                                                                                        !liElement?.textContent
                                                                                      ) {
                                                                                        liElement?.remove();
                                                                                      }
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                      event
                                                                                    ) => {
                                                                                      if (
                                                                                        event.key ===
                                                                                          "Enter" &&
                                                                                        !event.shiftKey
                                                                                      ) {
                                                                                        event.preventDefault();
                                                                                      }
                                                                                    }}
                                                                                    suppressContentEditableWarning={
                                                                                      true
                                                                                    }
                                                                                    // className="focus"
                                                                                    className="relative pl-4 focus"
                                                                                  >
                                                                                    <span className="absolute left-0">
                                                                                      •
                                                                                    </span>
                                                                                    {
                                                                                      item?.SummarizedSegment
                                                                                    }
                                                                                  </p>
                                                                                )
                                                                              )}
                                                                            </ul>
                                                                          </div>
                                                                        )
                                                                      )}
                                                                    </div>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            );
                                                          } else {
                                                            return (
                                                              <ul>
                                                                <li
                                                                  key={index}
                                                                  className="mb-4 flex  text-center"
                                                                >
                                                                  <p className="font-bold">
                                                                    {`Clinical Notes.${
                                                                      index + 1
                                                                    }`}{" "}
                                                                  </p>
                                                                </li>
                                                                <li>
                                                                  {" "}
                                                                  <p
                                                                    id={
                                                                      "emptyErrormsgId"
                                                                    }
                                                                    className="mb-4 flex justify-center items-center text-center"
                                                                  >
                                                                    No Clinical
                                                                    Notes are
                                                                    available.
                                                                  </p>
                                                                </li>
                                                              </ul>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                    </>
                                                  ) : (
                                                   ""
                                                  )}
                                                  {ContentEdit.current &&
                                      originalJsonBeforeEdit?.Sections?.map(
                                        (section, sectionIndex) => {
                                          return (
                                            <div
                                              className="p-2"
                                              key={`section-${sectionIndex}`}
                                            >
                                              <span
                                                className="!font-semibold"
                                                onInput={(e) => {
                                                  e.stopPropagation();
                                                  e.preventDefault();
                                                  updateJSON(
                                                    `section-${sectionIndex}`,
                                                    e.target.innerText
                                                  );
                                                }}
                                                suppressContentEditableWarning={
                                                  true
                                                }
                                              >
                                                {`${section?.SectionName}`?.replaceAll(
                                                  "_",
                                                  " "
                                                )}
                                              </span>
                                              <ul
                                                className="!list-disc ml-8"
                                                id="clinical_note_unordered_list"
                                              >
                                                {section?.Summary?.filter(
                                                  (item) =>
                                                    item?.SummarizedSegment
                                                )?.map((item, summaryIndex) => (
                                                  <li
                                                    contentEditable={
                                                      ContentEdit.current
                                                    }
                                                    id={`summary-${sectionIndex}-${summaryIndex}`}
                                                    key={`${section.SectionName}-${summaryIndex}`}
                                                    onInput={async (e) => {
                                                      let arr =
                                                        modifiedJson
                                                          .ClinicalDocumentation
                                                          .Sections;
                                                      const liElement =
                                                        e.currentTarget;
                                                      if (
                                                        liElement?.innerText ==
                                                        "\n"
                                                      ) {
                                                        await addNewLineIndex(
                                                          `summary-${sectionIndex}-${summaryIndex}`
                                                        );
                                                        focusElementById(
                                                          `summary-${sectionIndex}-${
                                                            summaryIndex + 1
                                                          }`
                                                        );
                                                      } else {
                                                        updateJSON(
                                                          `summary-${sectionIndex}-${summaryIndex}`,
                                                          e?.target?.innerText
                                                        );
                                                      }
                                                    }}
                                                    onKeyDown={async (
                                                      event
                                                    ) => {
                                                      const liElement =
                                                        event.currentTarget;
                                                      if (
                                                        event.key === "Enter" &&
                                                        !event.shiftKey
                                                      ) {
                                                        event.preventDefault();
                                                        await addNewLineIndex(
                                                          `summary-${sectionIndex}-${summaryIndex}`
                                                        );
                                                        focusElementById(
                                                          `summary-${sectionIndex}-${
                                                            summaryIndex + 1
                                                          }`
                                                        );
                                                      }
                                                    }}
                                                    suppressContentEditableWarning={
                                                      true
                                                    }
                                                    className="focus:outline-none"
                                                  >
                                                    {item?.SummarizedSegment}
                                                  </li>
                                                ))}
                                              </ul>
                                            </div>
                                          );
                                        }
                                      )}
                                                </div>
                                                <br />
                                              </div>
                                            )}
                                          </div>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div></div>
                                </div>
                              </div>
                            )}</div>
    </>
  ) : showAudioFilesData ? (
    <>
      <div> {showAudioFilesData && (
                                <div>
                                  {showClinicalNotes && (
                                    <>
                                      <div className="!bg-[#D9D9D9] flex justify-between items-center bg-gray-100 p-3 border-b border-gray-300 h-[4.7rem]">
                                        <div>
                                          <div className="flex items-center space-x-2">
                                            <h3 className=" text-xl font-bold text-[#A31B1B]">
                                              Audio files
                                            </h3>
                                          </div>
                                        </div>
                                        <div>
                                          <button className="text-gray-600 hover:text-gray-800 text-lg">
                                            <img
                                              id="CloseImageIcon"
                                              onClick={handleCloseAudioFiles}
                                              className={`size-5 mb-1 mr-2 inline cursor-pointer`}
                                              src={closeImage}
                                            />
                                          </button>
                                        </div>
                                      </div>
                                      <div className=" grid grid-cols-2 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-1  ">
                                        <div className=" ">
                                        {filteredSelFiles  ? (
  
  (() => {
    const file = filteredSelFiles ;
    

    let ClnicalDocuments;
                                                  const fileExtension = file?.documentUrl
                                                   ? file.documentUrl.split(".").pop().toLowerCase()
                                                   : null;
                                                
                                                  const isAudio = [
                                                    "mp3",
                                                    "wav",
                                                    "ogg",
                                                    "m4a",
                                                  ].includes(fileExtension);
                                                  const mimeTypes = {
                                                    mp3: "audio/mpeg",
                                                    wav: "audio/wav",
                                                    ogg: "audio/ogg",
                                                    m4a: "audio/mp4",
                                                  };
                                                  if (file?.transcript) {
                                                    const date = new Date(
                                                      file?.creationTime
                                                    );
                                                    const day = String(
                                                      date.getDate()
                                                    ).padStart(2, "0");
                                                    const month = String(
                                                      date.getMonth() + 1
                                                    ).padStart(2, "0");
                                                    const year = String(
                                                      date.getFullYear()
                                                    ).slice(-2);


      return (
        <div className="content-container pt-2 pb-2">
          <ul>
            <li>
              <div>
                <div className="p-3 justify-between pb-[0px] flex">
                <h3 className="m-0 p-0 ">
                                                                  <span className="text-[#A31B1B] font-semibold md:text-[14px] lg:text-[14px] xl:text-[16px]">{`Note 1`}</span>
                                                                  <span className="text-black font-normal ml-2 md:text-[14px] lg:text-[14px] xl:text-[16px]">{` (${new Date(
                                                                    file.creationTime
                                                                  ).toLocaleDateString(
                                                                    "en-US",
                                                                    {
                                                                      month:
                                                                        "short",
                                                                      day: "2-digit",
                                                                      year: "numeric",
                                                                    }
                                                                  )})`}</span>
                                                                  
                                                                </h3>
                                                                {file?.transcript !=
                                                                "Empty" ? (
                                                                  <CustomAudioPlayer
                                                                    key={
                                                                      file.id
                                                                    }
                                                                    id="AudioTagId"
                                                                    fileUrl={`${process.env.REACT_APP_EMRAXIS_S3_URL}/${file?.documentUrl}`}
                                                                    isPlaying={
                                                                      currentPlaying ===
                                                                      file
                                                                    }
                                                                    onPlay={() =>
                                                                      setCurrentPlaying(
                                                                        file
                                                                      )
                                                                    }
                                                                    onPause={() =>
                                                                      setCurrentPlaying(
                                                                        null
                                                                      )
                                                                    }
                                                                  />
                                                                ) : (
                                                                  <p></p>
                                                                )}
                </div>
              </div>
            </li>
            <li className="pl-[0.65rem] pr-[1rem]">
              {file?.transcript !== "Empty" ? (
                <p className="text-base">{file?.transcript}</p>
              ) : (
                <p className="text-center px-4 py-2">
                  Voice Notes are not present.
                </p>
              )}
            </li>
          </ul>
        </div>
      );
    } else {
      return (
        <p className="mb-4 text-center border px-4 py-2">
          No audio files available.
        </p>
      );
    }
  })()
) : (
  <p className="mb-4 text-center border px-4 py-2">
    No audio files available.
  </p>
)}
                                        </div>
                                        <div className="col-span-9 justify-self-start"></div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              )}</div>
      <div>  {selectedNote && (
                              <div className="w-auto border bg-[#FFFFFF] border-gray-300 rounded-lg shadow-md font-sans">
                                {/* Header Section */}
                                <div className="!bg-[#D9D9D9] flex justify-between items-center bg-gray-100 p-3 border-b border-gray-300 h-[4.65rem]">
                                  <div>
                                    <div className="flex items-center space-x-2">
                                      <h3 className=" text-xl font-bold text-[#A31B1B]">
                                        Clinical Notes
                                      </h3>
                                      <p className="text-sm text-gray-600 pt-[3px]">
                                      {history.doctor_name}
                                      </p>
                                    </div>
                                   
                                  </div>
                                  <div className="flex space-x-2">
                                  {!ContentEdit.current && (
                                    <div className="flex gap-[4px]">
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="AddImageIcon"
                                       
                                      />
                                    </button>
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="CopyImageIcon"
                                        onClick={(e) => {
                                        copyToClipBord(e);
                                       }}
                                        src={copyImage}
                                      />
                                    </button>
                                    {clinicalNoteVersionList.length > 1 && (
                            <Select
                              labelId="audio-transcipt-clinical-note-version-select"
                              id="audio-transcipt-clinical-note-version-select"
                              value={
                                latestNote ? clinicalNoteVersion : latestVersion
                              }
                              label=""
                              onChange={(e) => {
                                setLatestNote(true);
                                handleVersionDropdown(e?.target?.value);
                              }}
                              size="small"
                              style={{
                                height: 30,
                                backgroundColor: "#fff",
                                marginTop: "3px",
                              }}
                              inputProps={{
                                sx: {
                                  fontWeight: "500",
                                  maxHeight: "50px",
                                },
                                "data-testid":
                                  "audio-transcipt-clinical-note-version-types",
                              }}
                              disabled={!clinicalNoteVersionList}
                            >
                              {clinicalNoteVersionList?.map((version) => (
                                <MenuItem
                                  value={version}
                                  key={version}
                                  id={version}
                                >
                                  {version}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="EditImageIcon"
                                        onClick={(e) => {
                              handleEditContentButton(e, selectedNote);
                            }}
                                        src={editImage}
                                      />
                                    </button>
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="DownloadImageIcon"
                                        onClick={handleDownloadPDF}
                                      
                                        src={downloadImage}
                                      />
                                    </button>
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="CancelImageIcon"
                                        onClick={handleCloseClinicalNotes}
                                     
                                        src={cancelImage}
                                      />
                                    </button>
                                    </div>
                            )}
                            {ContentEdit.current && (
                        <div className="flex justify-between items-end space-x-2">
                          <div>
                            <button
                            id="BtnNoteSave"
                              onClick={handleSaveNoteButton}
                              disabled={
                                modifyClinicalNoteLoading ||
                                 compareJson()
                              }
                              className={
                                isActiveSaveButton
                                  ? "bg-[#b81f1f] text-[#f6f3f3] border-red-700 hover:bg-red-500 w-32 active:bg-white-700 font-medium py-2 px-4 h-100% w-100% rounded transition duration-300 ease-in-out rounded-xl"
                                  : "bg-[#cbc0c0] text-[#8e7979] border-red-700 w-32 active:bg-white-700 font-medium py-2 px-4 h-100% w-100% rounded transition duration-300 ease-in-out rounded-xl"
                              }
                            >
                              Save
                            </button>{" "}
                          </div>
                          <div>
                            <button
                            id="BtnCanEdit"
                              onClick={handleCancelEditButton}
                              className="bg-[#fffefe] text-[#e26666] border-red-700 hover:bg-[#e8d2d2] w-32 font-medium py-2 px-4 rounded transition duration-300 ease-in-out rounded-xl"
                            >
                              Cancel
                            </button>{" "}
                          </div>
                          <div>
                            <button className="text-gray-600 hover:text-gray-800 text-lg">
                              <img
                                id="AudiohandleCloseEditFunctionality"
                                onClick={handleCloseEditFunctionality}
                               
                                src={cancelImage}
                              />
                            </button>
                          </div>
                        </div>
                      )}
                                  </div>
                                </div>

                              

                                {/* Patient Info */}

                                <div
                                  style={{ display: "-webkit-inline-box" }}
                                  
                                >
                                  {!showAudioFilesData && (
                                    <div className="mt-[16rem]">
                                      <button
                                        id="AudioFilesBtn"
                                       onClick={() => {
                                          handleClinicalNotes(
                                            "showClinicalNotes_Id"
                                          );
                                        }}
                                      >
                                     
                                        <img
                                          id="AudioEditIcon"
                                          className={`size-auto mb-1 mr-2 inline cursor-pointer text-red-700`}
                                          src={AudioFiles}
                                        />
                                      </button>
                                    </div>
                                  )}

                                  <div id="clinical-notes-content">
                                    <div
                                      className={` ${
                                        !showAudioFilesData
                                          ? "ml-[1rem]"
                                          : "pl-[1rem]"
                                      } `}
                                    >
                                     
                                      <div>
                                        <ul className="list-disc list-inside   mt-2">
                                          <div className=" mb-4">
                                            {isAiscriptEnable && (
                                              <div
                                                id={"showClinicalNotes_Id"}
                                                className=" flex justify-between hidden sm:inline grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2"
                                              >
                                                <div className="col-span-9 justify-self-start ">
                                                  {!ContentEdit.current && audioFiles &&
                                                  audioFiles.length > 0 ? (
                                                    <>
                                                      {audioFiles?.slice(0,1)?.map(
                                                        (file, index) => {
                                                          if (
                                                            file?.listOfClinicalNote !=
                                                            "Empty"
                                                          ) {
                                                            const clinicalDocumentString =
                                                              file?.listOfClinicalNote;
                                                            let clinicalDocumentParsed =
                                                              [];
                                                            let ClnicalDocuments;

                                                            try {
                                                              if (
                                                                clinicalDocumentString !=
                                                                "Empty"
                                                              ) {
                                                                clinicalDocumentParsed =
                                                                  JSON.parse(
                                                                    clinicalDocumentString
                                                                  );
                                                                let data =
                                                                  clinicalDocumentParsed.reduce(
                                                                    (
                                                                      acc,
                                                                      item
                                                                    ) => {
                                                                      acc[
                                                                        item?.Version
                                                                      ] = {
                                                                        Version:
                                                                          item?.Version,
                                                                        EditedBy:
                                                                          item?.EditedBy,
                                                                        Content:
                                                                          item?.Content
                                                                            ? JSON?.parse(
                                                                                JSON.parse(
                                                                                  item?.Content
                                                                                )?.[0]
                                                                                  ?.Content
                                                                              )
                                                                            : {},
                                                                      };

                                                                      return acc;
                                                                    },
                                                                    {}
                                                                  );
                                                                let dataObj =
                                                                  data?.Original;

                                                                ClnicalDocuments =
                                                                  dataObj
                                                                    .Content
                                                                    .ClinicalDocumentation;
                                                              } else {
                                                                ClnicalDocuments =
                                                                  [];
                                                              }
                                                            } catch (error) {
                                                             
                                                            }

                                                            const date =
                                                              new Date(
                                                                file?.creationTime
                                                              );

                                                            const day = String(
                                                              date.getDate()
                                                            ).padStart(2, "0");
                                                            const month =
                                                              String(
                                                                date.getMonth() +
                                                                  1
                                                              ).padStart(
                                                                2,
                                                                "0"
                                                              );
                                                            const year = String(
                                                              date.getFullYear()
                                                            ).slice(-2);
                                                            return (
                                                              <div className="content-container pt-2 pb-2 pr-4">
                                                                <ul>
                                                                  <li  className="md:text-[14px] lg:text-[14px] xl:text-[16px]"
                                                                    key={index}
                                                                  >
                                                                    <div className="grid grid-row-10 gap-4 pr-[5rem]">
                                                                      

                                                                      {transcriptJson?.Sections?.map(
                                                                        (
                                                                          section,
                                                                          sectionIndex
                                                                        ) => (
                                                                          <div
                                                                            className="py-2 pr-2"
                                                                            key={`section-${sectionIndex}`}
                                                                          >
                                                                            <span
                                                                              className="!font-bold"
                                                                              onInput={(
                                                                                e
                                                                              ) => {
                                                                                e.stopPropagation();
                                                                                e.preventDefault();
                                                                              }}
                                                                              suppressContentEditableWarning={
                                                                                true
                                                                              }
                                                                            >
                                                                              {section?.SectionName.replaceAll(
                                                                                "_",
                                                                                " "
                                                                              )}
                                                                            </span>
                                                                            <ul
                                                                              className="!list-disc ml-8"
                                                                              id="clinical_note_unordered_list"
                                                                            >
                                                                              {section?.Summary?.filter(
                                                                                (
                                                                                  item
                                                                                ) =>
                                                                                  item?.SummarizedSegment
                                                                              )?.map(
                                                                                (
                                                                                  item,
                                                                                  summaryIndex
                                                                                ) => (
                                                                                  <p
                                                                                    key={`${section.SectionName}-${summaryIndex}`}
                                                                                    onInput={(
                                                                                      e
                                                                                    ) => {
                                                                                      const liElement =
                                                                                        e.currentTarget;
                                                                                      if (
                                                                                        !liElement?.textContent
                                                                                      ) {
                                                                                        liElement?.remove();
                                                                                      }
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                      event
                                                                                    ) => {
                                                                                      if (
                                                                                        event.key ===
                                                                                          "Enter" &&
                                                                                        !event.shiftKey
                                                                                      ) {
                                                                                        event.preventDefault();
                                                                                      }
                                                                                    }}
                                                                                    suppressContentEditableWarning={
                                                                                      true
                                                                                    }
                                                                                
                                                                                    className="relative pl-4 focus"
                                                                                  >
                                                                                    <span className="absolute left-0">
                                                                                      •
                                                                                    </span>
                                                                                    {
                                                                                      item?.SummarizedSegment
                                                                                    }
                                                                                  </p>
                                                                                )
                                                                              )}
                                                                            </ul>
                                                                          </div>
                                                                        )
                                                                      )}
                                                                    </div>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            );
                                                          } else {
                                                            return (
                                                              <ul>
                                                                <li
                                                                  key={index}
                                                                  className="mb-4 flex  text-center"
                                                                >
                                                                  <p className="font-bold">
                                                                    {`Clinical Notes.${
                                                                      index + 1
                                                                    }`}{" "}
                                                                  </p>
                                                                </li>
                                                                <li>
                                                                  {" "}
                                                                  <p
                                                                    id={
                                                                      "emptyErrormsgId"
                                                                    }
                                                                    className="mb-4 flex justify-center items-center text-center"
                                                                  >
                                                                    No Clinical
                                                                    Notes are
                                                                    available.
                                                                  </p>
                                                                </li>
                                                              </ul>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                    </>
                                                  ) : (
                                                   ""
                                                  )}
                                                  {ContentEdit.current &&
                                      originalJsonBeforeEdit?.Sections?.map(
                                        (section, sectionIndex) => {
                                          return (
                                            <div
                                              className="p-2"
                                              key={`section-${sectionIndex}`}
                                            >
                                              <span
                                                className="!font-semibold"
                                                onInput={(e) => {
                                                  e.stopPropagation();
                                                  e.preventDefault();
                                                  updateJSON(
                                                    `section-${sectionIndex}`,
                                                    e.target.innerText
                                                  );
                                                }}
                                                suppressContentEditableWarning={
                                                  true
                                                }
                                              >
                                                {`${section?.SectionName}`?.replaceAll(
                                                  "_",
                                                  " "
                                                )}
                                              </span>
                                              <ul
                                                className="!list-disc ml-8"
                                                id="clinical_note_unordered_list"
                                              >
                                                {section?.Summary?.filter(
                                                  (item) =>
                                                    item?.SummarizedSegment
                                                )?.map((item, summaryIndex) => (
                                                  <li
                                                    contentEditable={
                                                      ContentEdit.current
                                                    }
                                                    id={`summary-${sectionIndex}-${summaryIndex}`}
                                                    key={`${section.SectionName}-${summaryIndex}`}
                                                    onInput={async (e) => {
                                                      let arr =
                                                        modifiedJson
                                                          .ClinicalDocumentation
                                                          .Sections;
                                                      const liElement =
                                                        e.currentTarget;
                                                      if (
                                                        liElement?.innerText ==
                                                        "\n"
                                                      ) {
                                                        await addNewLineIndex(
                                                          `summary-${sectionIndex}-${summaryIndex}`
                                                        );
                                                        focusElementById(
                                                          `summary-${sectionIndex}-${
                                                            summaryIndex + 1
                                                          }`
                                                        );
                                                      } else {
                                                        updateJSON(
                                                          `summary-${sectionIndex}-${summaryIndex}`,
                                                          e?.target?.innerText
                                                        );
                                                      }
                                                    }}
                                                    onKeyDown={async (
                                                      event
                                                    ) => {
                                                      const liElement =
                                                        event.currentTarget;
                                                      if (
                                                        event.key === "Enter" &&
                                                        !event.shiftKey
                                                      ) {
                                                        event.preventDefault();
                                                        await addNewLineIndex(
                                                          `summary-${sectionIndex}-${summaryIndex}`
                                                        );
                                                        focusElementById(
                                                          `summary-${sectionIndex}-${
                                                            summaryIndex + 1
                                                          }`
                                                        );
                                                      }
                                                    }}
                                                    suppressContentEditableWarning={
                                                      true
                                                    }
                                                    className="focus:outline-none"
                                                  >
                                                    {item?.SummarizedSegment}
                                                  </li>
                                                ))}
                                              </ul>
                                            </div>
                                          );
                                        }
                                      )}
                                                </div>
                                                <br />
                                              </div>
                                            )}
                                          </div>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div></div>
                                </div>
                              </div>
                            )}</div>
    </>
  ) : null}
</div>   
                          </div>
                         
                        </div>
                        )}
                     
                      
                        
                      </>
                    )}
                </div>
              )}
            
          <textarea ref={textareaRef} id="txtdoctorNotes" style={{ resize: 'none' }} rows={5} onChange={(evt) => {
              setDrNotes(evt.target.value);
              setIsAnyChanges(true)
          }}className="w-full mt-2 px-2 rounded-md text-sm border border-gray-300 focus:border-gray-500 text-gray-900 bg-white dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400 h-auto overflow-hidden resize-none"
          value={drNotes} placeholder="Doctor Notes"></textarea></div>

          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
              {
                  files?.filter(a => a.file_type === "Notes")?.map((file, index) => {
                      const fileExtension = file.file_path.split('.').pop().toLowerCase();
                      const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(fileExtension);
                      const isAudio = ['mp3', 'wav', 'ogg', 'm4a'].includes(fileExtension);
                      const isVideo = ['mp4', 'webm', 'ogg', 'mov'].includes(fileExtension);

                      return (
                          <div className="relative border rounded-sm" key={'image-notes-' + index}>
                              <a rel="noopener" target="_blank" href={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} className=''>
                                  {isImage ? (
                                      <img id="imgDoctor"
                                          className='max-h-[180px] w-auto h-auto object-contain p-5'
                                          src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path}
                                          alt="Prescription"
                                      />
                                  ) : isAudio ? (
                                      <div className="flex justify-center items-center h-full">  <audio className="w-full max-w-[400px]" controls>
                                          <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} type={`audio/${fileExtension}`} />
                                          Your browser does not support the audio element.
                                      </audio></div>
                                  ) : isVideo ? (
                                      <video className="max-h-[180px] w-auto h-auto object-contain p-5" controls>
                                          <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} type={`video/${fileExtension}`} />
                                          Your browser does not support the video element.
                                      </video>
                                  ) : (
                                      <div className="flex justify-center items-center h-full">
                                          <FileIcon
                                              ext={"." + fileExtension}
                                              className='max-h-[180px] w-auto h-auto object-contain p-5  '
                                          />
                                      </div>
                                  )}
                              </a>
                              <button id='btnDeleteNotes'
                                  className="absolute top-2 left-2 bg-gray-100 rounded-full p-1 shadow"
                                  onClick={() => { setNotesFileDeleteCnfBox(true); setNotesFileDeleteID([{ ...file, is_active: false }]); }}
                              >
                                  <svg xmlns="http://www.w3.org/2000/svg" className="size-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                  </svg>
                              </button>
                          </div>
                      );
                  })
              }
          </div>
      </div>
  </>
    );
};

export default DoctorContent;
