import React, { useRef, useEffect, useState } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeMuteIcon from "@mui/icons-material/VolumeMute";
import VolumeDownIcon from "@mui/icons-material/VolumeDown";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
    slider: {
      width: '100%',
      backgroundColor: '#4B4A4A',
      borderRadius: '9999px',
      height: '6px',
      appearance: 'none',
      '&::-webkit-slider-thumb': {
        appearance: 'none',
        width: '12px',
        height: '12px',
        backgroundColor: '#ff5722',
        borderRadius: '50%',
        cursor: 'pointer',
      },
      '&::-moz-range-thumb': {
        width: '12px',
        height: '12px',
        backgroundColor: '#ff5722',
        borderRadius: '50%',
        cursor: 'pointer',
      },
      '&::-ms-thumb': {
        width: '12px',
        height: '12px',
        backgroundColor: '#ff5722',
        borderRadius: '50%',
        cursor: 'pointer',
      },
    },
    volumeSlider: {
      width: '5rem',
      backgroundColor: '#e2e2e2',
      borderRadius: '9999px',
      height: '6px',
      appearance: 'none',
      '&::-webkit-slider-thumb': {
        appearance: 'none',
        width: '12px',
        height: '12px',
        backgroundColor: '#ff5722',
        borderRadius: '50%',
        cursor: 'pointer',
      },
      '&::-moz-range-thumb': {
        width: '12px',
        height: '12px',
        backgroundColor: '#ff5722',
        borderRadius: '50%',
        cursor: 'pointer',
      },
      '&::-ms-thumb': {
        width: '12px',
        height: '12px',
        backgroundColor: '#ff5722',
        borderRadius: '50%',
        cursor: 'pointer',
      },
    },
  }));
const AudioPlayer = ({ fileUrl }) => {
  const audioRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const classes = useStyles();
  const [volume, setVolume] = useState(30);
  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

 

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleLoadedMetadata = () => {
    setDuration(audioRef.current.duration);
  };

 
  const handleProgressChange = (e) => {
    const player = audioRef.current;
    const seekTime = (e.target.value / 100) * player.duration;
    player.currentTime = seekTime;
    setProgress(e.target.value);
  };

  useEffect(() => {
    const player = audioRef.current;

    // Update duration when metadata is loaded
    const handleLoadedMetadata = () => {
      const totalDuration = player.duration;
      if (!isNaN(totalDuration) && isFinite(totalDuration)) {
        setDuration(totalDuration);
      } else {
        setDuration(0); // Fallback in case of error
      }
    };

    // Update current time and progress when playing
    const handleTimeUpdate = () => {
      const current = player.currentTime;
      setCurrentTime(current);
      setProgress((current / player.duration) * 100);
    };

    player.addEventListener("loadedmetadata", handleLoadedMetadata);
    player.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      player.removeEventListener("loadedmetadata", handleLoadedMetadata);
      player.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [isPlaying]);

  const togglePlayPause = () => {
    const player = audioRef.current;
    if (player.paused) {
      player.play();
      setIsPlaying(true);
    } else {
      player.pause();
      setIsPlaying(false);
    }
  };


  const handleVolumeChange = (e) => {
    const volumeLevel = e.target.value / 100;
    audioRef.current.volume = volumeLevel;
    setVolume(volumeLevel);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, "0");
    return `${minutes}:${seconds}`;
  };
  useEffect(() => {
    if (progress == 100) {
      setIsPlaying(false);
    }
  }, [progress]);
  
  return (
    <div className="">
      <div className="bg-[#8D8C8C] rounded-[1.5rem] py-[0.5rem]  flex items-center xl:space-x-4 lg:space-x-0 md:space-x-0 max-w-[100%]">
        <button id="btnTogglrPause"
          onClick={togglePlayPause}
          className="text-white focus:outline-none pl-[5px]"
        
        >
          {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
        </button>
        <div className="relative w-full">
          <input
            type="range"
            value={progress}
            onChange={handleProgressChange}
            max="100"
            className="w-full bg-[#4B4A4A] rounded-full appearance-none h-1.5"
          />
        </div>
        <span className="text-white text-sm">
          {formatTime(currentTime)}/{formatTime(duration)}
        </span>
        <button className="text-white focus:outline-none">
          {volume * 100 <= 0 ? (
            <VolumeOffIcon />
          ) : volume * 100 > 0 && volume * 100 <= 10 ? (
            <VolumeMuteIcon />
          ) : volume * 100 > 10 && volume * 100 <= 50 ? (
            <VolumeDownIcon />
          ) : (
            <VolumeUpIcon />
          )}
        </button>
        <input
          type="range"
          style={{
            WebkitAppearance: "none", // To reset default styling
            MozAppearance: "none",
            appearance: "none",
          }}
          value={volume * 100}
          onChange={handleVolumeChange}
          max="100"
          className={classes.volumeSlider}
     
        />
      
        <div className="flex-1">
          
        </div>
        <audio
          ref={audioRef}
          src={fileUrl}
         
        />
      </div>
    </div>
  );
};

export default AudioPlayer;
