import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from '../../../../Redux/features/toast/toastSlice';
import store from '../../../../API/store';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import DataDecode from '../../../../utils/DataDecode';
import EMRAudioRecorder from '../Audio/EMRAudioRecorder';
import { addLoginDetails } from "../../../../Redux/features/login/loginSlice";
import { setShowRecoringPopup, setStartRecording, setRecConfirmBox,setOpenRecordingPopUp } from '../../../../Redux/features/recording/recordingSlice';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import EMRAudioPlayer from '../Audio/EMRAudioPlayer';
import { setDocumentType, setIsCameraUpload, setRefreshNotes, setShowUploadPopup } from '../../../../Redux/features/uploadDoc/uploadDocSlice';
import FileIcon from '../FileIcon';
import DateFunction from '../../../../utils/DateFunctions';
import { setIsGlobalSaveRequired } from '../../../../Redux/features/profileDrawer/profileSideNavbarSlice';
import { theme } from '../../../../EmraxisTheme/Theme';
import CustomAudioPlayer from '../../../../components/CustomAudioPlayer';


const DoctorNotesMobile = ({ appointment, clinicGuid, appointment_id, patient_id, page_src, setIsMessagePopupOpen, patient}) => {
    const dispatch = useDispatch();
    const recNotesEndRef = useRef(null);
    const menuRef = useRef();
    const divRef = useRef(null);
    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    var loggeduser = useSelector(state => state.login.loginuserData);
    var showRecoringPopup = useSelector(state => state.recording.showRecoringPopup);
    var startRecording = useSelector(state => state.recording.startRecording);
    var confirmBox = useSelector(state => state.recording.confirmBox);
    var disableButtons = useSelector(state => state.recording.disableButtons);
    var isVoice2TextActive = useSelector(state => state.feature.isVoice2TextActive);
    var isStandardAiActive = useSelector(state => state.feature.isStandardAiActive);
    var isRecLoading = useSelector(state => state.recording.isLoading);
    var refreshNotes = useSelector(state => state.document_upload.refreshNotes);
    var autoSaveAll = useSelector(state => state.profileSideNavbar.autoSaveAll);
    var documentType = useSelector(state => state.document_upload.documentType);
    const [expandedRow, setExpandedRow] = useState(null);
    const [currentPlaying, setCurrentPlaying] = useState(null);
    var isDoctorNotesDataAvailable = useSelector(
        (state) => state.profileSideNavbar.isDoctorNotesDataAvailable
      );
     
    const previewTheme = useSelector(state => state.theme.colors) || theme.filter(a => a.name === "defaultTheme")[0];
    const [drNotes, setDrNotes] = useState('');
    const [loading, setLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(true);
    const [refreshData, setRefreshData] = useState(false);
    const [notesFileDeleteID, setNotesFileDeleteID] = useState(0);
    const [notesFileDeleteCnfBox, setNotesFileDeleteCnfBox] = useState(false);
    const [clinicalNoteData, setClinicalNoteData] = useState([]);
    const [allClinicalData, setAllClinicalData] = useState([]);
    const [audioFiles, setAudioFiles] = useState([]);
    const [files, setFiles] = useState([]);
    const [clinicalDocumentation, setClinicalDocumentation] = useState(false);
    const [audioConfirmBox, setAudioConfirmBox] = useState(false);
    const [loadingDeleteImage, setLoadingDeleteImage] = useState(false);
    const [isAnyChanges, setIsAnyChanges] = useState(false);
    const [expandRow, setExpandRow] = useState(true);
    const [showAll, setShowAll] = useState(false);
    const [sortOrder, setSortOrder] = useState('desc'); 
    const [clinicalnotesSection, setClinicalnotesSection] = useState(false)
    const [clinicalnotesSectionProfile, setClinicalnotesSectionProfile] = useState(false)
    const [ clinicalNotes, setClinicalNotes] = useState({})
    const [ clinicalNotesProfile, setClinicalNotesProfile] = useState({})
    const [ clinicalNotesData, setClinicalNotesData] = useState([])
    const [ clinicalNotesDataProfile, setClinicalNotesDataProfile] = useState([])
    const [openMenu, setOpenMenu] = useState(false);
    const [audioFilesView,setAudioFilesView] =useState(false);
    const [openMenuIndex, setOpenMenuIndex] = useState(null);

    const formatDateString = (dateString) => {
      const date = new Date(dateString);
    const additionalTime = (5 * 60 + 30) * 60 * 1000; 
    date.setTime(date.getTime() + additionalTime);
      const options = {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
      };
    
      return date.toLocaleString('en-US', options).replace(',', '');
    };
useEffect(()=> {
        if(clinicalnotesSection){
            let data =JSON.parse(
                clinicalNotes.clinicalNotesRes[0].listOfClinicalNote
               ).reduce(
                   (acc, item) => {
                     acc[item?.Version] = {
                       Version:
                         item?.Version,
                       EditedBy:
                         item?.EditedBy,
                       Content:
                       JSON.parse(item?.Content)     
                          ,
                   };
                
                    return acc;
                  },
                   {}
                 )
                 setClinicalNotesData(data.Original.Content.ClinicalDocumentation.Sections)
        }
      },[clinicalnotesSection])
      useEffect(()=> {
        if(clinicalnotesSectionProfile){
            let data =JSON.parse(
              clinicalNotesProfile.clinicalNotesRes[0].ListOfClinicalNote
               ).reduce(
                   (acc, item) => {
                     acc[item?.Version] = {
                       Version:
                         item?.Version,
                       EditedBy:
                         item?.EditedBy,
                       Content:
                       JSON.parse(item?.Content)     
                          ,
                   };
                
                    return acc;
                  },
                   {}
                 )
                 setClinicalNotesDataProfile(data.Original.Content.ClinicalDocumentation.Sections)
        }
      },[clinicalnotesSectionProfile])
      const copyToClipboard = () => {
        if (divRef.current) {
          navigator.clipboard.writeText(divRef.current.innerText)
            .then(() => {
              // alert('Content copied to clipboard!');
              dispatch(setNotification({ message: 'Content copied to clipboard!', status: 'success', action: true }));
            })
            .catch((err) => {
              dispatch(setNotification({ message: 'Failed to copy', status: 'error', action: true }));
            });
        }
      };
    const scrollToBottom = () => {
        if (recNotesEndRef.current) {
            recNotesEndRef.current.scrollTop = recNotesEndRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        if (isRecLoading)
            scrollToBottom();
    }, [isRecLoading]);

    const GetClinicalNotes = () => {
        try {
            store.GetClinicalNotes(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': clinicGuid, appointment_id: appointment_id, patient_id: patient_id }))
                .then((result) => {
                    if (result.status === 200) {
                        result.json().then((res) => {
                            let response = DataDecode.decryptResponse(res);
                            let data = JSON.parse(response)
                            setClinicalNoteData(data)
                        });
                    } else {
                        setClinicalNoteData([])
                    }
                });
        } catch (err) {
            setClinicalNoteData([])
            dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
        }
    };

    const GetPatientNotesFile = () => {
        store.GetPatientFiles(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': clinicGuid, 'patient_id': patient_id, 'appointment_id': appointment_id }))
            .then((result) => {
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        setFiles(JSON.parse(response));
                    })
                } else {
                    setFiles([])
                }
            })
            .catch(err => {
                setFiles([])
                dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
            })
    }

    const GetAllPatientDrNotes = () => {
        if (allClinicalData.length == 0) {
            setDataLoading(true);
        }
        store.GetAllPatientDrNotes(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': clinicGuid, 'patient_id': patient_id }))
            .then((result) => {
                setDataLoading(false);
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        setAllClinicalData(JSON.parse(response));
                    })
                } else {
                    setAllClinicalData([])
                }
            })
            .catch(err => {
                setDataLoading(false);
                setAllClinicalData([])
                dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
            })
    }

    const handleUpdateAudioRecordOption = async () => {
        let enableAudioRecording = {
          userId: loggeduser?.userId,
          clinicalNoteModelType: "AiScript",
        };
        await store
          .EnableAudioRecording(DataDecode.encryptPayload(enableAudioRecording))
          .then((result) => {
            if (result.status === 200) {
              setAudioConfirmBox(false);
              dispatch(
                addLoginDetails({
                  ...loggeduser,
                  clinicalNoteModelType: enableAudioRecording.clinicalNoteModelType,
                })
              );
              dispatch(
                setNotification({
                  message: "Enabled Clinical DOcuments",
                  status: "success",
                  action: true,
                })
              );
              dispatch(setShowRecoringPopup(true));
            }
            if (result.status === 400) {
              dispatch(
                setNotification({
                  message: "Error while enabling the  Standard_Ai feature",
                  status: "error",
                  action: true,
                })
              );
            } else if (result.status !== 200) {
              dispatch(
                setNotification({
                  message: "Error while enabling the ClinicalDocumentation",
                  status: "error",
                  action: true,
                })
              );
            }
          });
      };
      const handleDownloadPDF = async () => {
        const content = document.getElementById("clinical-notes-contentMobile");
    
        html2canvas(content, { scale: 2 }).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "mm", "a4");
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pageHeight = pdf.internal.pageSize.getHeight();
          const imgWidth = pdfWidth;
          const imgHeight = (canvas.height * pdfWidth) / canvas.width;
    
          let heightLeft = imgHeight;
          let position = 0;
    
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
    
          while (heightLeft > 0) {
            position -= pageHeight;
            pdf.addPage();
            pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }
    
          const now = new Date();
          const dateTimeString = `${now.getFullYear()}-${(now.getMonth() + 1)
              .toString()
              .padStart(2, "0")}-${now.getDate().toString().padStart(2, "0")}_${now
              .getHours()
              .toString()
              .padStart(2, "0")}-${now.getMinutes().toString().padStart(2, "0")}-${now
              .getSeconds()
              .toString()
              .padStart(2, "0")}`;
  
          pdf.save(`clinical_notes_${dateTimeString}.pdf`);
        });
      };
    
      const handleFetchTranscriptionFiles = async () => {
        let data = { PatientId: patient_id, Id: appointment_id };
        await store
          .GetTranscriptionFiles(DataDecode.encryptPayload(data))
          .then((result) => {
            if (result.status == 200) {
              result.json().then((res) => {
                let audioData = res;
                setAudioFiles(audioData);
              }, {});
            } else {
              dispatch(
                setNotification({
                  message: "Error while fetching Audio Files",
                  status: "error",
                  action: true,
                })
              );
              setAudioFiles([]);
            }
          })
          .catch((err) => {
            setDataLoading(false);
            setAllClinicalData([]);
            dispatch(
              setNotification({
                message: "Error occurred",
                status: "error",
                action: true,
              })
            );
          });
      };

    useEffect(() => {
        if (page_src === "patient") {
            GetAllPatientDrNotes();
        }
    }, [refreshNotes])

    useEffect(() => {
        GetPatientNotesFile();
    }, [refreshNotes])
    useEffect(() => {
        if (clinicalDocumentation) {
          handleFetchTranscriptionFiles().then((res) => {
            setClinicalDocumentation(false);
          });
        }
      }, [clinicalDocumentation]);
      useEffect(() => {
        const fetchData = async () => {
          if (page_src === "appointment" || isDoctorNotesDataAvailable) {
            const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    
            await delay(1000);
            await handleFetchTranscriptionFiles().then((result) => {});
            await delay(1000);
          }
        };
    
        fetchData();
      }, []);
    useEffect(() => {
        GetClinicalNotes();
    }, [refreshData]);

    const handleStartRecording = () => {
        dispatch(setShowRecoringPopup(true))
    };
    const hanldeEnableRecordIcon = () => {
        if(isStandardAiActive){
        if (loggeduser?.clinicalNoteModelType === "AiScript") {
          dispatch(setShowRecoringPopup(true));
          setRecConfirmBox(true);
          dispatch(setOpenRecordingPopUp(false))
        } else {
          setAudioConfirmBox(true);
        }}
        else{
          dispatch(setNotification({ message: "Clinical Notes Feature is not enabled for your Facility", status: 'info', action: true }));
        }
      };

    const handleCloseRecording = () => {
        dispatch(setStartRecording(false))
        dispatch(setRecConfirmBox(true))
    }

    useEffect(() => {
        setDrNotes(appointment && appointment?.notes ? appointment?.notes : '')
    }, [appointment])

    const saveNotes = () => {
        if (drNotes.trim() === "") {
            dispatch(setNotification({ message: "Please enter Notes!", status: 'error', action: true }));
        } else {
            setLoading(true)
            store.updateDoctorNotes(loggedusertoken, DataDecode.encryptPayload({ appointment_id: appointment.appointment_id, 'clinic_guid': clinicGuid, 'notes': drNotes, type: "notes" }))
                .then((result) => {
                    setLoading(false)
                    setIsAnyChanges(false)
                    if (result.status == 204) {
                        store.CreateActivityLogs(loggeduser, "Appointment to Patient Profile", `Doctor Notes Updated - ${(appointment?.doctor_name ? "for " + appointment?.doctor_name?.trim():"")} with patient ${patient.FirstName} ${patient.MiddleName?.charAt(0)} ${patient.LastName}(${patient?.patient_op_number}) on ${DateFunction.GetDate(appointment?.appointment_time)} ${(appointment?.app_time ? " at " + DateFunction.Convert12Hour(appointment?.app_time) : "")}`,"Update");
                        dispatch(setNotification({ message: "Notes Updated!", status: 'success', action: true }));
                    }
                    else {
                        dispatch(setNotification({ message: 'Error Occured!', status: 'error', action: true }));
                    }
                })
                .catch(err => {
                    dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
                })
        }
    }

    useEffect(() => {
        if (isAnyChanges && drNotes.trim() !== "") {
            saveNotes()
        }
    }, [autoSaveAll])

    const deleteNotesFile = () => {
        setLoadingDeleteImage(true);
        store.DeletePatientFile(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': clinicGuid, 'patient_id': patient_id, files: notesFileDeleteID }))
            .then((result) => {
                setLoadingDeleteImage(false)
                setNotesFileDeleteCnfBox(false)
                if (result.status == 204) {
                    store.CreateActivityLogs(loggeduser, "Appointment to Patient Profile", `Doctor Notes File Deleted - ${(appointment?.doctor_name ? "for " + appointment?.doctor_name?.trim():"")} with patient ${patient.FirstName} ${patient.MiddleName?.charAt(0)} ${patient.LastName}(${patient?.patient_op_number}) on ${DateFunction.GetDate(appointment?.appointment_time)} ${(appointment?.app_time ? " at " + DateFunction.Convert12Hour(appointment?.app_time) : "")}`,"Delete");
                    dispatch(setRefreshNotes(!refreshNotes))
                    dispatch(setNotification({ message: 'Deleted Successfully!', status: 'success', action: true }));
                }
                else {
                    dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                }

            })
            .catch((error) => {
                dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
            });
    }
    const handleSvgClick = () => {
        setExpandRow(!expandRow)
    }

    const handleUploadButton = (type) => {
        dispatch(setDocumentType({ ...documentType, doctor_note: true }))
        dispatch(setIsCameraUpload(type === "capture" ? true : false));
        if (appointment_id == 0 || (appointment && (appointment.status == 'Completed'))) {
            setIsMessagePopupOpen(p => !p);
        } else {
            dispatch(setShowUploadPopup(true))
        }
    }

    const handleRowClick = (note) => {
        // setSelectedNote(note);
        setClinicalnotesSection(!clinicalnotesSection);
        setClinicalNotes(note);
      };
      const handleRowClick1 = (note,id) => {
        setExpandedRow(expandedRow === id ? null : id);
        setClinicalnotesSectionProfile(!clinicalnotesSectionProfile);
        setClinicalNotesProfile(note);
      };
      const handleSortClick = () => {
        const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newOrder);
       
      };
      useEffect(() => {
        const sortedData = [...audioFiles].sort((a, b) => {
          const dateA = new Date(a.creationTime);
          const dateB = new Date(b.creationTime);
    
          if (sortOrder === 'asc') {
            return dateA - dateB;
          } else {
            return dateB - dateA;
          }
        });
        setAudioFiles(sortedData);
      }, [sortOrder]);
      const toggleMenu = (e) => {
        e.stopPropagation(); 
        setOpenMenu(prevState => !prevState);
    };
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setOpenMenuIndex(null);
        }
      };
    
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);
    return (
        <>
            {confirmBox &&
                <div className="fixed inset-0 flex justify-center items-center p-8 z-[999999]">
                    <div className="absolute inset-0 bg-black opacity-25 "></div>
                    <div className="relative bg-white shadow-xl rounded-sm" style={{ width: "400px" }} >
                        <div className='pl-8 pr-8'>
                            <p className="pt-2 text-lg font-semibold text-center">Your recording will be deleted, are you sure you want to close?</p>
                            <div className="my-10 flex justify-center items-center">
                                <button id="btnCancelCfmRecording"
                                    className="mr-2 bg-red-600 py-1 px-3 text-white font-semibold rounded-sm shadow-md ring-red-800 ring-1 hover:bg-red-700"
                                    onClick={() => {
                                        dispatch(setRecConfirmBox(false));
                                    }}>Cancel</button>
                                <button id="btnCancelCfmProceed"
                                    className="mr-2 bg-red-600 py-1 px-3 text-white font-semibold rounded-sm shadow-md ring-red-800 ring-1 hover:bg-red-700"
                                    onClick={() => {
                                        dispatch(setRecConfirmBox(false));
                                        dispatch(setShowRecoringPopup(false))
                                        dispatch(setStartRecording(false))
                                    }}>Proceed</button>
                            </div>
                        </div>

                    </div>
                </div>
            }
            {notesFileDeleteCnfBox &&

                <div id="delete" tabIndex="-1" aria-hidden="true" className="fixed z-[9999] lg:left-[40%] lg:w-80 top-[10rem] lg:top-[3rem] lg:translate-x-[50%] translate-y-[50%]">

                    <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                        <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                            <svg className="text-red-600 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
                            <p className="mb-4 text-gray-500 dark:text-gray-300">Are you sure you want to delete this file?</p>
                            <div className=" flex justify-center items-center">
                                <button id="btnCancelCfmDeletePopup"
                                    disabled={loadingDeleteImage}
                                    className={`mr-2 py-2 px-3 text-sm font-medium rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10 ${previewTheme.cancelBtnColor} ${previewTheme.cancelBtnBgColor} hover:${previewTheme.cancelBtnHoverColor}`}
                                    onClick={() => {
                                        setNotesFileDeleteCnfBox(false)
                                    }}>No, cancel</button>
                                <button id="btnYesCfmDeletePopup" type="button" onClick={deleteNotesFile} disabled={loadingDeleteImage} className={`py-2 px-3 text-sm font-medium text-center rounded-lg focus:ring-4 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} hover:${previewTheme.saveBtnHoverColor}`}>
                                    Yes, I'm sure {loadingDeleteImage &&
                                        <svg aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                        </svg>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <>
          {audioConfirmBox && (
            <div className="fixed inset-0 flex justify-center items-center p-8 z-[999999]">
              <div className="absolute inset-0 bg-black opacity-25 "></div>
              <div
                className="relative bg-white shadow-xl rounded-xl"
                style={{ width: "400px" }}
              >
                <div className="pl-8 pt-4 pr-8 rounded-xl">
                  <p className="pt-2 text-lg font-semibold text-center">
                    Do You Want to Enable the AI Generated Clinical Notes?
                  </p>
                  <div className="my-10 flex justify-center items-center">
                    <button
                      id="btnCancelCfmRecordingPopupMobile"
                      className={`mr-2 px-3 py-1 font-semibold rounded-md shadow-md ${previewTheme.cancelBtnColor} ${previewTheme.cancelBtnBgColor} hover:${previewTheme.cancelBtnHoverColor}`}
                      onClick={() => {
                        setAudioConfirmBox(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      id="btnCancelCfmProccedPopupMobile"
                      className={`mr-2  py-1 px-3 font-semibold rounded-md shadow-md ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} hover:${previewTheme.saveBtnHoverColor}`}
                      onClick={() => {
                        handleUpdateAudioRecordOption();
                      }}
                    >
                      Enable
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
            {showRecoringPopup &&
                <div className="fixed inset-0 flex justify-center items-center p-8 z-[999998]">
                    <div className="absolute inset-0 bg-black opacity-25 "></div>
                    <div className="relative bg-white shadow-xl rounded-sm" style={{ width: "600px" }} >
                        <div className="hidden lg:flex justify-between ">
                            <div className='m-2'></div>
                            <div className='m-2'><h2 className='text-center text-lg font-semibold pt-[10px] lg:pt-[0px] '>{startRecording ? "Recording" : "Record"}</h2></div>
                            <button id="btnCloseRecordingPopupMobile" className="text-red-700 sm:justify-left disabled:cursor-not-allowed disabled:opacity-50" disabled={disableButtons} onClick={handleCloseRecording}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-7 w-7 mr-2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>
                            </button>
                        </div>
                        <div className='m-2'><h2 className='block lg:hidden text-center text-lg font-semibold pt-[32px] lg:pt-[0px] '>{startRecording ? "Recording" : "Record"}</h2></div>
                            <button id="btnCloseRecordingPopupMobile" className="text-red-700 sm:justify-left disabled:cursor-not-allowed disabled:opacity-50 block lg:hidden absolute top-2 right-2" disabled={disableButtons} onClick={handleCloseRecording}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-7 w-7 mr-2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>
                            </button>
                        <div className='lg:min-h-[200px] pl-8 pr-8 '>
                            <EMRAudioRecorder patient_id={patient_id} appointment_id={appointment_id} setRefreshData={setRefreshData} setClinicalDocumentation={setClinicalDocumentation}/>
                        </div>
                    </div>
                </div>
            }

            <div className='shadow-md rounded-lg' >
                <div className='flex justify-between items-center bg-slate-200 text-[#a31b1b] rounded-t-lg text-lg font-semibold py-2 px-2'>
                    <div className='flex cursor-pointer' id='RadExpandDrNotes' onClick={handleSvgClick}>Doctor Notes <span className='mt-1 cursor-pointer'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" >
                            <path d="M5.8335 8.33289L10.0002 12.4996L14.1668 8.33289H5.8335Z" fill="#a31b1b" />
                        </svg>
                    </span>
                    </div>
                    <div className='flex justify-end items-center '>
                        {(appointment?.status !== 'Completed' && appointment_id > 0 && page_src == "appointment") &&
                            <button title="Record" onClick={hanldeEnableRecordIcon}
                                id="btnStartRecordingMobile"
                                type="button"
                                className={`${previewTheme.borderTheame} flex border-2 py-1 px-2 mr-2 text-sm font-medium text-gray-500 bg-white rounded-lg  border-gray-200 hover:bg-gray-100   hover:text-gray-900  dark:bg-gray-700 dark:text-gray-300 `}> <div className='font-semibold text-[14px]'>AI Scribe</div>
                               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class={loggeduser?.clinicalNoteModelType === "AiScript" ? "size-5  mr-0 cursor-pointer text-[#a31b1b]":"size-5  mr-0 cursor-pointer text-gray-500"}>
  <path d="M8.25 4.5a3.75 3.75 0 1 1 7.5 0v8.25a3.75 3.75 0 1 1-7.5 0V4.5Z" />
  <path d="M6 10.5a.75.75 0 0 1 .75.75v1.5a5.25 5.25 0 1 0 10.5 0v-1.5a.75.75 0 0 1 1.5 0v1.5a6.751 6.751 0 0 1-6 6.709v2.291h3a.75.75 0 0 1 0 1.5h-7.5a.75.75 0 0 1 0-1.5h3v-2.291a6.751 6.751 0 0 1-6-6.709v-1.5A.75.75 0 0 1 6 10.5Z" />
</svg>

                            </button>
                        }
                        <svg xmlns="http://www.w3.org/2000/svg" id='MobBtnUploadDrNotesData' onClick={() => handleUploadButton('upload')} fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className={`size-5 mt-0 mr-2 cursor-pointer text-red-700`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" id='MobBtnCaptureDrNotesData' onClick={() => handleUploadButton('capture')} fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className={`size-5 mt-0 cursor-pointer text-red-700`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                        </svg>
                    </div>
                </div>
                {expandRow && (
                    <>
                        {page_src === "appointment" ?
                            // appointments to patient profile
                            <div className="w-full border border-gray-200 bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
                                {appointment_id > 0 ?
                                    <>
                                        <div ref={recNotesEndRef} className={loggeduser?.clinicalNoteModelType === "AiScript"  ?"px-0 py-2 bg-white rounded-t-lg dark:bg-gray-800 min-h-36":"px-4 py-2 bg-white rounded-t-lg dark:bg-gray-800 min-h-36"}>
                                            {isVoice2TextActive &&
                                                <>
                                                    {
                                                        clinicalNoteData.filter(a => a.notes !== "N/A" && a.notes !== "").length > 0 ?
                                                            <>
                                                                {
                                                                    clinicalNoteData.filter(a => a.notes !== "N/A" && a.notes !== "").map((cn, ind) => (
                                                                        <div key={"cn-" + ind} className='flex justify-between my-1 '>
                                                                            <div>
                                                                                <p className="break-words"><span className='font-semibold'>Note {ind + 1}: </span> {cn.notes}</p>
                                                                            </div>
                                                                            <div>
                                                                                {cn.file_path && <EMRAudioPlayer source={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + cn.file_path} />}
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }

                                                            </>
                                                            :
                                                            <p></p>

                                                    }
                                                    {isRecLoading && <LoadingSpinner text='Generating Notes' />}
                                                </>
                                            }
                                            {appointment?.status === 'Completed' ? (
                                                <div
                                                    className="w-full px-2 text-sm"
                                                >
                                                    {drNotes || "Doctor Notes"}
                                                </div>
                                            ) : (
                                                loggeduser?.clinicalNoteModelType === "AiScript" && audioFiles && audioFiles.length >0 ?                                                 
                                                !clinicalnotesSection ? (<><table className="min-w-full bg-white border rounded-lg shadow-sm">
      <thead>
        <tr className="bg-[#f1e8e9] text-white h-[4.65rem] w-[100%]">
          <th className="py-2 px-4 text-left font-semibold w-[40%] flex text-[#262834]">
           <span> Visit/Upload Details</span>
           <button onClick={handleSortClick} className="ml-2">
              {sortOrder === 'asc' ? (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-4 text-[#262834]">
                <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
              </svg>
              
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-4 text-[#262834]">
  <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
</svg>

              
              )}
            </button>
          </th>
          <th className="py-2 px-4 text-left font-semibold w-[20%] text-[#262834]">Type</th>
          <th className="py-2 px-4 text-left font-semibold w-[40%] text-[#262834]">Last Modified by</th>
        </tr>
      </thead>
        
      <tbody>
        <>
        {audioFiles.map((note, index) => (
          <tr
            key={index}
            id='clinical-notes-mobile'
            onClick={() => handleRowClick(note)}
            className="hover:bg-gray-100 border-b last:border-b-0 cursor-pointer w-[100%]"
          >
            <td className="py-2 px-4 w-[40%]">
              <span className="text-black font-normal">
              {/* {formatDateString(note.creationTime)} */}
                {`${new Date(note.creationTime).toLocaleDateString("en-US", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                })} `}
              </span>
              <br />
              <span className="text-[#8C8C8C] font-normal">
                {`${new Date(new Date(note.creationTime).getTime() + (5 * 60 + 30) * 60 * 1000).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                })} `}
              </span>
            </td>
            <td className="py-2 px-4 w-[20%]">{"AI Scribe"}</td>
            <td className="py-2 px-4 w-[40%]">
              {note.lastModifiedBy} <br />
              <span className="text-black font-normal">
                {`${new Date(note.lastModifiedDate).toLocaleDateString("en-US", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                })} `}
              </span>
              <span className="text-[#8C8C8C] font-normal">
                {`${new Date(new Date(note.lastModifiedDate).getTime() + (5 * 60 + 30) * 60 * 1000).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                })} `}
              </span>
            </td>
          </tr>
        ))}
      </></tbody>
      
      </table>
      
<textarea
                                                    id="doctorNotestextMobile"
                                                    style={{ resize: 'none', overflow: 'hidden' }}
                                                    rows={(isVoice2TextActive && clinicalNoteData.filter(a => a.notes !== "N/A" && a.notes !== "").length > 0) ? 2 : 5}
                                                    onChange={(evt) => {
                                                        setDrNotes(evt.target.value);
                                                        setIsAnyChanges(true);
                                                        dispatch(setIsGlobalSaveRequired(true));
                                                        evt.target.style.height = 'auto';
                                                        evt.target.style.height = `${evt.target.scrollHeight}px`;
                                                    }}
                                                    className="w-full mt-2 px-2 rounded-md text-sm border border-gray-300 focus:border-gray-500 text-gray-900 bg-white dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                                    value={drNotes}
                                                    placeholder="Doctor Notes"
                                                />



                                            {loggeduser?.clinicalNoteModelType !== "AiScript" && audioFiles.length === 0  &&<div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-2 p-2">
                                                {
                                                    files.filter(a => a.file_type === "Notes").map((file, index) => {
                                                        const fileExtension = file.file_path.split('.').pop().toLowerCase();
                                                        const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(fileExtension);
                                                        const isAudio = ['mp3', 'wav', 'ogg', 'm4a'].includes(fileExtension);
                                                        const isVideo = ['mp4', 'webm', 'ogg', 'mov'].includes(fileExtension);

                                                        return (
                                                            <div className="relative p-2 border rounded-sm" key={'image-notes-' + index}>
                                                                <a rel="noopener" target="_blank" href={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} className=''>
                                                                    {isImage ? (
                                                                        <img
                                                                            className='max-h-[180px] w-auto h-auto object-contain p-5'
                                                                            src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path}
                                                                            alt="Prescription"
                                                                        />
                                                                    ) : isAudio ? (
                                                                        <div className="flex justify-center items-center h-full">  <audio className="w-full max-w-[400px]" controls>
                                                                            <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} type={`audio/${fileExtension}`} />
                                                                            Your browser does not support the audio element.
                                                                        </audio></div>
                                                                    ) : isVideo ? (
                                                                        <video className="max-h-[180px] w-auto h-auto object-contain p-5" controls>
                                                                            <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} type={`video/${fileExtension}`} />
                                                                            Your browser does not support the video element.
                                                                        </video>
                                                                    ) : (
                                                                        <div className="flex justify-center items-center h-full">
                                                                            <FileIcon
                                                                                ext={"." + fileExtension}
                                                                                className='max-h-[180px] w-auto h-auto object-contain p-5  '
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </a>
                                                                <button
                                                                    id="btnDeleteDrNoteFile"
                                                                    className="absolute top-2 left-2 bg-gray-100 rounded-full p-1 shadow"
                                                                    onClick={() => { setNotesFileDeleteCnfBox(true); setNotesFileDeleteID([{ ...file, is_active: false }]); }}
                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="size-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>}
                                            </>
      ):
     ( clinicalnotesSection &&  ( <>
        <div className='flex justify-between items-center bg-slate-200 text-[#a31b1b] rounded-t-lg text-lg font-semibold py-2 px-2'>
        
        <div className='flex justify-end items-center '><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5  mr-2 cursor-pointer text-[#a31b1b]" onClick={()=>{setClinicalnotesSection(false);setClinicalNotesData([]); setClinicalNotes({})}}>
  <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
</svg>

                    <div className='flex cursor-pointer' id='RadExpandDrNotes'>Clinical Notes 
                    </div></div>
                    <div className='flex justify-end items-center '>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5  mr-2 cursor-pointer text-[#a31b1b]" onClick={()=>{copyToClipboard()}}>
  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
</svg>
<div className="px-1 py-1 text-center cursor-pointer mt-2">
                                        <button id="PatientsBtnmenuClinicalMobile" onClick={toggleMenu}>
                                            <svg id="PatientsBtnmenuIcon" xmlns="http://www.w3.org/2000/svg" width="6" height="19" viewBox="0 0 6 19" fill="none">
                                                <g opacity="0.5">
                                                    <path d="M3 3.86C3.55228 3.86 4 3.44362 4 2.93C4 2.41638 3.55228 2 3 2C2.44772 2 2 2.41638 2 2.93C2 3.44362 2.44772 3.86 3 3.86Z" stroke="#A31B1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M3 10.68C3.55228 10.68 4 10.2636 4 9.75001C4 9.23638 3.55228 8.82001 3 8.82001C2.44772 8.82001 2 9.23638 2 9.75001C2 10.2636 2.44772 10.68 3 10.68Z" stroke="#A31B1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M3 17.5C3.55228 17.5 4 17.0836 4 16.57C4 16.0564 3.55228 15.64 3 15.64C2.44772 15.64 2 16.0564 2 16.57C2 17.0836 2.44772 17.5 3 17.5Z" stroke="#A31B1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </g>
                                            </svg>
                                        </button>
                                        {openMenu && (
                                            <div id="PatientsDivmobileActionMenu" ref={menuRef} className="absolute mt-[-40px] right-[5.5rem] w-30 bg-[#f1f1f1] border border-gray-300 rounded-md z-20 shadow-lg">
                                                <ul>
                                                    <li
                                                        id="PatientsLImobileEditActionClincal"
                                                        className="px-3 py-1 hover:bg-gray-100 cursor-pointer flex items-center gap-2 text-[14px] text-gray-800"
                                                        onClick={() => {setAudioFilesView(true);setOpenMenu(!openMenu)}}
                                                    >  
                                                       Audio Files
                                                    </li>
                                                     
                                                        <li
                                                            id="PatientsLImobileDeleteActionClinical"
                                                            className="px-3 py-1 hover:bg-gray-100 cursor-pointer flex items-center gap-2 text-[14px] text-gray-800"
                                                            onClick={() => {handleDownloadPDF(); setOpenMenu(!openMenu)}}
                                                        >
                                                           
                                                            Download
                                                        </li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                    </div>
                </div>
            {!audioFilesView ?
                <div >
        <div ref={divRef} >
       { clinicalNotesData.map((clinicalNote,index) => (
        <div className='pt-[6px]' key={index}>
       <div className='text-left text-[14px] text-[#151924] font-semibold mb-1'>{clinicalNote.SectionName}</div> 
      { clinicalNote.Summary.map((note,index) => (
        <div className='w-full pr-[6px]' >
       <div key={index} className='w-full pr-[6px] mb-2'>{index+1 +'. '}{note.SummarizedSegment}</div>
       </div>
      )) }
       </div>
      ))}</div>
      
      <div className='absolute top-[-10000px] p-4' id="clinical-notes-contentMobile">
        <div className='text-center text-[20px] text-[#A31B1B] font-semibold mb-5'>Clinical Notes</div>
       { clinicalNotesData.map((clinicalNote,index) => (
        <div className='pt-[6px]' key={index}>
       <div className='text-left text-[14px] text-[#A31B1B] font-semibold mb-[10px]'>{clinicalNote.SectionName}</div> 
      { clinicalNote.Summary.map((note,index) => (
        <div className='w-full pr-[6px]' >
       <div key={index} className='w-full pr-[6px] text-[12px]'>{index+1 +'. '}{note.SummarizedSegment}</div>
       </div>
      )) }
       </div>
      ))}</div>


      </div>:<div className=" grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1  ">
                                        <div className=" ">
                                          {
                                          clinicalNotes ? (
                                                      <div className="content-container pt-2 pb-2 ">
                                                        <ul>
                                                          <li >
                                                            <div className="">
                                                              <div className="p-3 block pb-[0px] ">
                                                                <h3 className="m-0 p-0 mb-4 flex justify-between items-center">
                                                                    <div>
                                                                  <span className="text-[#A31B1B] font-semibold ">{`Notes :`}</span>
                                                                  </div>
                                                                  <div onClick={() => {setAudioFilesView(false)}} id='close-clinal-mobile'>
                                                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5 text-[#A31B1B]">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
</svg>

                                                                  </div>
                                                                </h3>
                                                                {clinicalNotes?.transcript !=
                                                                "Empty" ? (
                                                                  <CustomAudioPlayer
                                                                    key={
                                                                        clinicalNotes.id
                                                                    }
                                                                    id="AudioTagId"
                                                                    fileUrl={`${process.env.REACT_APP_EMRAXIS_S3_URL}/${clinicalNotes?.documentUrl}`}
                                                                    isPlaying={
                                                                      currentPlaying ===
                                                                      clinicalNotes
                                                                    }
                                                                    onPlay={() =>
                                                                      setCurrentPlaying(
                                                                        clinicalNotes
                                                                      )
                                                                    }
                                                                    onPause={() =>
                                                                      setCurrentPlaying(
                                                                        null
                                                                      )
                                                                    }
                                                                  />
                                                                ) : (
                                                                  <p></p>
                                                                )}
                                                              </div>
                                                            </div>
                                                          </li>
                                                          <li
                                                            
                                                            className=" pl-[0.65rem] "
                                                          >
                                                            {clinicalNotes?.transcript !=
                                                            "Empty" ? (
                                                              <p className="text-base mt-4">
                                                                {
                                                                  clinicalNotes?.transcript
                                                                }
                                                              </p>
                                                            ) : (
                                                              <p className="text-center  px-4 py-2">
                                                                Voice Notes are
                                                                not present.
                                                              </p>
                                                            )}
                                                          </li>
                                                        </ul>
                                                      </div>
                                          ) : (
                                            <p className='mb-4 className="text-center border px-4 py-2"'>
                                              No audio files available.
                                            </p>
                                          )}
                                        </div>
                                        <div className="col-span-9 justify-self-start"></div>
                                      </div>}
      
      </> ))   
                                                :<textarea
                                                    id="doctorNotestext"
                                                    style={{ resize: 'none', overflow: 'hidden' }}
                                                    rows={(isVoice2TextActive && clinicalNoteData.filter(a => a.notes !== "N/A" && a.notes !== "").length > 0) ? 2 : 5}
                                                    onChange={(evt) => {
                                                        setDrNotes(evt.target.value);
                                                        setIsAnyChanges(true);
                                                        dispatch(setIsGlobalSaveRequired(true));
                                                        evt.target.style.height = 'auto';
                                                        evt.target.style.height = `${evt.target.scrollHeight}px`;
                                                    }}
                                                    className="w-full mt-2 px-2 rounded-md text-sm border border-gray-300 focus:border-gray-500 text-gray-900 bg-white dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                                    value={drNotes}
                                                    placeholder="Doctor Notes"
                                                />
)}


                                            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-2 p-2">
                                                {
                                                    files.filter(a => a.file_type === "Notes").map((file, index) => {
                                                        const fileExtension = file.file_path.split('.').pop().toLowerCase();
                                                        const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(fileExtension);
                                                        const isAudio = ['mp3', 'wav', 'ogg', 'm4a'].includes(fileExtension);
                                                        const isVideo = ['mp4', 'webm', 'ogg', 'mov'].includes(fileExtension);

                                                        return (
                                                            <div className="relative p-2 border rounded-sm" key={'image-notes-' + index}>
                                                                <a rel="noopener" target="_blank" href={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} className=''>
                                                                    {isImage ? (
                                                                        <img
                                                                            className='max-h-[180px] w-auto h-auto object-contain p-5'
                                                                            src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path}
                                                                            alt="Prescription"
                                                                        />
                                                                    ) : isAudio ? (
                                                                        <div className="flex justify-center items-center h-full">  <audio className="w-full max-w-[400px]" controls>
                                                                            <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} type={`audio/${fileExtension}`} />
                                                                            Your browser does not support the audio element.
                                                                        </audio></div>
                                                                    ) : isVideo ? (
                                                                        <video className="max-h-[180px] w-auto h-auto object-contain p-5" controls>
                                                                            <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} type={`video/${fileExtension}`} />
                                                                            Your browser does not support the video element.
                                                                        </video>
                                                                    ) : (
                                                                        <div className="flex justify-center items-center h-full">
                                                                            <FileIcon
                                                                                ext={"." + fileExtension}
                                                                                className='max-h-[180px] w-auto h-auto object-contain p-5  '
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </a>
                                                                <button
                                                                    id="btnDeleteDrNoteFile"
                                                                    className="absolute top-2 left-2 bg-gray-100 rounded-full p-1 shadow"
                                                                    onClick={() => { setNotesFileDeleteCnfBox(true); setNotesFileDeleteID([{ ...file, is_active: false }]); }}
                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="size-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>

                                    </>
                                    :
                                    <p className='text-center text-red-600 p-5'>Notes not available!</p>
                                }
                            </div>
                            :
                            <>
                                {/* patients to patient profile */}
                                {allClinicalData.length > 0 ?
                                    <div className="">
                                        {allClinicalData.slice(0, showAll ? undefined : 3).map((data, index1) => (
                                            <div className='border border-gray-200 p-1' key={'all-notes-' + index1}>
                                                <div className='bg-red-300 h-6 ring-2 ring-red-400 rounded-sm'>
                                                    <p className='text-center font-semibold text-blue-900'><span className='px-1'>{DateFunction.GetDate(data.date)}</span></p>
                                                </div>
                                               { data.getTranscriptResponses && data.getTranscriptResponses.length > 0 ?  expandedRow !== index1  ? ( <table className="min-w-full bg-white border rounded-lg shadow-sm mt-[10px]">
      <thead>
        <tr className="bg-[#f1e8e9] text-white h-[4.65rem] w-[100%]">
          <th className="py-2 px-4 text-left font-semibold w-[40%] flex text-[#262834]">
           <span> Visit/Upload Details</span>
          </th>
          <th className="py-2 px-4 text-left font-semibold w-[20%] text-[#262834]">Type</th>
          <th className="py-2 px-4 text-left font-semibold w-[40%] text-[#262834]">Last Modified by</th>
        </tr>
      </thead>
        
      <tbody>
        <>
        {data.getTranscriptResponses.map((note, index) => (
          <tr
            key={index}
            id='clinical-notes-mobile'
            onClick={() => handleRowClick1(note,index1)}
            className="hover:bg-gray-100 border-b last:border-b-0 cursor-pointer w-[100%]"
          >
            <td className="py-2 px-4 w-[40%]">
              <span className="text-black font-normal">
                {`${new Date(note.CreationTime).toLocaleDateString("en-US", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                })} `}
              </span>
              <br />
              <span className="text-[#8C8C8C] font-normal">
                {`${new Date(new Date(note.CreationTime).getTime() + (5 * 60 + 30) * 60 * 1000).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                })} `}
              </span>
            </td>
            <td className="py-2 px-4 w-[20%]">{"AI Scribe"}</td>
            <td className="py-2 px-4 w-[40%]">
              {note.LastModifiedBy} <br />
              <span className="text-black font-normal">
                {`${new Date(note.LastModifiedDate).toLocaleDateString("en-US", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                })} `}
              </span>
              <span className="text-[#8C8C8C] font-normal">
                {`${new Date(new Date(note.LastModifiedDate).getTime() + (5 * 60 + 30) * 60 * 1000).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                })} `}
              </span>
            </td>
          </tr>
        ))}
      </></tbody>
      
      </table>):( expandedRow === index1  &&  ( <>
        <div className='flex justify-between items-center bg-slate-200 text-[#a31b1b] rounded-t-lg text-lg font-semibold py-2 px-2'>
        
        <div className='flex justify-end items-center '><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5  mr-2 cursor-pointer text-[#a31b1b]" onClick={()=>{setClinicalnotesSectionProfile(false); setExpandedRow(null);setClinicalNotesDataProfile([]); setClinicalNotesProfile({})}}>
  <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
</svg>

                    <div className='flex cursor-pointer' id='RadExpandDrNotes'>Clinical Notes 
                    </div></div>
                    <div className='flex justify-end items-center '>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5  mr-2 cursor-pointer text-[#a31b1b]" onClick={()=>{copyToClipboard()}}>
  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
</svg>
<div className="px-1 py-1 text-center cursor-pointer mt-2">
                                        <button id="PatientsBtnmenuClinicalMobile" onClick={toggleMenu}>
                                            <svg id="PatientsBtnmenuIcon" xmlns="http://www.w3.org/2000/svg" width="6" height="19" viewBox="0 0 6 19" fill="none">
                                                <g opacity="0.5">
                                                    <path d="M3 3.86C3.55228 3.86 4 3.44362 4 2.93C4 2.41638 3.55228 2 3 2C2.44772 2 2 2.41638 2 2.93C2 3.44362 2.44772 3.86 3 3.86Z" stroke="#A31B1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M3 10.68C3.55228 10.68 4 10.2636 4 9.75001C4 9.23638 3.55228 8.82001 3 8.82001C2.44772 8.82001 2 9.23638 2 9.75001C2 10.2636 2.44772 10.68 3 10.68Z" stroke="#A31B1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M3 17.5C3.55228 17.5 4 17.0836 4 16.57C4 16.0564 3.55228 15.64 3 15.64C2.44772 15.64 2 16.0564 2 16.57C2 17.0836 2.44772 17.5 3 17.5Z" stroke="#A31B1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </g>
                                            </svg>
                                        </button>
                                        {openMenu && (
                                            <div id="PatientsDivmobileActionMenu" ref={menuRef} className="absolute mt-[-40px] right-[5.5rem] w-30 bg-[#f1f1f1] border border-gray-300 rounded-md z-20 shadow-lg">
                                                <ul>
                                                    <li
                                                        id="PatientsLImobileEditActionClincal"
                                                        className="px-3 py-1 hover:bg-gray-100 cursor-pointer flex items-center gap-2 text-[14px] text-gray-800"
                                                        onClick={() => {setAudioFilesView(true);setOpenMenu(!openMenu)}}
                                                    >  
                                                       Audio Files
                                                    </li>
                                                     
                                                        <li
                                                            id="PatientsLImobileDeleteActionClinical"
                                                            className="px-3 py-1 hover:bg-gray-100 cursor-pointer flex items-center gap-2 text-[14px] text-gray-800"
                                                            onClick={() => {handleDownloadPDF(); setOpenMenu(!openMenu)}}
                                                        >
                                                           
                                                            Download
                                                        </li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                    </div>
                </div>
            {!audioFilesView ?
                <div >
        <div ref={divRef} >
       { clinicalNotesDataProfile.map((clinicalNote,index) => (
        <div className='pt-[6px]' key={index}>
       <div className='text-left text-[14px] text-[#151924] font-semibold mb-1'>{clinicalNote.SectionName}</div> 
      { clinicalNote.Summary.map((note,index) => (
        <div className='w-full pr-[6px]' >
       <div key={index} className='w-full pr-[6px] mb-2'>{index+1 +'. '}{note.SummarizedSegment}</div>
       </div>
      )) }
       </div>
      ))}</div>
      
      <div className='absolute top-[-10000px] p-4' id="clinical-notes-contentMobile">
        <div className='text-center text-[20px] text-[#A31B1B] font-semibold mb-5'>Clinical Notes</div>
       { clinicalNotesDataProfile.map((clinicalNote,index) => (
        <div className='pt-[6px]' key={index}>
       <div className='text-left text-[14px] text-[#A31B1B] font-semibold mb-[10px]'>{clinicalNote.SectionName}</div> 
      { clinicalNote.Summary.map((note,index) => (
        <div className='w-full pr-[6px]' >
       <div key={index} className='w-full pr-[6px] text-[12px]'>{index+1 +'. '}{note.SummarizedSegment}</div>
       </div>
      )) }
       </div>
      ))}</div>


      </div>:<div className=" grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1  ">
                                        <div className=" ">
                                          {
                                          clinicalNotesProfile ? (
                                                      <div className="content-container pt-2 pb-2 ">
                                                        <ul>
                                                          <li >
                                                            <div className="">
                                                              <div className="p-3 block pb-[0px] ">
                                                                <h3 className="m-0 p-0 mb-4 flex justify-between items-center">
                                                                    <div>
                                                                  <span className="text-[#A31B1B] font-semibold ">{`Notes :`}</span>
                                                                
                                                                  </div>
                                                                  <div onClick={() => {setAudioFilesView(false)}} id='close-clinal-mobile'>
                                                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5 text-[#A31B1B]">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
</svg>

                                                                  </div>
                                                                </h3>
                                                                {clinicalNotesProfile?.transcript !=
                                                                "Empty" ? (
                                                                  <CustomAudioPlayer
                                                                    key={
                                                                        clinicalNotesProfile.id
                                                                    }
                                                                    id="AudioTagId"
                                                                    fileUrl={`${process.env.REACT_APP_EMRAXIS_S3_URL}/${clinicalNotesProfile?.DocumentUrl}`}
                                                                    isPlaying={
                                                                      currentPlaying ===
                                                                      clinicalNotesProfile
                                                                    }
                                                                    onPlay={() =>
                                                                      setCurrentPlaying(
                                                                        clinicalNotesProfile
                                                                      )
                                                                    }
                                                                    onPause={() =>
                                                                      setCurrentPlaying(
                                                                        null
                                                                      )
                                                                    }
                                                                  />
                                                                ) : (
                                                                  <p></p>
                                                                )}
                                                              </div>
                                                            </div>
                                                          </li>
                                                          <li
                                                            
                                                            className=" pl-[0.65rem] "
                                                          >
                                                            {clinicalNotesProfile?.transcript !=
                                                            "Empty" ? (
                                                              <p className="text-base mt-4">
                                                                {
                                                                  clinicalNotesProfile?.transcript
                                                                }
                                                              </p>
                                                            ) : (
                                                              <p className="text-center  px-4 py-2">
                                                                Voice Notes are
                                                                not present.
                                                              </p>
                                                            )}
                                                          </li>
                                                        </ul>
                                                      </div>
                                          ) : (
                                            <p className='mb-4 className="text-center border px-4 py-2"'>
                                              No audio files available.
                                            </p>
                                          )}
                                        </div>
                                        <div className="col-span-9 justify-self-start"></div>
                                      </div>}
      
      </> ))  :''}

                                                
                                                <div className='px-2'>{data.notes}</div>
                                                <div className='p-2'>
                                                    {
                                                        data?.audio_notes?.filter(a => a.audio_notes !== "N/A" && a.audio_notes !== "").length > 0 &&
                                                        <>
                                                            {
                                                                data?.audio_notes?.filter(a => a.audio_notes !== "N/A" && a.audio_notes !== "").map((cn, ind) => (
                                                                    <div key={"cn-" + ind} className='flex justify-between my-1 '>
                                                                        <div>
                                                                            <p className="break-words"><span className='font-semibold'>Note {ind + 1}: </span> {cn.audio_notes}</p>
                                                                        </div>
                                                                        <div>
                                                                            {cn.audio_file && <EMRAudioPlayer source={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + cn.audio_file} />}
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }

                                                        </>

                                                    }
                                                </div>
                                                <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-2 p-2">
                                                    {
                                                        data?.files?.map((file, index) => {
                                                            const fileExtension = file.upload_file.split('.').pop().toLowerCase();
                                                            const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(fileExtension);
                                                            const isAudio = ['mp3', 'wav', 'ogg', 'm4a'].includes(fileExtension);
                                                            const isVideo = ['mp4', 'webm', 'ogg', 'mov'].includes(fileExtension);

                                                            return (
                                                                <div className="relative p-2 border rounded-sm" key={'image-notes-' + index}>
                                                                    <a rel="noopener" target="_blank" href={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.upload_file} className=''>
                                                                        {isImage ? (
                                                                            <img
                                                                                id={"imgDoctorNotesFile" + index}
                                                                                className='max-h-[180px] w-auto h-auto object-contain p-5'
                                                                                src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.upload_file}
                                                                                alt="Doctor Notes"
                                                                            />
                                                                        ) : isAudio ? (
                                                                            <div className="flex justify-center items-center h-full">  <audio id={"audioDoctorNotesFile" + index} className="w-full max-w-[400px]" controls>
                                                                                <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.upload_file} type={`audio/${fileExtension}`} />
                                                                                Your browser does not support the audio element.
                                                                            </audio></div>
                                                                        ) : isVideo ? (
                                                                            <video id={"videogDoctorNotesFile" + index} className="max-h-[180px] w-auto h-auto object-contain p-5" controls>
                                                                                <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.upload_file} type={`video/${fileExtension}`} />
                                                                                Your browser does not support the video element.
                                                                            </video>
                                                                        ) : (
                                                                            <div className="flex justify-center items-center h-full">
                                                                                <FileIcon
                                                                                    id={"fileDoctorNotesFile" + index}
                                                                                    ext={"." + fileExtension}
                                                                                    className='max-h-[180px] w-auto h-auto object-contain p-5  '
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </a>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                        <div className='flex justify-end'>
                                            {allClinicalData.length > 3 && (
                                                <button id='BtnDoctorNotesShowAll'
                                                    className="text-red-600 p-2 "
                                                    onClick={() => setShowAll(!showAll)}
                                                >
                                                    {showAll ? "View Less" : "View More"}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    :
                                    <>
                                        {dataLoading &&
                                            <p className='text-center p-5'>
                                                <svg id="imgDrNotesDataLoading" aria-hidden="true" role="status" className="size-6 ml-2 text-red-600 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                </svg>
                                            </p>                                            
                                        }
                                    </>

                                }
                            </>
                        }
                    </>
                )}
            </div>
        </>
    )
}

export default DoctorNotesMobile