import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showRecoringPopup: false,
    startRecording: false,
    openRecorderPopUp:false,
    stopRecording: false,
    confirmBox: false,
    disableButtons: false,
    isLoading: false
}

export const recordingSlice = createSlice({
    name: 'recording',
    initialState,
    reducers: {
        setShowRecoringPopup: (state, action) => {
            state.showRecoringPopup = action.payload
        },
        setStartRecording: (state, action) => {
            state.startRecording = action.payload
        },
        setOpenRecordingPopUp: (state, action) => {
            state.openRecorderPopUp = action.payload
        },
        setStopRecording: (state, action) => {
            state.stopRecording = action.payload
        },
        setRecConfirmBox: (state, action) => {
            state.confirmBox = action.payload
        },
        setRecDisableButton: (state, action) => {
            state.disableButtons = action.payload
        },
        setRecIsLoading: (state, action) => {
            state.isLoading = action.payload
        }
    }
})

export const { setShowRecoringPopup, setStartRecording, setStopRecording, setRecConfirmBox, setRecDisableButton, setRecIsLoading,setOpenRecordingPopUp } = recordingSlice.actions;

export default recordingSlice.reducer;